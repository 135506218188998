import { mobileSize14, spoqa } from "../../../../shared/css/css-utils";
import { CreateStyle } from "../../../../shared/presentation/shared/CreateStyle";

const FooterStyle = (
  isMobile: boolean,
  isTablet: boolean,
  baseColor: string,
  textColor: string = 'white',
) => CreateStyle({
  background: {
    padding: '10.38vw 11.6vw/3.5vw 14.37vw 2.86vw 14.27vw@53px',

    backgroundColor: baseColor,

    display: 'flex',
    flexDirection_: 'column/',
    alignItems_: 'center/',
    justifyContent_: '/space-between',

    color: textColor,
    fontFamily: spoqa,
  },
  leftDiv: {
    display: 'flex',
    flexDirection: 'column',

    fontWeight: 300,
  },
  centerNumber: {
    lineHeight: '1.42/1.3@1.5',
    fontSize: '24px/40px@24px',

    textAlign_: 'center/left',

    cursor: 'pointer',
    marginBottom: '0/.31vw@2px',
  },
  workTime: {
    lineHeight: '1.71/1.67@1.71',
    fontSize: '14px/18px@14px',
    textAlign_: 'center/left',

    marginBottom: '6.52vw/2.23vw@36px',
  },
  iconImage: {
    display_: 'none/flex',
    flexDirection: 'row',
  },
  logo: {
    width: '1.7vw',
    height: '1.56vw',

    marginRight: '1.66vw',
    cursor: 'pointer',
    fill: textColor,
  },
  facebookLogo: {
    width: '1.7vw',
    height: '1.56vw',
    cursor: 'pointer',
    fill: textColor,
  },
  rightDiv: {
    width: 'max-content',

    textAlign_: 'center/right',
    display_: 'flex/',
    flexDirection_: 'column/',
    alignItems_: 'center/',

    fontWeight: 300,
  },
  companyLogo: {
    width: '38.64vw/12.76vw@',
    height: '5.63vw/4vw@25px',

    paddingTop: '.83vw',
    fill: textColor,

    cursor: 'pointer',
  },
  firstLine: {
    fontWeight_: '400',
    fontSize: '11.5px/16px@13px',
    lineHeight: '2.17/1.56@1.83',

    paddingTop: '3.3vw/1.35vw@17px',
  },
  secondLine: {
    fontWeight_: '/400',
    fontSize: '14px/14px@13px',
    lineHeight: '1.71/1.79@1.83',
    color: isMobile || isTablet ? textColor : 'default',

    opacity: 0.5,

    marginTop: '1.3vw/1.3vw@6px',
  },
  thirdLine: {
    fontWeight_: '/400',
    fontSize: '13px/14px@15px',
    lineHeight: '1.71/1.79@24px',
    color: isMobile || isTablet ? textColor : 'default',

    opacity: 0.5,

    marginTop: '5.55vw/.3vw@29px',
  },
  fourthLine: {
    width: '300px/@315px',

    fontWeight_: '400',
    fontSize: '11px/13px@13px',
    lineHeight: '1.69/1.92@1.75',
    whiteSpace_: '/pre',
    wordBreak_: 'keep-all/',

    opacity: .5,

    marginTop: '20px/.2vw@27px',
  },
  emailLine: {
    display_: '/none',

    lineHeight: 1.71,
    fontWeight: 400,
    fontSize: mobileSize14,

    opacity: .5,
  },
  cursorPointer: {
    textDecoration: 'none',
    color: 'inherit',

    cursor: 'pointer',
  },
}, isMobile, isTablet);

export default FooterStyle;
