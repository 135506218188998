import React from 'react';
import { css } from 'aphrodite';
import { useRecoilValue } from 'recoil';
import { Link } from 'react-router-dom';

import { spoqa } from '../../../../shared/css/css-utils';
import { Image } from '../../../../shared/css/Image';
import { Assets } from '../../shared/Assets';
import { totalScreenState } from '../../../../shared/presentation/MobileFrame';
import { requestPage } from '../../../../shared/presentation/HistoryHelper';
import { CreateStyle } from '../../../../shared/presentation/shared/CreateStyle';
import { NotDesktopBr } from '../../../../shared/presentation/NotDesktopBr';

function Request() {
  const {isMobile, isTablet} = useRecoilValue(totalScreenState);
  const styles = RequestStyle(isMobile, isTablet);
  return (
    <div className={css(styles.background)} id={'section_16'}>
      <Image src={Assets.RequestBack(isMobile)} className="background" alt="배경"/>
      <div className={css(styles.firstFont)}>
        무료법인전환 <NotDesktopBr/>
        30초 만에 신청하기
      </div>
      <Link className={css(styles.requestButton)} to={requestPage()} id={'request_button'}>
        무료 법인전환 신청하기
      </Link>
    </div>
  );
}

export default Request;

const RequestStyle = (isMobile: boolean, isTablet: boolean) => CreateStyle({
  background: {
    height: '280px/562px',

    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',

    position: 'relative',

    fontFamily: spoqa,
  },
  firstFont: {
    fontWeight: 300,
    fontSize: '30px/50px',
    lineHeight: '1.37/1.2',
    color: 'white',
    textAlign_: 'center/',

    marginBottom: '14px/34px',
  },
  requestButton: {
    width: '330px/18.54vw',
    height: '46px/3.47vw',

    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

    cursor: 'pointer',

    border: 'solid 1px white',

    fontWeight_: '500/300',
    fontSize: '13px/0.97vw',
    color: 'white',

    ':hover': {
      backgroundColor: 'white',
      color: '#051a43',
      transition: '0.3s',
    },
  },
}, isMobile, isTablet);
