import { spoqa } from '../../css/css-utils';
import { CreateStyle } from '../shared/CreateStyle';

export const CheckProcessStyle = (
  isMobile: boolean,
  isTablet: boolean,
  baseColor: string = '#1b315d',
  buttonColor: string = '#1b315d',
) => CreateStyle({
  checkOutline: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent_: 'space-between/',

    width: '100%/',
    height: '65vh/',
    padding: '4vh 2.84vh 5.42vh/3.4vw 3.64vw 0@52px 162px',

    fontSize: '1.62vh/14px@15.6px',
    fontFamily: spoqa,
    fontWeight_: `${500}/${300}`,
    lineHeight: '1.67/1.79@',
  },
  checkTitle: {
    width: '100%/',
    fontSize: '13px/0.93vw@15.6px',
    fontWeight_: `${400}/${300}@${400}`,
    lineHeight: '22px/1.67@26px',

    marginBottom: '5px/@16.5px',
    color: baseColor,
  },
  checkPhone: {
    width: '100%/20.46vw',

    fontWeight_: `${400}/${300}@${400}`,
    fontSize: '26px/2.08vw@31px',
    lineHeight: '32px/1.38@38.4px',
  },
  ButtonDiv: {
    display: 'flex',

    width: '100%/20.83vw',
    height: '130px/@144px',
    paddingTop: '/20vw',

    flexDirection_: 'column/',
    justifyContent: 'space-between',

    textAlign: 'center',

    marginBottom: '10vw/',
  },
  reInputNumberButton: {
    width: '100%/9.5vw',
    height: '50px/3vw@60px',
    textDecoration: 'none',
    color: 'inherit',

    backgroundColor: 'white',
    border: 'solid 1px ' + baseColor,
    borderRadius: '4px/@8px',

    fontWeight_: `${400}/${500}`,
    fontSize: '13px/@15.6px',
    lineHeight: '16px/19.61px',

    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

    cursor: 'pointer',
    outline: 'none',
    userSelect: 'none',
  },
  okayButton: {
    width: '100%/9.5vw',
    height: '6.77vh/3vw@60px',

    backgroundColor: buttonColor,
    borderRadius: '4px/@8px',

    fontWeight_: `${400}/${500}`,
    fontSize: '13px/@15.6px',
    lineHeight: '16px/19.61px',

    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

    cursor: 'pointer',
    outline: 'none',
    userSelect: 'none',
  },
}, isMobile, isTablet);
