import React from 'react';
import { useRecoilValue } from 'recoil';
import { useHistory } from 'react-router-dom';
import { css } from 'aphrodite';

import { spoqa } from '../../css/css-utils';
import { ReactComponent as CloseIcon } from '../icons/close.svg';
import { BackgroundButton } from '../kakao-popup/BackgroundButton';
import { Image } from '../../css/Image';
import { totalScreenState } from '../MobileFrame';
import { defaultPage, IHistory, isShowNewsPopup } from '../HistoryHelper';
import { newsState } from '../../store/NewsStore';
import { CreateStyle } from '../shared/CreateStyle';
import SharedStyleSheet from '../../css/SharedStyleSheet';

export function NewsPopUp() {
  const { isMobile, isTablet } = useRecoilValue(totalScreenState);
  const history = useHistory<IHistory>();
  const news = useRecoilValue(newsState);

  const styles = NewsPopUpStyle(isMobile, isTablet);
  const shared = SharedStyleSheet(isMobile, isTablet);
  return (
    <>
      {isShowNewsPopup(history) &&
        <div className='popup background'>
          <div className={css(styles.newsBox)}>
            <CloseIcon
              className={css(shared.closeButton)}
              onClick={() => history.push(defaultPage)}
              data-testid='close'
            />
            <Image
              src={news.imagePath}
              className={css(styles.bannerImageStyle)}
              alt={'뉴스'}
            />
            <div className={css(styles.contentBox)}>
              <div className={css(styles.title)}>{news.title}</div>
              <div className={css(styles.writer)}>{news.writer}</div>
              <div className={css(styles.content)}>{news.content}</div>
              <div className={css(styles.link)} onClick={() => (window.open(news.linkUrl))}>기사 보러가기</div>
              <div className={css(styles.space)}/>
            </div>
          </div>
          <BackgroundButton/>
        </div>
      }
    </>
  );
}

const NewsPopUpStyle = (isMobile: boolean = false, isTablet: boolean) => CreateStyle({
  newsBox: {
    width: '100vw/1435px',
    height: '100vh/844px',

    background: 'white',

    padding: `0 0 30px 0/1.72vw@0 0 75px`,

    display_: '/flex',
    position: 'relative',
    overflowY_: 'auto/',

    fontFamily: spoqa,
  },
  bannerImageStyle: {
    width: '100%/623px',
    height: '55vw/100%@349px',

    marginRight: '/4.32vw',
  },
  contentBox: {
    width: '/623px',
    height: '100%',

    color: process.env.REACT_APP_BASE_COLOR ?? 'black',

    padding: '7vw 5.31vw/@57px 115px 75px',

    overflow_: '/hidden',
  },
  title: {
    fontWeight_: `${300}/${300}@${500}`,
    fontSize: '19px/28px@22px',
    lineHeight_: '1.39/1.39@1.56',
    whiteSpace_: '/pre',

    marginTop: '/1.14vw',
    marginBottom: '2.89vw/1vw',
  },
  writer: {
    fontSize: '14px/18px@17px',
    fontWeight: isTablet ? 400 : 300,
    color: '#8a8bb7',
    lineHeight: 1.66,

    marginBottom: '2.89vw/1vw@16.5px',
  },
  content: {
    height: '/520px',

    fontWeight: isTablet ? 400 : 300,
    fontSize: '14px/18px@17px',
    lineHeight: 1.66,
    whiteSpace: 'pre-line',

    marginBottom: '2.89vw/20px@21px',

    overflowY_: 'hidden/scroll',
  },
  link: {
    fontSize: '14px/18px@17px',
    fontWeight: isTablet ? 400 : 300,
    color: '#00b1d1',
    lineHeight: 1.66,
    textDecoration: 'underline',

    cursor: 'pointer',
  },
  space: {
    height: '100px/',
  }
}, isMobile, isTablet);
