import React from 'react';
import { css } from 'aphrodite';
import { useRecoilValue } from 'recoil';
import { Link, useHistory } from 'react-router-dom';

import FooterStyle from './FooterStyle';
import { ReactComponent as Facebook } from '../../../../shared/presentation/icons/facebook.svg';
import { ReactComponent as KakaoIcon } from '../../../../shared/presentation/icons/kakao.svg';
import { ReactComponent as BlogIcon } from '../../../../shared/presentation/icons/blog.svg';
import { LinkItem } from "../../../../shared/presentation/shared/LinkItem";
import { totalScreenState } from "../../../../shared/presentation/MobileFrame";
import {
  IHistory,
  kakaoPage
} from "../../../../shared/presentation/HistoryHelper";
import { useInjection } from "../../../../shared/presentation/InversifyProvider";
import {
  LOCATION_SERVICE,
  LocationService
} from "../../../../shared/store/LocationStore";
import { openBlogPage } from "../../../../shared/helper/WindowHelper";
import { moveToHome } from "../../../../shared/helper/Util";

export interface IFooterProps {
  linkList?: LinkItem[],
  LogoImage?: any,
  baseColor: string,
  textColor?: string,
  warning?: string,
  company?: string,
  place?: string,
}

function Footer(
  {
    baseColor,
    LogoImage,
    warning,
    linkList,
    textColor,
    company = process.env.REACT_APP_COMPANY_NAME,
    place = '서울특별시 서초구 서초대로 396 강남빌딩 17층',
  }: IFooterProps) {
  const { isMobile, isTablet, isDesktop } = useRecoilValue(totalScreenState);
  const history = useHistory<IHistory>();
  const locationService = useInjection<LocationService>(LOCATION_SERVICE);

  const styles = FooterStyle(
    isMobile,
    isTablet,
    baseColor,
    textColor,
  );

  const callToService = () => {
    if (isMobile) {
      locationService.callToService();
    }
  };

  return (
    <div className={css(styles.background)}>
      <div className={css(styles.leftDiv)}>
        <div className={css(styles.centerNumber)} onClick={callToService}>
          고객센터 {process.env.REACT_APP_SERVICE_CENTER_NUMBER}
        </div>
        <div className={css(styles.workTime)}>
          오전 9시 - 오후 7시 (주말, 공휴일 제외)
        </div>
        <div className={css(styles.iconImage)}>
          {/*<BlogIcon*/}
          {/*  className={css(styles.logo)}*/}
          {/*  onClick={openBlogPage}*/}
          {/*/>*/}
          <Link to={kakaoPage()}>
            <KakaoIcon className={css(styles.logo)}/>
          </Link>
          <Facebook className={css(styles.facebookLogo)}/>
        </div>
      </div>

      <div className={css(styles.rightDiv)}>
        <LogoImage
          className={css(styles.companyLogo)}
          onClick={() => moveToHome(history)}
        />
        <div className={css(styles.firstLine)}>
          {linkList?.map((item, index) => (
            <React.Fragment key={'link-' + index}>
              <span className={css(styles.cursorPointer)} onClick={item.callback}>
                {item.title}
              </span>
              {!item.isLast && <> | </>}
            </React.Fragment>
          ))}
        </div>
        <div className={css(styles.secondLine)}>
          © {company}
        </div>
        <div className={css(styles.thirdLine)}>
          <Link
            className={css(styles.cursorPointer)}
            to={'/privacy_policy'}
            target={'_blank'}
          >
            서비스이용약관
          </Link>&nbsp;|&nbsp;
          <Link
            className={css(styles.cursorPointer)}
            to={'/terms_of_service'}
            target={'_blank'}
          >
            개인정보처리방침
          </Link>&nbsp;|&nbsp;
          {process.env.REACT_APP_COMPANY_NAME}&nbsp;
          {isDesktop ?
            <span>|</span>
            :
            <br/>
          }
          &nbsp;{place}
        </div>
        {/*<div className={css(styles.emailLine)}>*/}
        {/*  contact@k-startbiz.org*/}
        {/*</div>*/}
        {warning !== undefined
        && <div className={css(styles.fourthLine)}>
          {warning}
        </div>
        }
      </div>
    </div>
  );
}

export default Footer;
