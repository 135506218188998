import React from 'react';
import { css } from 'aphrodite';

import { ReactComponent as Kakao } from '../icons/kakao.svg';
import { ReactComponent as Call } from '../icons/call.svg';
import { MenuStyle } from './MenuStyle';
import { LOCATION_SERVICE, LocationService } from '../../store/LocationStore';
import { openKakaoMobilePage } from '../../helper/Util';
import { useInjection } from '../InversifyProvider';
import { useRecoilValue } from 'recoil';
import { totalScreenState } from '../MobileFrame';

interface IMenuProps {
  menuList: MenuItem[],
  textColor?: string,
}

export interface MenuItem {
  title: string,
  callback: () => void,
}

export function MenuList(input: IMenuProps) {
  const locationService = useInjection<LocationService>(LOCATION_SERVICE);
  const screen = useRecoilValue(totalScreenState);
  const styles = MenuStyle({
    ...screen,
    color: input.textColor ?? 'black',
  });

  return (
    <React.Fragment>
      {input.menuList.map((item, index) => (
        <p
          className={css(styles.button)}
          onClick={item.callback}
          key={'menu-' + index}
        >
          {item.title}
        </p>)
      )}
      <div className={css(styles.button)} onClick={() => openKakaoMobilePage()}>
        <Kakao className={css(styles.icon)}/>
        카카오톡 상담
      </div>
      <div className={css(styles.button)} onClick={() => locationService.callToService()}>
        <Call className={css(styles.icon)}/>
        고객센터 상담
      </div>
    </React.Fragment>
  );
}
