import { CreateStyle } from '../../../../shared/presentation/shared/CreateStyle';
import { spoqa } from '../../../../shared/css/css-utils';

export interface ButtonStylesProps {
  isMobile: boolean,
  isTablet: boolean,
}

export const ButtonStyles = (
  {
    isMobile,
    isTablet,
  }: ButtonStylesProps
) => CreateStyle({
  button: {
    width: `50px/@56px`,
    height: '100%',
    border: 'none',

    paddingLeft: '/42px@',
    paddingRight: '/42px@',
    textDecoration: 'none',

    display: 'flex',
    alignItems: 'center',
    justifyContent_: 'center/flex-start@center',

    color: '#002933',
    fill: '#002933',

    backgroundColor: 'inherit',
    fontFamily: spoqa,
    fontWeight: 300,
    fontSize: '3.14vw/19px',

    cursor: 'pointer',
    whiteSpace: 'pre',
  },
  button_top: {
    color: 'white',
    fill: 'white',
  },
  buttonIcon: {
    width: `25px/27px@28px`,
    height: `25px/27px@28px`,
    fill: 'inherit',
  },
  button_kakao: {
    width: `25px/25px@28px`,
    height: `25px/25px@28px`,
    fill: 'inherit',
  },
  text: {
    whiteSpace: 'pre',
  },
}, isMobile, isTablet, {isNoRatio: true});

