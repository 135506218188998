import React from 'react';
import { css } from 'aphrodite';
import { useRecoilValue } from 'recoil';
import { spoqa } from '../../../../shared/css/css-utils';
import { Image } from '../../../../shared/css/Image';
import { Assets } from '../../shared/Assets';
import { totalScreenState } from '../../../../shared/presentation/MobileFrame';
import { DesktopBr } from '../../../../shared/presentation/DesktopBr';
import { CreateStyle } from '../../../../shared/presentation/shared/CreateStyle';
import { NotDesktopBr } from '../../../../shared/presentation/NotDesktopBr';

function FreeProgress() {
  const {isMobile, isTablet} = useRecoilValue(totalScreenState);
  const styles = FreeProgressStyle(isMobile, isTablet);

  return (
    <div className={css(styles.background)} id={'section_2'}>
      <div className={css(styles.leftBox)}>
        <div className={css(styles.firstFont)}>
          개인사업자의 법인전환, <NotDesktopBr/>
          무료로<DesktopBr/>
          진행할 수 있다는 사실 <NotDesktopBr/>
          알고계셨나요?
        </div>
        <div className={css(styles.secondFont)}>
          법인전환 후 필수인 세무기장, <span style={{color: '#06b3cb'}}>법인전문 세무사&#183;공인회계사</span>에게 <NotDesktopBr/>
          맡기시면 <DesktopBr/>
          100만원 이상의 법인전환 등기 수수료를 <NotDesktopBr/>
          100% 지원해 드립니다.
        </div>
      </div>
      <div className={css(styles.rightBox)}>
        <div>
          <Image src={Assets.Free01} className={css(styles.imageStyle)} alt="개인사업자"/>
          <div className={css(styles.rightTextFont)}>
            개인사업자
          </div>
        </div>
        <Image src={Assets.Path} className={css(styles.arrowStyle)} alt="화살표"/>
        <div>
          <Image src={Assets.Free02} className={css(styles.imageStyle)} alt="법인사업자"/>
          <div className={css(styles.rightTextFont)}>
            법인사업자
          </div>
        </div>
      </div>
    </div>
  );
}

export default FreeProgress;

const FreeProgressStyle = (isMobile: boolean, isTablet: boolean) => CreateStyle({
  background: {
    width: '100%',
    height: '415px/486px',

    backgroundColor: '#d9e5ec',

    display: 'flex',
    flexDirection_:  'column/',
    justifyContent_:  '/space-between',
    alignItems: 'center',

    fontFamily: spoqa,

    paddingLeft:  '/272px',
    paddingRight:  '/282px',

    position: 'relative',
    overflowX: 'hidden',
  },
  leftBox: {},
  firstFont: {
    fontWeight: 300,
    fontSize:  '25px/28px',
    lineHeight:  '1.42/1.37',
    color: '#051a43',
    textAlign_:  'center/',

    marginBottom:  '5px/13px',
    marginTop:  '41px/',
  },
  secondFont: {
    fontWeight: 300,
    fontSize:  '15px/18px',
    lineHeight: 1.72,
    color: '#051a43',
    textAlign_:  'center/',

    marginBottom:  '25px/',
  },
  rightBox: {
    display: 'flex',
  },
  imageStyle: {
    width:  '100px/182px',
    height:  '100px/182px',

    marginBottom: '/21px',
  },
  arrowStyle: {
    width:  '24px/40px',
    height:  '18px/30px',

    marginLeft:  '25px/63px',
    marginRight:  '25px/61px',
    marginTop:  '41px/76px',
  },
  rightTextFont: {
    fontWeight: 300,
    fontSize:  '18px/28px',
    lineHeight:  '2.17/1.39',
    color: '051a43',
    textAlign: 'center',
  },
}, isMobile, isTablet);
