import { spoqa } from '../../css/css-utils';
import { CreateStyle } from '../shared/CreateStyle';

export const SuccessProcessStyle = (
  isMobile: boolean,
  isTablet: boolean,
  baseColor: string = '#1b315d',
  highlight: string = '#ccd4d6',
  buttonColor: string = '#1b315d',
) => CreateStyle({
  successOutline: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent_: 'space-between/',

    width: '100%/',
    height: '65vh/',
    margin: '/0 auto',
    padding: '4vh 2.84vh 12.42vh/3.4vw 0 0@52px 162px 130px',

    fontSize: '1.62vh/14px',
    fontFamily: spoqa,
    fontWeight_: `${500}/${300}`,
    lineHeight: '1.67/1.79',

    color: baseColor,
  },
  successTitle: {
    fontSize: '18px/1.46vw@21.6px',
    fontWeight_: `${400}/${300}@${400}`,
    lineHeight: '25px/1.39@30px',

    color: baseColor,
  },
  hrStyle: {
    width: '90vw/@100%',
    borderTop: 'solid 1px #ccd4d6',

    margin: '3vw 0/.93vw 0@15px 0px 21px',
    opacity: .5,
  },
  successSubTitle: {
    fontWeight_: `${400}/${300}@${400}`,
    fontSize: '18px/0.93vw@21.6px',
    lineHeight: '24px/1.67@28.8px',

    marginBottom: '1.2vw/1.2vw@13px',
    color: highlight,
  },
  successDesc: {
    fontWeight_: `${400}/${300}@${400}`,

    fontSize: '13px/0.72vw@15.6px',
    width: '/20vw',
    wordBreak: 'keep-all',
    lineHeight: '22px/1.79@26.4px',

    marginBottom: '/13.1vw',
    color: baseColor,
  },
  requestButton: {
    width: '100%/21vw',
    height: '50px/3vw@60px',

    backgroundColor: buttonColor,
    borderRadius: '4px/@8px',

    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

    textAlign: 'center',
    fontWeight_: `${400}/${500}@${400}`,
    fontSize: '13px/16px@15.6px',
    lineHeight: '16.34px/@19.61px',

    cursor: 'pointer',
    outline: 'none',
    userSelect: 'none',

    textDecoration: 'none',
    color: 'inherit',
  },
}, isMobile, isTablet);
