import { atom } from 'recoil';

export interface INewsState {
  title: string,
  writer: string,
  content: string,
  imagePath: string,
  linkUrl: string,
}

export const newsState = atom<INewsState>({
  key: 'newsState',
  default: {
    title: '',
    writer: '',
    content: '',
    imagePath: '',
    linkUrl: '',
  }
})

export interface NewsItem {
  title: string,
  description: string,
  descriptionTablet?: string,
  titleImage: string,
  thumbImage: string,
  mobileThumb?: string,
  content: string,
  writer: string,
  imagePath: string,
  linkUrl: string;
  simplify: string,
  isLast?: boolean,
}
