import React, { useState } from 'react';
import { css } from 'aphrodite';
import { useRecoilValue } from 'recoil';

import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { sharedColor, sharedShape } from '../../../../shared/css/SharedStyleSheet';
import { spoqa, } from '../../../../shared/css/css-utils';
import { Image } from '../../../../shared/css/Image';
import { SharedAssets } from '../../../../shared/assets/SharedAssets';
import { Assets } from '../../shared/Assets';
import { PhoneFrame } from '../../../../shared/assets/SharedImages';
import { totalScreenState } from '../../../../shared/presentation/MobileFrame';
import { CreateStyle } from '../../../../shared/presentation/shared/CreateStyle';

const EXAMPLE_MENUS = ['법인전환 접수', '세무사 프로필', '1:1 담당자 상담'];

function Example() {
  const {isMobile, isTablet} = useRecoilValue(totalScreenState);
  const [selectedIndex, setSelectedIndex] = useState(0);
  let slider: any;
  const sliderSetting = {
    autoplay: true,
    dots: false,
    arrows: false,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplaySpeed: 7000,
    speed: 100,
    pauseOnHover: false,
    beforeChange: (oldIndex: number, newIndex: number) => setSelectedIndex(newIndex),
  };

  const selectMenu = (index: number) => {
    slider.slickGoTo(index);
    setSelectedIndex(index);
  };

  const nextSlide = (): number => selectedIndex + 1;
  const previousSlide = (): number => selectedIndex - 1;

  const styles = ExampleStyle(isMobile, isTablet);

  return (
    <div className={css(styles.background)} id={'section_13'}>
      <div className={css(styles.buttons)}>
        <SharedAssets.Arrow
          className={css(styles.arrowButtonStyle, sharedShape.reverse)}
          onClick={() => selectMenu(previousSlide())}
        />
        <SharedAssets.Arrow
          className={css(styles.arrowButtonStyle)}
          onClick={() => selectMenu(nextSlide())}
        />
      </div>
      <div className={css(styles.leftBox)}>
        <div className={css(styles.firstLine)}>
          {EXAMPLE_MENUS.map((item, index) => (
            <React.Fragment key={'example-menu-' + index}>
              {selectedIndex === index
                ? <p className="mint bottom-line-mint-bold">
                  {item}
                </p>
                : <p className="blue-gray button" onClick={() => selectMenu(index)}>
                  {item}
                </p>
              }
            </React.Fragment>
          ))}
        </div>
        <p className={css(styles.secondLine)}>카카오톡을 이용한 편리한 소통</p>
        <p className={css(styles.thirdLine, sharedColor.darkBlue)}>
          법인전환 접수부터 사업자등록 대행까지 <span className="mint">모든 법인설립절차는 <br/>
          카카오톡으로 </span> 확인 가능하며, 세이브택스의 업종별 법인전문 <br/>
          세무사의 프로필을 받아보고 선택하실 수 있습니다.
        </p>
      </div>
      <div className={css(styles.example)}>
        <Image src={PhoneFrame} alt="핸드폰" className={css(styles.phoneFrame)}/>
        <div className={css(styles.kakaoExample)}>
          <Slider
            ref={c => slider = c}
            {...sliderSetting}
            className="slider"
          >
            <div>
              <Image
                src={Assets.Kakao_in1} alt="example01"
                className={css(styles.exampleImageStyle)}
              />
            </div>
            <div>
              <Image
                src={Assets.Kakao_in2} alt="example02"
                className={css(styles.exampleImageStyle)}
              />
            </div>
            <div>
              <Image
                src={Assets.Kakao_in3} alt="example03"
                className={css(styles.exampleImageStyle)}
              />
            </div>
          </Slider>
        </div>
      </div>
    </div>
  );
}

export default Example;

const ExampleStyle = (isMobile: boolean, isTablet: boolean) => CreateStyle({
  background: {
    height: '654px/752px@780px',
    backgroundColor: '#d9e5ec',

    position: 'relative',
    display_: 'flex/',
    flexDirection: 'column',
    alignItems: 'center',

    fontFamily: spoqa,

    paddingTop: '40px/168px',
    paddingLeft: '/272px',
  },
  leftBox: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems_: 'center/',
    width: '100%/550px',
    zIndex: 1,

    fontWeight: 300,
  },
  firstLine: {
    lineHeight: '1.67/36px',
    marginBottom: '6.3vw',
    width: '319px/23.8vw',
    order_: '3/1',

    display: 'flex',
    justifyContent: 'space-between',

    fontSize: `14px/20px`,
  },
  secondLine: {
    lineHeight: 1.38,
    marginBottom: '2.17vw/1.19vw',
    order_: '1/2',

    fontWeight: 300,
    fontSize: `24px/37px`,
  },
  thirdLine: {
    lineHeight: 1.67,
    order_: '2/3',
    marginBottom: '4.1vw/',

    fontWeight: 300,
    fontSize: '14px/17px',
    textAlign_: 'center/',
  },
  example: {
    position: 'absolute',
    width: '66vw/23.6vw@50vw',
    height: '99.5vw/34.42vw',
    right: '50vw/20vw',
    bottom_: '0/0@',
    top_: '/@34%',
    overflow: 'hidden',

    transform: 'translateX(50%)/',
  },
  phoneFrame: {
    width: '100%',
    height: '100%',
    position: 'absolute',
    zIndex: 2,
  },
  kakaoExample: {
    width: '88%/88%',
    height: '97vw/34vw',
    marginLeft: '4vw/1.4vw@3vw',
    marginTop: '3.5vw/.8vw',
    overflow: 'hidden',
  },
  exampleImageStyle: {
    width: '100%',
    height: '100%',
  },
  buttons: {
    position: 'absolute' as 'absolute',
    top: '19vw',
    left: 0,
    width: '100%',
    height: 'max-content',

    display_: 'none/flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  arrowButtonStyle: {
    width: '1.3vw',
    height: '2.6vw',
    margin: '0 5vw',

    opacity: 0.4,
    cursor: 'pointer',
    fill: 'gray',
    transition: '.3s all',
    ':hover': {
      opacity: 1,
    }
  },
}, isMobile, isTablet);
