import { css } from 'aphrodite';
import React from 'react';
import { Link } from 'react-router-dom';
import { atom, useAtom } from 'jotai';

import { ReactComponent as TickIcon } from '../icons/tick.svg';
import { requestAtom } from '../../store/RequestAtom';

interface IProps {
  styles: { checkDiv: Object, checkBox: Object, privacyPolicy: Object, tick: Object }
}

const privacyCheckAtom = atom(
  (get) => get(requestAtom).isPrivacyPolicyChecked,
  (get, set, update: boolean) => {
    set(requestAtom, { ...get(requestAtom), isPrivacyPolicyChecked: update });
  }
);

export function PrivacyPolicyCheckBox({ styles }: IProps) {
  const [isPrivacyPolicyChecked, setPrivacyPolicy] = useAtom(privacyCheckAtom);
  // const [request, setRequest] = useRecoilState(requestState);

  const togglePrivacyPolicy = () => setPrivacyPolicy(!isPrivacyPolicyChecked);

  return (
    <div
      className={css(styles.checkDiv)}
      data-testid='checkbox'
    >
      <div
        className={css(styles.checkBox)}
        onClick={togglePrivacyPolicy}
      >
        {isPrivacyPolicyChecked && <TickIcon className={css(styles.tick)}/>}
      </div>
      <div>
        <Link
          className={css(styles.privacyPolicy)}
          to={'/privacy_policy'}
        >
          개인정보취급방침
        </Link>
        <span onClick={togglePrivacyPolicy}>에 동의합니다.</span>
      </div>
    </div>
  );
}
