import { CreateStyle } from '../../../../shared/presentation/shared/CreateStyle';
import { ButtonStylesProps } from './ButtonStyles';
import { spoqa } from '../../../../shared/css/css-utils';

export const RenewalButtonStyle = (
  {isMobile, isTablet}: ButtonStylesProps
) => CreateStyle({
  button: {
    width: `50px/`,
    height: '100%',
    border: 'none',

    paddingLeft: '/32px',
    paddingRight: '/32px',
    textDecoration: 'none',

    display: 'flex',
    alignItems: 'center',
    justifyContent_: 'center/flex-start',

    color: '#002933',
    fill: '#002933',

    backgroundColor: 'inherit',
    fontFamily: spoqa,
    fontWeight: 400,
    fontSize: '3.14vw/17px',
    lineHeight: '/20px',

    cursor: 'pointer',
    whiteSpace: 'pre',
  },
  button_top: {
    color: 'white',
    fill: 'white',
  },
  buttonIcon: {
    width: `25px/22px`,
    height: `25px/22px`,
    fill: 'inherit',
  },
  button_kakao: {
    width: `25px/25px`,
    height: `25px/25px`,
    fill: 'inherit',
  },
  text: {
    whiteSpace: 'pre',
  },
  iconSpace: {
    marginLeft: '/6px',
  }
}, isMobile, isTablet, {isNoRatio: true});
