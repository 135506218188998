import React from 'react';
import { css } from 'aphrodite';
import { useRecoilValue } from 'recoil';

import { spoqa } from '../../../../shared/css/css-utils';
import { totalScreenState } from '../../../../shared/presentation/MobileFrame';
import { DesktopBr } from '../../../../shared/presentation/DesktopBr';
import { NotDesktopBr } from '../../../../shared/presentation/NotDesktopBr';
import { CreateStyle } from '../../../../shared/presentation/shared/CreateStyle';

const CASE_LIST = (isMobile: boolean) => ([
  {
    case: '상황',
    method01: '법인전환 방법',
    isTop: true,
  },
  {
    case: '사업용 부동산이 \n있는 경우',
    title01: isMobile ? '소규모 사업 ' : '소규모 사업 : ',
    method01: '사업포괄양수도를 통한 법인전환',
    title02: isMobile ? '대규모 사업 ' : '대규모 사업 : ',
    method02: '현물출자를 통한 법인전환',
  },
  {
    case: '사업용 부동산이 \n없는 경우',
    title01: isMobile ? '옵션 1 ' : '옵션 1 : ',
    method01: '일반 사업포괄양수도를 통한 법인전환',
    title02: isMobile ? '옵션 2 ' : '옵션 2 : ',
    method02: '신규법인설립을 통한 법인전환',
  },
  {
    case: '중소기업 통합에 \n해당하는 경우',
    method01: '중소기업 통합을 통한 법인전환',
    isLast: true,
  },
]);

function Method() {
  const {isMobile, isTablet} = useRecoilValue(totalScreenState);
  const styles = MethodStyle(isMobile, isTablet);
  return (
    <div className={css(styles.background)} id={'section_6'}>
      <div className={css(styles.titleFont)}>
        개인사업자의 <DesktopBr/>
        법인전환 방법
      </div>
      <div className={css(styles.totalBox)}>
        {CASE_LIST(isMobile).map((item, index) => (
          <div key={'case-' + index}>
            <div className={item.isTop === true
              ? css(styles.topListBox)
              : item.isLast
                ? css(styles.lastListBox)
                : css(styles.listBox)}>
              <div className={css(styles.caseBox)}>
                {item.case}
              </div>
              <div>
                <div
                  className={index !== 0 && index !== 3 ? css(styles.methodBox) : css(styles.methodBox, styles.borderNone)}>
                  {index !== 0 && index !== 3
                  && <span style={{color: '#838ba3'}}>{item.title01}<NotDesktopBr/></span>
                  }
                  {item.method01}
                </div>
                {(index !== 0 && index !== 3) &&
                <div className={css(styles.methodBox, styles.borderNone)}>
                  <span style={{color: '#838ba3'}}>{item.title02}<NotDesktopBr/></span>
                  {item.method02}
                </div>
                }
              </div>
            </div>

          </div>
        ))}
      </div>
    </div>
  );
}

export default Method;

const MethodStyle = (isMobile: boolean, isTablet: boolean) => CreateStyle({
  background: {
    height: '500px/505px',
    backgroundColor: '#d9e5ec',

    display: 'flex',
    flexDirection_: 'column/',
    justifyContent: 'center',
    alignItems_: '/@center',

    fontFamily: spoqa,

    paddingTop: '20px/89px',
    paddingLeft: '21px/',
  },
  titleFont: {
    fontSize: '24px/38px',
    fontWeight: 300,
    lineHeight: '1.42/1.37',
    color: '#051a43',

    marginRight: '/255px',
  },
  totalBox: {
    width: '370px/900px',
    marginTop: '21px/17px',
  },
  topListBox: {
    height: '30px/60px',

    display: 'flex',

    color: '#06b3cb',
  },
  listBox: {
    height: '130px/90px',

    display: 'flex',

    color: '#051a43',

    borderBottom: 'solid 1px rgba(131, 139, 163, .3)',

    marginBottom: '/20px',
    paddingTop: '10px/',
  },
  lastListBox: {
    height: '50px/80px',

    display: 'flex',

    color: '#051a43',
    paddingTop: '10px/',
  },
  caseBox: {
    width: '120px/400px',
    height: '100%',
    whiteSpace_: 'pre/',

    fontWeight: 300,
    fontSize: '14px/19px',
    lineHeight: 1.72,

  },
  methodBox: {
    width: '249px/400px',
    height: '50%',

    fontWeight: 300,
    fontSize: '14px/19px',
    lineHeight: 1.72,

    borderBottom: 'solid 1px rgba(131, 139, 163, .3)/',
    paddingTop: '3px/',
  },
  borderNone: {
    borderBottom: 'none',
  },
}, isMobile, isTablet);
