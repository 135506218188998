import React from 'react';
import { css } from 'aphrodite';
import { useHistory } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { LOCATION_SERVICE, LocationService } from '../../../../shared/store/LocationStore';
import { scrollWindow } from '../../../../shared/helper/Util';
import { ReactComponent as CallLogo } from '../../../../shared/presentation/icons/call.svg';
import { totalScreenState } from '../../../../shared/presentation/MobileFrame';
import { IHistory, isPartnershipPage, requestPage } from '../../../../shared/presentation/HistoryHelper';
import { useInjection } from '../../../../shared/presentation/InversifyProvider';
import { RenewalButtonStyle } from './RenewalButtonStyle';

export function RequestButton() {
  const {isMobile, isTablet, isDesktop, isTop} = useRecoilValue(totalScreenState);
  const history = useHistory<IHistory>();
  const locationService = useInjection<LocationService>(LOCATION_SERVICE);
  const showRequestOrCall = () => {
    if (!isDesktop) {
      locationService.callToService();
      return;
    }
    if (isPartnershipPage(history)) {
      scrollWindow('request');
      return;
    }
    history.push(requestPage());
  };
  const styles = RenewalButtonStyle({
    isMobile,
    isTablet,
  });

  return (
    <div
      className={css(styles.button, isTop && styles.button_top)}
      onClick={showRequestOrCall}
      id={'call_request_button'}
    >
      <CallLogo className={css(styles.buttonIcon)}/>
      {isDesktop &&
      <p className={css(styles.iconSpace)}>
        고객센터 {process.env.REACT_APP_SERVICE_CENTER_NUMBER}
      </p>
      }
    </div>
  );
}


