import { ReactComponent as LogoIcon } from '../../assets/svgs/logo.svg';

import Award1 from '../../assets/pngs/award1.png'
import Award2 from '../../assets/pngs/award2.png'
import Benefit01 from '../../assets/pngs/benefit1.png'
import Benefit02 from '../../assets/pngs/benefit2.png'
import Benefit03 from '../../assets/pngs/benefit3.png'
import ComplicateBackground from '../../assets/jpgs/complicate-background.jpg'
import ComplicateMobile from '../../assets/jpgs/complicate-mobile.jpg'
import Free01 from '../../assets/pngs/free01.png'
import Free02 from '../../assets/pngs/free02.png'
import Kakao_in1 from '../../assets/pngs/kakao_in1.png'
import Kakao_in2 from '../../assets/pngs/kakao_in2.png'
import Kakao_in3 from '../../assets/pngs/kakao_in3.png'
import MainBackground from '../../assets/jpgs/main-background.jpg'
import MainBackground02 from '../../assets/jpgs/main-background02.jpg'
import MainMobile from '../../assets/pngs/main-mobile.png'
import Map from '../../assets/pngs/map.png'
import News from '../../assets/jpgs/news.jpg'
import NewsLogo01 from '../../assets/pngs/news-logo01.png'
import NewsLogo02 from '../../assets/pngs/news-logo02.png'
import Path from '../../assets/pngs/path.png'
import PhoneFrame from '../../assets/pngs/phone-frame.png'
import Process01 from '../../assets/pngs/process01.png'
import Process02 from '../../assets/pngs/process02.png'
import Process03 from '../../assets/pngs/process03.png'
import Process04 from '../../assets/pngs/process04.png'
import Process05 from '../../assets/pngs/process05.png'
import RequestBackground from '../../assets/jpgs/request-background.jpg'
import RequestMobile from '../../assets/jpgs/request-mobile.jpg'
import RequestPopupDesk from '../../assets/jpgs/request-page.jpg'
import RequestPopupMobile from '../../assets/jpgs/request-popup-mobile.jpg'
import Review1 from '../../assets/jpgs/review1.jpg'
import Review2 from '../../assets/jpgs/review2.jpg'
import Review3 from '../../assets/jpgs/review3.jpg'
import ReviewMobile1 from '../../assets/jpgs/review-mobile1.jpg'
import ReviewMobile2 from '../../assets/jpgs/review-mobile2.jpg'
import ReviewMobile3 from '../../assets/jpgs/review-mobile3.jpg'
import ServiceBackground from '../../assets/jpgs/service-background.jpg'
import ServiceMobile from '../../assets/pngs/service-mobile.png'
import SupportBackground from '../../assets/jpgs/support-background.jpg';
import SupportBackgroundMobile from '../../assets/jpgs/support-background-m.jpg';

import Target01 from '../../assets/svgs/target01.svg'
import Target02 from '../../assets/svgs/target02.svg'
import Target03 from '../../assets/svgs/target03.svg'
import Target04 from '../../assets/svgs/target04.svg'
import Target05 from '../../assets/svgs/target05.svg'
import Target06 from '../../assets/svgs/target06.svg'

import MainVideo from '../../assets/main-video.mp4'

export const Assets = {
  MainVideo,
  LogoIcon,
  Award1,
  Award2,
  Benefits: [
    Benefit01,
    Benefit02,
    Benefit03,
  ],
  ComplicateBack: (isMobile: boolean) => isMobile ? ComplicateMobile : ComplicateBackground,
  Free01,
  Free02,
  Kakao_in1,
  Kakao_in2,
  Kakao_in3,
  MainBack: (isMobile: boolean) => isMobile ? MainMobile : MainBackground,
  MainBackground02,
  MainMobile,
  Map,
  News,
  NewsLogos: [
    NewsLogo01,
    NewsLogo02,
  ],
  Path,
  PhoneFrame,
  Processes: [
    Process01,
    Process02,
    Process03,
    Process04,
    Process05,
  ],
  RequestBack: (isMobile: boolean) => isMobile ? RequestMobile : RequestBackground,
  RequestPopupBack: (isMobile: boolean) => isMobile ? RequestPopupMobile : RequestPopupDesk,
  ReviewsDesk: [
    Review1,
    Review2,
    Review3,
  ],
  ReviewsMobile: [
    ReviewMobile1,
    ReviewMobile2,
    ReviewMobile3,
  ],
  ServiceBack: (isMobile: boolean) => isMobile ? ServiceMobile : ServiceBackground,
  SupportBackground,
  SupportBackgroundMobile,
  Targets: [
    Target01,
    Target02,
    Target03,
    Target04,
    Target05,
    Target06,
  ],
}
