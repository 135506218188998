import React from 'react';
import { css } from 'aphrodite';
import { useRecoilValue } from 'recoil';

import { createCalc } from '../../../../shared/helper/Util';
import { spoqa } from '../../../../shared/css/css-utils';
import { Image } from '../../../../shared/css/Image';
import { Assets } from '../../shared/Assets';
import { totalScreenState } from '../../../../shared/presentation/MobileFrame';
import { CreateStyle } from '../../../../shared/presentation/shared/CreateStyle';

const TARGET_LIST = [
  {
    content01: '',
    content02: '성실신고확인제도 ',
    content03: '대상인\n사업자',
    mobileContent:
      <>
        <span style={{color: 'white'}}>성실신고확인제도</span><br/>
        대상인 사업자
      </>,
  },
  {
    content01: '금융소득 및 임대소득으로\n',
    content02: '종합소득세 부담',
    content03: '이\n큰 사업자',
    mobileContent:
      <>
        금융소득 및 임대<br/>
        소득으로 <span style={{color: 'white'}}>종합소득세<br/>
        부담</span>이 큰 사업자
      </>,
  },
  {
    content01: '소유 부동산 가격\n상승으로 ',
    content02: '양도세 부담',
    content03: '이\n가중되는 사업자',
    mobileContent:
      <>
        소유 부동산 가격<br/>
        상승으로 <span style={{color: 'white'}}>양도세<br/>
        부담</span>이 가중되는<br/>사업자
      </>,
  },
  {
    content01: '',
    content02: '가업상속공제',
    content03: '를 받을 수\n없거나 상속과 증여세\n부담이 큰 사업자',
    mobileContent:
      <>
        <span style={{color: 'white'}}>가업상속공제</span>를<br/>
        받을 수 없거나 상속과<br/>
        증여세 부담이 큰<br/>
        사업자
      </>,
  },
  {
    content01: '정책자금 지원 및 ',
    content02: '고용\n지원 정책자금의 혜택',
    content03: '을\n원하는 사업자',
    mobileContent:
      <>
        정책자금 지원 및<br/>
        <span style={{color: 'white'}}>고용 지원 정책자금의<br/>
        혜택</span>을 원하는 사업자
      </>,
  },
  {
    content01: '정부기관, 대기업 ',
    content02: '입찰 및\n납품',
    content03: ' 등을 위해 신용도를\n높여야 하는 사업자',
    mobileContent:
      <>
        정부기관, 대기업 <span style={{color: 'white'}}>입찰<br/>
        및 납품</span> 등을 위해<br/>
        신용도를 높여야 하는<br/>
        사업자
      </>,
  },
];

function Target() {
  const {isMobile, isTablet} = useRecoilValue(totalScreenState);
  const styles = TargetStyle(isMobile, isTablet);

  return (
    <div className={css(styles.background)} id={'section_4'}>
      <div>
        <div className={css(styles.firstFont)}>
          개인사업자의 법인전환 대상자
        </div>
        <div className={css(styles.targetTotal)}>
          {TARGET_LIST.map((item, index) => (
            <div key={'target-' + index}>
              <div className={css(styles.targetBox)}>
                <Image
                  src={Assets.Targets[index]}
                  className={css(styles.imageStyle)}
                  alt="대상"
                />
                <div className={css(styles.contentFont)}>
                  {isMobile ?
                    item.mobileContent
                    :
                    <>
                      {item.content01}
                      <span style={{color: 'white'}}>{item.content02}</span>
                      {item.content03}
                    </>
                  }
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default Target;

const TargetStyle = (isMobile: boolean, isTablet: boolean) => CreateStyle({
  background: {
    height: '420px/500px',
    backgroundColor: '#051a43',

    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',

    fontFamily: spoqa,

    paddingTop: '28px/',
  },
  firstFont: {
    fontWeight: 300,
    fontSize: '24px/33px',
    color: 'white',

    marginBottom: '31px/50px',
  },
  targetTotal: {
    width: '360px/1380px',
    display: 'flex',
    justifyContent: 'space-between',
    flexWrap_: 'wrap/',
  },
  targetBox: {},
  imageStyle: {
    width: '50px/70px',
    height: '45px/70px',

    marginBottom: createCalc(20, 1920),
  },
  contentFont: {
    width: '106px/',
    fontWeight: 300,
    fontSize: '12px/18px',
    lineHeight: 1.6,
    color: '#838ba3',
    whiteSpace: 'pre',

    marginBottom: '40px/',
  },
}, isMobile, isTablet);
