import React from 'react';
import { css } from 'aphrodite';
import { useRecoilValue } from 'recoil';

import { RequestBannerStyle } from './RequestBannerStyle';
import { Image } from '../../css/Image';
import { totalScreenState } from '../MobileFrame';

interface Props {
  imagePath: string,
  title: string,
  mobileTitle: string,
  subTitle: string,
  comment?: string,
  privacyPolicyComment: string,
}

export function RequestBanner(
  {
    imagePath,
    title,
    mobileTitle,
    subTitle,
    comment,
    privacyPolicyComment,
  }: Props
) {
  const { isMobile, isTablet } = useRecoilValue(totalScreenState);

  const styles = RequestBannerStyle(isMobile, isTablet);

  return (
    <div className={css(styles.outline)}>
      <Image
        className='background'
        alt='배너'
        src={imagePath}
      />
      <div className={css(styles.leftDiv)}>
        <div>
          <div className={css(styles.title)}>
            {title}
          </div>
          <div className={css(styles.mobileTitle)}>
            {mobileTitle}
          </div>
          <div className={css(styles.subTitle)}>
            {subTitle}
          </div>
          <div className={css(styles.comment)}>
            {comment}
          </div>
        </div>
        <div className={css(styles.description)}>
          {privacyPolicyComment}
        </div>
      </div>
    </div>
  );
}
