import React, { useEffect, useState } from 'react';
import { css } from 'aphrodite';
import Slider from 'react-slick';
import * as _ from 'lodash';
import { useRecoilValue, useRecoilValueLoadable } from 'recoil';

import { createPixel } from '../../../../shared/helper/Util';
import { robotoRegular, spoqa } from '../../../../shared/css/css-utils';
import { customersState } from '../../../../shared/store/CustomerStore';
import { Image } from '../../../../shared/css/Image';
import { Assets } from '../../shared/Assets';
import { totalScreenState } from '../../../../shared/presentation/MobileFrame';
import { CreateStyle } from '../../../../shared/presentation/shared/CreateStyle';

const sliderSetting = {
  autoplay: true,
  dots: false,
  arrows: false,
  infinite: true,
  slidesToShow: 4,
  slidesToScroll: 1,
  speed: 500,
  autoplaySpeed: 2000,
  vertical: true,
  verticalSwiping: true,
  swipeToSlide: false,
  touchMove: false,
};

function Realtime() {
  const {isMobile, isTablet, isDesktop} = useRecoilValue(totalScreenState);
  const customers = useRecoilValueLoadable(customersState(process.env.REACT_APP_CUSTOMER_API_URL!));
  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    window.addEventListener('resize', _.throttle(() => setWidth(window.innerWidth), 100));
    return () => window.removeEventListener('resize', _.throttle(() => setWidth(window.innerWidth), 100));
  }, []);

  const styles = RealtimeStyle({isMobile, isTablet, width});

  return (
    <div className={css(styles.background)} id={'section_3'}>
      {isDesktop &&
      <div className={css(styles.leftDiv)}>
        <div className={css(styles.titleFont)}>
          AWARDS
        </div>
        <div className={css(styles.contentFont)}>
          법인설립지원센터<br/>
          소비자 만족도 1위 수상
        </div>
        <div className={css(styles.awardImageBox)}>
          <Image
            src={Assets.Award1}
            className={css(styles.awardImageStyle)}
            alt="상"
          />
          <Image
            src={Assets.Award2}
            className={css(styles.awardImageStyle)}
            alt="상"
          />
        </div>
        <div className={css(styles.awardFont)}>
          Award Winning<br/>
          Korea Marketing Forum
        </div>
      </div>
      }

      <div className={css(styles.rightDiv)}>
        <div style={{display: 'flex'}}>
          <div className={css(styles.realtimeFont)}>
            실시간 신청현황
          </div>
          <div className={css(styles.standardFont)}>
            (담당자 접수 기준)
          </div>
        </div>
        <div className={css(styles.topLineBox)}>
          <div className={css(styles.elementBox)}>
            날짜
          </div>
          <div className={css(styles.elementBox)}>
            이름
          </div>
          <div className={css(styles.elementBox)}>
            업종
          </div>
          <div className={css(styles.elementBox)}>
            지역
          </div>
        </div>
        <div className={css(styles.listTotalBox)}>
          {customers.state === 'hasValue' && (
            <Slider {...sliderSetting}>
              {customers.contents.map((item, index) => (
                <div className="no-select" key={'customer-' + index}>
                  <div className={css(styles.listBox)}>
                    <p className={css(styles.elementBox)}>
                      {item.date_creation}
                    </p>
                    <p className={css(styles.elementBox)}>
                      {item.user_name.replace('**', '00')}
                    </p>
                    <p className={css(styles.elementBox)}>
                      {item.industry}
                    </p>
                    <p className={css(styles.elementBox)}>
                      {item.dosi_name}
                    </p>
                  </div>
                </div>
              ))}
            </Slider>
          )}
        </div>
      </div>
    </div>
  );
}

export default Realtime;

const RealtimeStyle = (
  {
    isMobile,
    isTablet,
    width
  }: { isMobile: boolean, isTablet: boolean, width: number }) => CreateStyle({
  background: {
    display: 'flex',
    justifyContent_: 'center/',
    overflow: 'hidden',

    width: '100%',
    height: '342px/534px',
    padding_: '8.21vw 0 0/3.8vw 10.76vw 3.95vw 14.1vw',

    backgroundColor: 'white',

    fontFamily: spoqa,
  },
  leftDiv: {
    paddingTop: '.69vw',
  },
  titleFont: {
    fontSize: '.9vw',
    fontFamily: robotoRegular,
    letterSpacing: '1.73px',
    color: '#06b3cb',

    marginBottom: '1.73vw',
  },
  contentFont: {
    fontWeight: 300,
    fontSize: '1.45vw',
    lineHeight: 1.48,
    color: '#051a43',

    marginBottom: '2.08vw',
  },
  awardImageBox: {
    width: '/375px',

    display: 'flex',
    justifyContent: 'space-between',
  },
  awardImageStyle: {
    width: '/169px',
    height: '/100px',
  },
  awardFont: {
    fontFamily: robotoRegular,
    fontSize: '.9vw',
    lineHeight: 1.38,
    color: '#c5ced0',

    marginTop: '1.38vw',
  },
  rightDiv: {
    width: '360px/867px',

    marginLeft: '/9.1vw',
    position_: 'relative/',
    whiteSpace_: '/pre',
  },
  realtimeFont: {
    fontWeight: 300,
    fontSize: '20px/28px',
    lineHeight: '1.45/1.48',
    color: '#051a43',

    marginRight: '/519px',
  },
  standardFont: {
    width: '/500px',
    fontWeight: 300,
    fontSize: '13px/21px',
    lineHeight: '1.67/1.69',
    color: '#838ba3',

    position_: 'absolute/',
    right: '0/',
    top: '5/',
  },
  topLineBox: {
    width: '100%',
    height: '50px/72px',
    borderBottom: 'solid 1px #e0e0e0',

    fontWeight: 300,
    fontSize: '12px/21px',
    lineHeight: '1.67/1.72',
    color: '#838ba3',

    display: 'flex',
  },
  listTotalBox: {
    marginTop: -2,
  },
  listBox: {
    width: '100%',
    height: isMobile ? createPixel(47, 414, width) : isTablet ? createPixel(47, 414, width) : createPixel(72, 1920, width),

    fontWeight: 300,
    fontSize: '13px/21px',
    lineHeight: 1.72,
    color: '#051a43',

    borderTop: 'solid 1px #e0e0e0',

    display: 'flex',
  },
  elementBox: {
    width: '88px/192px',
    height: '100%',

    display: 'flex',
    alignItems: 'center',
    lineHeight: 1.69,
  },
}, isMobile, isTablet);
