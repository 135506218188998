import React, { useState } from 'react';
import { css } from 'aphrodite';
import { useRecoilValue } from 'recoil';

import { spoqa } from '../../../../shared/css/css-utils';
import SharedStyleSheet, { sharedColor } from '../../../../shared/css/SharedStyleSheet';
import { Image } from '../../../../shared/css/Image';
import { Assets } from '../../shared/Assets';
import { totalScreenState } from '../../../../shared/presentation/MobileFrame';
import { CreateStyle } from '../../../../shared/presentation/shared/CreateStyle';

const SUPPORT_LIST = (isMobile: boolean) => ([
  {
    title: '법인전환\n' +
      '등기 수수료',
    content: '법인전환 전문 변호사\n의 프리미엄 법인전환\n등기 수수료를 100%\n지원해 드립니다.',
  },
  {
    title: '사업자등록',
    content: '법인전환 후 정확한\n사업자등록까지 \n100% 무료로 대행해\n드립니다.',
  },
  {
    title: '최신 정관 제작',
    content: '법인전문 변호사가 작\n성하고 검토한 고품질\n정관을 무료로 제공해\n드립니다.',
  },
  {
    title: '법인인감도장',
    content: '법인전환 후 필수적으\n로 필요한 법인인감\n도장까지 무료로 제작\n하여 보내드립니다.',
  },
  {
    title: '등기부등본',
    content: '등기부등본 원본을 \n각 3부씩 출력하여\n 보내드립니\n다.',
  },
  {
    title: isMobile ? '고품질\n근로계약서 등 \n각종 양식' : '고품질\n근로계약서 등\n각종 양식',
    content: '노무사, 변호사가 검\n토한 고품질 근로계약\n서와 총 24종의 양식\n을 제공해 드립니다.',
  },
]);

function Support() {
  const {isMobile, isTablet, isDesktop} = useRecoilValue(totalScreenState);
  const [selectedIndex, setSelectedIndex] = useState(-1);

  const styles = SupportStyle(isMobile, isTablet);
  const shared = SharedStyleSheet(isMobile);
  return (
    <div className={css(styles.background)} id={'section_10'}>
      <div className={css(styles.firstFont)}>
        100만원 이상의 <br className={css(shared.onlyMobile)}/>
        법인전환 수수료를 지원받으세요
      </div>
      <div className={css(styles.secondFont)}>
        무료법인전환 신청 시 아래의 <span style={{color: '#008fb1'}}>6가지 혜택</span>을 모두 드립니다.
      </div>
      <div className={css(styles.supportTotalBox)}>
        <Image
          src={isDesktop ? Assets.SupportBackground : Assets.SupportBackgroundMobile}
          className={css(styles.backgroundStyle)}
          alt="지원"
        />
        {SUPPORT_LIST(isMobile).map((item, index) => (
          <div
            key={'support-' + index}
            className={selectedIndex === index
              ? css(styles.supportBox, sharedColor.darkBlueFaintBack)
              : css(styles.supportBox, sharedColor.transparentBack)
            }
            onMouseEnter={() => setSelectedIndex(index)}
            onMouseLeave={() => setSelectedIndex(-1)}
          >
            <div className={css(styles.numberFont)}>
              {'0' + (index + 1)}
            </div>
            <div>
              <div className={css(styles.titleFont)}>
                {item.title}
              </div>
              {selectedIndex === index
              && <div className={css(styles.contentFont, shared.onlyDesktop)}>
                {item.content}
              </div>
              }
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Support;

const SupportStyle = (isMobile: boolean, isTablet: boolean) => CreateStyle({
  background: {
    height: '680px/989px',

    paddingLeft: '20px/272px@',
    paddingTop: '40px/150px',

    fontFamily: spoqa,

    display_: '/@flex',
    flexDirection_: '/@column',
    alignItems_: '/@center',
  },
  firstFont: {
    fontWeight: 300,
    fontSize: '24px/40px',
    lineHeight: '1.42/',
    color: '#002933',

    marginBottom: '6px/9px',
  },
  secondFont: {
    fontWeight: 300,
    fontSize: '14px/21px',
    lineHeight: 1.72,
    color: '#002933',
  },
  supportTotalBox: {
    width: '370px/1350px',
    height: '480px/550px',

    position: 'relative',

    display: 'flex',

    flexWrap_: 'wrap/',

    marginTop: '23px/67px',
  },
  backgroundStyle: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    top: 0,
    left: 0,
    zIndex: 1,
  },
  supportBox: {
    width: '33.3%/16.7%',
    height: '50%/100%',

    fontWeight: 300,
    color: 'white',
    zIndex: 2,

    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',

    paddingTop: '21px/52px',
    paddingBottom: '16px/30px',
    paddingLeft: '11px/23px',
    paddingRight: '11px/23px',

    borderRight: 'solid 1px rgba(255, 255, 255, 0.2)',
    borderTop: 'solid 1px rgba(255, 255, 255, 0.2)/',

    transition: '0.15s',
  },
  numberFont: {
    fontSize: '14px/25px',

    marginBottom: '10px/',
  },
  titleFont: {
    fontSize: '16px/26px',
    lineHeight: '22px/40px',

    whiteSpace: 'pre',
  },
  contentFont: {
    marginTop: '10px/13px',
    fontSize: '10px/19px',
    whiteSpace: 'pre',
  },
}, isMobile, isTablet);
