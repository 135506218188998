import React, { RefObject } from 'react';
import { css } from 'aphrodite';
import { useRecoilValue } from 'recoil';
import { useHistory } from 'react-router-dom';
import Cookies from 'universal-cookie';
import { useAtomValue, useSetAtom } from 'jotai';

import { InputFormStyle } from './InputFormStyle';
import { isIE } from '../../helper/Util';
import { totalScreenState } from '../MobileFrame';
import { NameInput } from '../../../makebiz/pages/request-popup/NameInput';
import { PhoneInput } from '../../../makebiz/pages/request-popup/PhoneInput';
import { QuestionInput } from '../../../makebiz/pages/request-popup/QuestionInput';
import { useInjection } from '../InversifyProvider';
import { IHistory, requestPage } from '../HistoryHelper';
import { PrivacyPolicyCheckBox } from './PrivacyPolicyCheckBox';
import { checkerAtom } from '../../store/RequestAtom';
import { CustomRequestService } from '../../service/RequestService';
import { requestErrorAtom } from '../../store/RequestErrorAtom';
import { totalKeywordAtom } from '../KeywordAtom';

interface Props {
  baseColor?: string,
  buttonColor?: string,
  reference?: RefObject<HTMLDivElement>,
  cookie?: Cookies
}

export function DefaultInputForm({baseColor, buttonColor, reference}: Props) {
  const {isMobile, isTablet} = useRecoilValue(totalScreenState);
  const request = useAtomValue(checkerAtom);
  const setError = useSetAtom(requestErrorAtom);
  const keyword = useAtomValue(totalKeywordAtom);
  const requestService = useInjection(CustomRequestService);
  const history = useHistory<IHistory>();

  const moveToCheckPage = () => {
    if (reference?.current !== null && !isIE()) {
      reference?.current?.scrollTo(0, 0);
    }

    if (!request.isPrivacyPolicyChecked) {
      alert('개인정보취급방침에 동의해주세요.');
      return;
    }

    if (!request.isValid) {
      setError(request);
      return;
    }

    requestService?.sendRequest(request.toRequestForm(), keyword);
    history.push(requestPage(1));
  };

  const styles = InputFormStyle(
    isMobile,
    baseColor,
    buttonColor,
    isTablet
  );
  return (
    <div className={css(styles.inputOutline)} id={'input_frame'}>
      <NameInput styles={styles}/>
      <PhoneInput styles={styles}/>
      <QuestionInput styles={styles}/>
      <PrivacyPolicyCheckBox styles={styles}/>
      <div
        className={css(styles.requestButton)}
        onClick={moveToCheckPage}
        id={'send_button_check'}
      >
        <p className="white">신청하기</p>
      </div>
    </div>
  );
}
