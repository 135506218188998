import { spoqa } from '../../css/css-utils';
import { CreateStyle } from '../shared/CreateStyle';

export const InputFormStyle = (
  isMobile: boolean,
  baseText: string = '#1b315d',
  buttonColor: string = '#1b315d',
  isTablet?: boolean,
) => CreateStyle({
  inputOutline: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',

    width: '100%/',
    padding: '7.4vw 5.3vw 10.5vw/2.8vw 3.64vw 0@53px 162px',

    fontSize: '12px/14px@15px',
    fontFamily: spoqa,
    fontWeight_: `${500}/${300}`,
    lineHeight: '1.67/1.79',
  },
  inputBox: {
    width: '100%',
    height: '50px/3.1vw@60px',

    marginBottom: '15px/1.04vw@18px',
    position: 'relative',
  },
  input: {
    width: '100%',
    height: '100%',

    border: 'solid 1px rgba(27, 49, 93, 0.22)',
    borderRadius: 0,

    paddingLeft: '20px/1.3vw',

    fontFamily: spoqa,
    fontWeight_: `${500}/${300}`,
    fontSize: '12px/14px@15px',
    color: baseText,

    '::placeholder': {
      color: baseText,
      opacity: 0.5,
    }
  },
  error: {
    position: 'absolute',
    width: 'max-content',
    height: '50px/3.1vw',

    top: 0,
    right: '20px/.7vw',

    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',

    fontWeight_: `${500}/${300}`,
    color: '#ff0000',

    cursor: 'default',
  },
  dropdownError: {
    position: 'absolute',
    width: 'max-content',
    height: '50px/3.1vw',

    top: 0,
    right: '20px/2.7vw',

    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',

    fontWeight_: `${500}/${300}`,
    color: '#ff0000',

    cursor: 'default',
  },
  textAreaBox: {
    width: '100%',
    height: '189px/12.7vw@221px',

    marginBottom: '15px/1.04vw@19px',
    position: 'relative',
  },
  textArea: {
    width: '100%',
    height: '100%/12.7vw',
    border: 'solid 1px rgba(27, 49, 93, 0.22)',
    borderRadius: 0,

    paddingLeft: '20px/1.3vw',
    paddingTop: '20px/1vw',

    fontFamily: spoqa,
    fontWeight_: `${500}/${300}`,
    fontSize: '12px/14px@15px',
    color: baseText,

    '::placeholder': {
      color: baseText,
      opacity: 0.5,
    },

    resize: 'none',
  },
  smallTextAreaBox: {
    width: '100%',
    height: '189px/8.7vw',

    marginBottom: '15px/1.04vw',
    position: 'relative',
  },
  smallSize: {
    height: '100%/8.7vw',
  },
  checkDiv: {
    display: 'flex',
    position: 'relative',
    width: '100%',

    marginBottom: '20px/1.56vw',
    cursor: 'pointer',
  },
  checkBox: {
    width: '26px/1.35vw',
    height: '26px/1.35vw',

    marginRight: '20px/.5vw',

    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

    border: 'solid 1px rgba(27, 49, 93, 0.22)',
  },
  tick: {
    width: '20px/1vw',
    height: '20px/1vw',
    fill: 'black',
  },
  requestButton: {
    width: '100%/21vw',
    height: '50px/3vw@60px',

    backgroundColor: buttonColor,

    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

    textAlign: 'center',
    fontSize: '13px/16px@15.5px',
    fontWeight: 500,

    cursor: 'pointer',
    outline: 'none',
    userSelect: 'none',

    marginBottom: '25vw/',
  },
  privacyPolicy: {
    borderBottom: 'solid 1px black',
    textDecoration: 'none',
    color: 'inherit',
  }
}, isMobile, isTablet);
