import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useRecoilValue, useSetRecoilState } from 'recoil';

import { IHistory, isPartnershipPage, kakaoPage } from '../HistoryHelper';
import { KAKAO_SERVICE, kakaoPhoneState, KakaoService } from '../../store/KakaoStore';
import { useInjection } from '../InversifyProvider';
import { SCRIPT_LOGGER, ScriptLogger } from '../../domain/ScriptLogger';
import { isLoadingState } from '../LoadingFrame';

export function KakaoCheck() {
  const history = useHistory<IHistory>();
  const phone = useRecoilValue(kakaoPhoneState);
  const setIsLoading = useSetRecoilState(isLoadingState);
  const kakaoService = useInjection<KakaoService>(KAKAO_SERVICE);
  const scriptLogger = useInjection<ScriptLogger>(SCRIPT_LOGGER);

  useEffect(() => {
    if (!isPartnershipPage(history)) {
      scriptLogger.sendPathApproachedLog('/kakao_check');
    }
  }, [scriptLogger, history])

  const sendRequest = async () => {
    setIsLoading(true);

    await kakaoService.sendRequest(phone);

    setIsLoading(false);

    scriptLogger.sendPathApproachedLog('/kakaoSuccess');
    scriptLogger.sendRequestOccuredLog();
    history.push(kakaoPage(2));
  };

  return (
    <>
      <h2 className='kakao phone checkcircle spoqa730 bottom-space12'>
        {phone}
      </h2>
      <div className='kakao comment spoqa740 bottom-space31'>
        휴대폰 번호가 맞으시면 아래의 확인 버튼을 눌러주세요
      </div>
      <button
        className='kakao button spoqa750 bottom-space15'
        type='button'
        onClick={sendRequest}
        id={'kakao_check_button'}
      >
        확인
      </button>
      <button
        className='kakao button spoqa750 active'
        type='button'
        onClick={() => history.goBack()}
        id={'modify_number'}
      >
        휴대폰 번호 재입력
      </button>
    </>
  )
}
