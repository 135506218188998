import Airtable from 'airtable';

import { ITaxReturn, ITaxReturnRequest, ITaxReturnResult, TaxReturnReopository } from '../domain/TaxReturnReopository';

const BASE_ID = 'appUf0TT1DvNs0mhx';
const TABLE_NAME = '신청';
const API_KEY = 'patc9ZiqoPk0ImxuG.322c5f745f033f484b94c00c6793230123367970e20e767b1a1dafb5f32e6ba5'

export class AirtableTaxReturnRepository implements TaxReturnReopository {
  private readonly base: any;
  private readonly table: any;

  constructor(
    // apiKey: string = '',
  ) {
    this.base = new Airtable({apiKey: API_KEY}).base(BASE_ID);
    this.table = this.base(TABLE_NAME);
  }

  async add(info: ITaxReturn): Promise<string> {
    if (this.table == null) {
      return '';
    }
    const body = {
      '① 성함': info.name,
      '② 연락처': info.phone,
      '③ 주민등록번호': info.ssn,
      SpreadSheetId: info.spreadSheetId,
    }
    console.log(body)
    const result = await this.table.create(body);
    return result.id;
  }

  async update(info: ITaxReturnResult): Promise<string> {
    const result = await this.table.update([{
      id: info.id,
      fields: {
        '진행상황': info.state,
      },
    }]);
    return result[0].id;
  }

  async findOneById(id: string): Promise<ITaxReturnRequest | undefined> {
    if (this.table === undefined) {
      return Promise.reject('airtable setting not exist');
    }

    const result = await this.table.find(id);

    if (result == null) {
      return undefined;
    }

    return this.mapFieldToRequest(result);
  }

  async mapFieldToRequest(entity: any): Promise<ITaxReturnRequest> {
    const res = entity._rawJson;
    return ({
      id: res.id,
      name: res.fields['① 성함'],
      phone: res.fields['② 연락처'],
      ssn: res.fields['③ 주민등록번호'],
    });
  }
}
