import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { homeInit } from './helper/Util';
import { IHistory } from './presentation/HistoryHelper';

export interface IHomeProps {
  request?: boolean,
  kakao?: boolean,
}

export const withHome = <P extends object>(Component: React.ComponentType<P>, sections: string[]) =>
  function WithHome({ request, kakao, ...props }: P & IHomeProps) {
    const history = useHistory<IHistory>();
    useEffect(() => homeInit({ request, kakao, history, sections }), [request, kakao, history]);
    return (<Component {...props as P} />);
  }


