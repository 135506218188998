import { CreateStyle } from '../shared/CreateStyle';

const RequestPopUpStyle = (isMobile: boolean, isTablet?: boolean) => CreateStyle({
  requestBox: {
    width: '100vw/56.93vw',
    height: '100vh/33.75vw',
    backgroundColor: 'white',
    display_: '/flex',

    position: 'relative',

    overflowY_: 'auto/',
  },
}, isMobile, isTablet);

export default RequestPopUpStyle;
