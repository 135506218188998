export class NumberPolicy {
  // 숫자 단위 ','를 넣어줌
  static addComma(number?: string | number): string {
    if (number == null) {
      return '';
    }
    if (typeof number === 'number') {
      number = number.toString();
    }
    const regexp = /\B(?=(\d{3})+(?!\d))/g;
    return number.toString().replace(regexp, ',');
  }

  static removeComma(number?: string): string {
    if (number == null) {
      return '';
    }

    return number.replace(/,/g,'');
  }

  static toNumber(value?: string): number {
    if (value == null) {
      return 0;
    }

    return Number(this.removeComma(value));
  }
}
