import uniqid from 'uniqid';
import { atomWithStorage, selectAtom } from 'jotai/utils';
import { atom } from 'jotai';

import { TypicalPhoneFormatPolicy } from '../domain/PhoneFormatPolicy';
import { IRequestForm } from './RequestStore';
import { ICustomRequest } from '../service/RequestService';
import { getMobileType } from '../helper/DeviceHelper';

export interface IRequestType {
  requestId: string;
  name: string;
  phone: string;
  question: string;
  isPrivacyPolicyChecked: boolean;
  bizType: string,
  // requestType: string;
  type: string;
}

export const requestAtom = atomWithStorage<IRequestType>('request-form', {
  requestId: uniqid(),
  name: '',
  phone: '',
  question: '',
  isPrivacyPolicyChecked: false,
  bizType: '',
  type: getMobileType(),
});

export const requestPartialAtom = atom<IRequestType, Partial<IRequestType>>(
  (get) => get(requestAtom),
  (get, set, newValue: Partial<IRequestForm>) => {
    const phoneFormatPolicy = new TypicalPhoneFormatPolicy();
    const defaultValue = get(requestAtom);
    if (newValue.phone != null) {
      newValue.phone = phoneFormatPolicy.changeFormat(newValue.phone);
    }
    set(requestAtom, { ...defaultValue, ...newValue });
  }
);

export const checkerAtom = selectAtom<IRequestType, RequestChecker>(requestAtom, request => new RequestChecker(request))

export const nameAtom = atom(
  (get) => get(requestAtom).name,
  (get, set, update: string) => {
    set(requestAtom, { ...get(requestAtom), name: update });
  }
);

export const resetAtom = atom(
  (get) => get(requestAtom).name,
  (get, set, update: undefined) => {
    set(requestAtom, {
      requestId: uniqid.time(),
      name: '',
      phone: '',
      question: '',
      isPrivacyPolicyChecked: false,
      bizType: '',
      type: getMobileType(),
    });
  }
);

export const phoneAtom = atom(
  (get) => get(requestAtom).phone,
  (get, set, update: string) => {
    const phoneFormatPolicy = new TypicalPhoneFormatPolicy();
    set(requestAtom, { ...get(requestAtom), phone: phoneFormatPolicy.changeFormat(update) });
  }
);

export const questionAtom = atom(
  (get) => get(requestAtom).question,
  (get, set, update: string) => {
    set(requestAtom, { ...get(requestAtom), question: update });
  }
);

export class RequestChecker {
  private readonly phoneFormatPolicy = new TypicalPhoneFormatPolicy();
  requestId?: string;
  public name = '';
  public phone = '';
  public question = '';
  public isPrivacyPolicyChecked = false;
  public bizType = '';
  public requestType = '';

  constructor(props: Partial<IRequestType>) {
    Object.assign(this, props);
  }

  get isNameError(): boolean {
    return this.name === '';
  }

  get isPhoneEmpty(): boolean {
    return this.phone === '';
  }

  get isPhoneError(): boolean {
    return !this.phoneFormatPolicy.isValid(this.phone);
  }

  get isQuestionError(): boolean {
    return this.question === '';
  }

  get isBizTypeError(): boolean {
    if (process.env.REACT_APP_BIZTYPE_ACTIVE === 'TRUE') {
      return this.bizType === '' ?? false;
    }
    return false;
  }

  get isValid(): boolean {
    return !this.isNameError && !this.isPhoneEmpty && !this.isPhoneError && !this.isQuestionError && !this.isBizTypeError;
  }

  toRequestForm(): ICustomRequest {
    return {
      requestId: this.requestId,
      phone: this.phone,
      name: this.name,
      bizType: this.bizType,
      question: this.question,
      isPrivacyPolicyChecked: this.isPrivacyPolicyChecked,
      type: getMobileType()
    };
  }
}
