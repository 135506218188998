export const NewsList = ({isMobile, isDesktop}: { isMobile: boolean, isTablet: boolean, isDesktop: boolean }) => ([
  {
    title: '법인설립지원센터, \n초기 스타트업 맞춤 무료 법인설립 서비스',
    content: '법인설립지원센터가 ‘무료 법인설립 서비스’와 ‘초기 사업자 맞춤 세무\n서비스’를 선보인다. 지난해 신규 법인 설립 수가 12만개를 넘은 반면, \n다수의 스타트업 창업자들이 법인 설립 시 복잡한 절차와 비용 부담 등 \n전문지식이 부족한 점을 해소시키기 위한 서비스이다. ‘무료 법인설…',
    linkUrl: 'https://www.wowtv.co.kr/NewsCenter/News/Read?articleId=A202112240086&t=NN',
  },
  {
    title: '법인설립지원센터, IT기술 접목 무료 법인설립',
    content: '법인설립지원센터는 IT 기술을 접목한 \'무료 법인설립\' 서비스를 통해\n 시간과 비용 모두 절감할 수 있다고 밝혔다. 법인설립지원센터의 ‘프리\n미엄 무료 법인설립’서비스는 세무기장 서비스를 이용하면, 법인 전문\n 변호사를 통해 간편하게 법인설립을 할 수 있다. 또한 IT 기술이 도입…',
    linkUrl: 'https://www.fnnews.com/news/202112290955152304',
    isLast: true,
  }
]);
