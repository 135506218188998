import { robotoRegular, spoqa } from '../../css/css-utils';
import { CreateStyle } from '../../presentation/shared/CreateStyle';

const NewsStyle = (isMobile: boolean, isTablet: boolean) => CreateStyle({
  background: {
    width: '100%',
    height: '434px/49.3vw@500px',

    backgroundColor: '#fafafa',

    padding: '6.7vw 4.2vw 0 4.2vw/6.25vw 0 0 10.76vw',
    position: 'relative',
  },
  pressFont: {
    fontFamily: robotoRegular,
    fontSize: '12px/.9vw',
    letterSpacing: '1.5px/1.73px',
    color: '#00abd4',

    marginBottom: '23px/',
  },
  newsTotalBox: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column',
    alignItems_: 'center/',
    height: '210px/',
  },
  newsBox: {
    width: '370px/32.3vw',
    height: '210px/20vw',

    borderBottom: 'solid 1px #c5ced0',

    display: 'flex',
    flexDirection: 'column',
    justifyContent_: '/center',

    cursor: 'pointer',
    ':nth-child(2)': {
      marginTop: '30px/',
      borderBottom: 'none',
    }
  },
  noBorder: {
    borderBottom: 'none',
  },
  titleFont: {
    fontFamily: spoqa,
    fontWeight_: '400/300',
    fontSize: '18px/1.46vw',
    lineHeight: '28px/2.15vw',
    color: '#002933',
    marginBottom: '6px/1vw',
    whiteSpace: 'pre',
  },
  contentFont: {
    fontFamily: spoqa,
    fontWeight: 300,
    fontSize: '13px/1.11vw',
    lineHeight: '22px/1.8vw',
    color: '#002933',
    whiteSpace: 'pre',

    marginBottom: '25px/1vw',
  },
  logoStyle: {
    width: '86px/7vw',
    height: '25px/2vw',

    marginBottom: '2vw',
  },
  imageStyle: {
    position_: '/absolute',
    right: 0,
    top: 150,

    width: '46.04vw',
    height: '32vw',
  },
}, isMobile, isTablet);
export default NewsStyle;
