import { spoqa } from '../../css/css-utils';

const PrivacyPolicyStyle = (isMobile: boolean) => ({
  wrapper: {
    padding: isMobile ? '65px 27px 36px' : '150px 0 60px',
    display: 'flex',
    justifyContent: 'center',
  },
  wrapperRocket: {
    width: '100%',
    paddingTop: isMobile ? '150px' : '196px',

    display: 'flex',
    flexDirection: 'column' as 'column',
    alignItems: 'center',

    fontFamily: spoqa,
    fontWeight: 600,
  },
  background: {
    width: isMobile ? undefined : '700px',
  },
  backgroundRocket: {
    width: isMobile ? undefined : '700px',
    marginBottom: isMobile ? '100px' : '200px',
    marginLeft: isMobile ? '27px' : undefined,
    marginRight: isMobile ? '27px' : undefined,
  },
  title: {
    fontSize: isMobile ? '25px' : '24px',
    lineHeight: 1.5,
    color: '#34373a',
    marginBottom: '60px',
  },
  titleRocket: {
    fontSize: isMobile ? '25px' : '30px',
    lineHeight: 1,
    color: '#34373a',
    marginBottom: isMobile ? '65px' : '93px',
  },
  subTitle: {
    lineHeight: 2,
  },
  text: {
    fontWeight: 300,
    fontSize: isMobile ? '13px' : '13px',
    lineHeight: 1.6,
    whiteSpace: 'pre-wrap' as 'pre-wrap',
    color: '#34373a',
  },
  textRocket: {
    fontSize: '13px',
    lineHeight: 1.77,
    color: '#34373a',

    wordBreak: 'keep-all' as 'keep-all',
  },
});

export default PrivacyPolicyStyle;
