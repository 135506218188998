import React from 'react';
import { css } from 'aphrodite';
import { useRecoilValue } from 'recoil';
import { RouteComponentProps, useHistory, withRouter } from 'react-router-dom';

import { MenuStyle } from './MenuStyle';
import { MenuItem, MenuList } from './MenuList';
import { BackgroundButton } from '../kakao-popup/BackgroundButton';
import { closedPage, IHistory, isShowMenu } from '../HistoryHelper';
import { totalScreenState } from '../MobileFrame';
import { CloseButton } from '../shared/CloseButton';

interface IMenuProps extends RouteComponentProps {
  menuList: MenuItem[],
  textColor?: string,
}

function MenuFrame({ menuList, textColor }: IMenuProps) {
  const { isMobile, isTablet, isDesktop } = useRecoilValue(totalScreenState);
  const history = useHistory<IHistory>();

  const styles = MenuStyle({
    isMobile,
    isTablet,
    color: textColor ?? 'black',
  });
  return (
    <>
      {isShowMenu(history) && !isDesktop &&
      <div className='background popup menu'>
        <div className={css(styles.wrapper)}>
          <CloseButton isAlwaysBlack onClickClose={() => history.push(closedPage)} />
          <div>
            <MenuList menuList={menuList} textColor={textColor}/>
          </div>
          <div>
            <p>
              고객센터 {process.env.REACT_APP_SERVICE_CENTER_NUMBER}
            </p>
            <p className={css(styles.time)}>
              오전 9시 - 오후 7시 (주말, 공휴일 제외)
            </p>
          </div>
        </div>
        <BackgroundButton/>
      </div>
      }
    </>
  );
}

export default withRouter(MenuFrame);
