import AxiosWrapper from '../AxiosWrapper';
import moment from 'moment';
import { plainToClass } from 'class-transformer';

import { atom, DefaultValue, selector, selectorFamily } from 'recoil';
import { CustomerDTO, CustomerRepository } from '../domain/CustomerRepository';
import { ICorporation } from '../../startbiz/pages/customer/customer-page/Customer';
import { CorpMember } from '../../startbiz/pages/customer/domain/CustomerConstant';
import { STARTBIZ_BASE_URL } from '../../mybuild/pages/customer/customer-page/Constants';

export const customersState = selectorFamily<CustomerDTO[], string>({
  key: 'customersState',
  get: param => (): Promise<CustomerDTO[]> => {
    const customerRepository = new CustomerRepository(param);
    return customerRepository.fetchCustomers();
  },
});

export const customersStateForMinute = selectorFamily<CustomerDTO[], string>({
  key: 'customersState',
  get: param => ({get}): Promise<CustomerDTO[]> => {
    // const minute = get(minuteState);
    // console.log(minute);
    const customerRepository = new CustomerRepository(param);
    return customerRepository.fetchCustomers();
  },
});

export const minuteState = atom<string>({
  key: 'minuteState',
  default: moment().format('YYYYMMDD-mm'),
});

export const refreshMinuteState = selector({
  key: 'refreshMinuteState',
  get: () => {
    const minutes = 60 * 1000;
    const curHour = Number(moment().format('HH'));
    if (curHour > 18 && curHour < 24) {
      return minutes * (Math.floor(Math.random() * 5) + 1);
    }
    if (curHour > 0 && curHour < 9) {
      return minutes * (Math.floor(Math.random() * 20) + 1);
    }
    return minutes;
  },
});


const customerInfoTrigger = atom({
  key: 'customerInfoTrigger',
  default: 0
});

// export const editedTimeState = atom<number>({
//   key: 'editedTimeState',
//   default: 0,
// })

let editedTime = 0;
const SECOND = 500;
export const setEditedTime = () => editedTime = new Date().getTime();
export const isAvailToGet = () => new Date().getTime() - editedTime > SECOND;

export const customerInfoState = selectorFamily<ICorporation, string>({
  key: 'customerInfoState',
  get: id => async ({get}): Promise<any> => {
    if (id === '') {
      throw new Error('No input');
    }
    get(customerInfoTrigger);
    const {data: info} = await AxiosWrapper.get(`/realtime-cs/startbiz/${id}/RAW2`);
    if (!isAvailToGet) {
      throw new Error('Now edited');
    }
    info.members = info.members?.map((v: Object) => plainToClass(CorpMember, v));
    return info;
  },
  set: () => ({set}, value) => {
    if (value instanceof DefaultValue) {
      set(customerInfoTrigger, v => v + 1);
      return;
    }
    editedTime = new Date().getTime();
    // set(editedTimeState, new Date().getTime());
    AxiosWrapper.post(`/realtime-cs/startbiz/RAW2`, value);
  }
});
