import React from 'react';
import { useHistory } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { History } from 'history';

import { IHistory } from './presentation/HistoryHelper';
import { isMobileState, isTabletState } from './presentation/MobileFrame';
import { isTopState } from './presentation/ScrollFrame';

export interface IResizeFrame {
  isMobile: boolean,
  isTablet: boolean,
  isDesktop: boolean,
  history: History<IHistory>,
}

export const withResizeFrame = <P extends object>(Component: React.ComponentType<P & IResizeFrame>) =>
  function WithResizeFrame({...props}: P) {
    const isMobile = useRecoilValue(isMobileState);
    const isTablet = useRecoilValue(isTabletState);
    const history = useHistory<IHistory>();
    return (<Component
      isMobile={isMobile}
      isTablet={isTablet}
      isDesktop={!isMobile && !isTablet}
      {...props}
      history={history}
    />);
  };

export interface IResizeWithDirection extends IResizeFrame {
  isTop: boolean;
}

export const withResizeFrameAndDirection = <P extends object>(Component: React.ComponentType<P & IResizeWithDirection>) =>
  function WithResizeFrameAndScrollDirection({...props}: P) {
    const {isTop} = useRecoilValue(isTopState);
    const isMobile = useRecoilValue(isMobileState);
    const isTablet = useRecoilValue(isTabletState);
    const history = useHistory<IHistory>();

    return (<Component
      isTop={isTop}
      isMobile={isMobile}
      isTablet={isTablet}
      isDesktop={!isMobile && !isTablet}
      {...props}
      history={history}
    />);
  };
