import React, { useEffect } from 'react';
import { css, StyleSheet } from 'aphrodite';
import { useHistory } from 'react-router-dom';
import { useRecoilState } from 'recoil';

import { IHistory, isPartnershipPage, kakaoPage } from '../HistoryHelper';
import { KAKAO_SERVICE, kakaoErrorState, kakaoPhoneState, KakaoService } from '../../store/KakaoStore';
import { useInjection } from '../InversifyProvider';
import { PHONE_FORMAT_POLICY, PhoneFormatPolicy } from '../../domain/PhoneFormatPolicy';
import { SCRIPT_LOGGER, ScriptLogger } from '../../domain/ScriptLogger';
import { spoqa } from '../../css/css-utils';

export function KakaoInput() {
  const history = useHistory<IHistory>();
  const phoneFormatPolicy = useInjection<PhoneFormatPolicy>(PHONE_FORMAT_POLICY);
  const [phone, setPhone] = useRecoilState(kakaoPhoneState);
  const [error, setError] = useRecoilState(kakaoErrorState);
  const kakaoService = useInjection<KakaoService>(KAKAO_SERVICE);
  const scriptLogger = useInjection<ScriptLogger>(SCRIPT_LOGGER);

  useEffect(() => {
    if (!isPartnershipPage(history)) {
      scriptLogger.sendPathApproachedLog('/kakao');
    }
  }, [scriptLogger, history]);

  const onClickNext = () => {
    const isPhoneError = kakaoService.checkPhone(phone);
    console.log(isPhoneError);
    if (isPhoneError) {
      setError(true);
      return;
    }
    history.push(kakaoPage(1));
  };

  const onChangePhoneNumber = (value: string) => {
    const phone = phoneFormatPolicy.changeFormat(value);
    setPhone(phone);
    setError(false);
  };

  return (
    <>
      <div className={css(styles.inputBox)}>
        <input
          className={css(styles.input)}
          type='text'
          placeholder="휴대폰 번호를 입력하여 주세요"
          onChange={e => onChangePhoneNumber(e.target.value)}
          value={phone}
          data-testid='phone'
          id={'number_input'}
        />
        {error && (
          <div className={css(styles.error)}>
            휴대폰번호를 확인해주세요
          </div>
        )}
      </div>
      <div className='kakao comment bottom-space25 spoqa740'>
        입력해주신 휴대폰 번호로 카카오톡 상담을 위한 알림톡 <br/>
        메세지가 발송될 예정입니다
      </div>
      <button
        className='kakao button spoqa750'
        type='button'
        onClick={onClickNext}
        id={'kakao_confirm_button'}
      >
        확인
      </button>
    </>
  );
}

const styles = StyleSheet.create({
  inputBox: {
    position: 'relative',
    width: '368px',
    height: '60px',
    fontSize: '16px',
    border: 'solid 1px #bcb6b2',
    marginBottom: '15px',
  },
  input: {
    fontFamily: spoqa,
    width: '100%',
    height: '100%',
    padding: '0 27px',
    '::placeholder': {
      color: '#3b1f0a',
    }
  },
  error: {
    position: 'absolute',
    top: '50%',
    left: 0,
    transform: 'translateY(-50%)',
    width: '100%',
    textAlign: 'right',
    padding: '0 27px',

    color: '#ff5c5c',
    fontSize: '13px'
  }
});
