import { atom, DefaultValue, selector, selectorFamily } from 'recoil';
import * as _ from 'lodash';
import axios from 'axios';

import { ICorporation } from '../customer-page/Customer';
import { registrationToString, stringToRegistration } from '../util/utils';
import { NumberPolicy } from '../../../../shared/domain/NumberPolicy';
import { customerInfoState } from '../../../../shared/store/CustomerStore';
import { plainToClass } from 'class-transformer';
import { STARTBIZ_BASE_URL } from '../../../../mybuild/pages/customer/customer-page/Constants';

export const positionOptions = ['대표이사', '이사', '감사'];
export const stockOptions = ['주식 보유', '주식 미보유'];

interface IBizName {
  name: string;
  englishName: string;
}

export class CorpMember {
  position?: string;
  name = '';
  registrationNumber = '';
  address = {
    main: '',
    detail01: '',
    detail02: '',
  };
  isStockHolder?: boolean;
  isExecutive = false;
  stock?: number;
  capital?: number;
  percent?: number;

  setPosition(value: string): void {
    this.position = value;
  }

  setStock(input: { stockNumber?: number, capital?: number, percent?: number }) {
    this.stock = input.stockNumber;
    this.capital = input.capital;
    this.percent = input.percent;
  }

  setIsStockHolder(value: string): void {
    this.isStockHolder = (stockOptions.indexOf(value) === 0)
  }

  setToStockHolder(): void {
    this.isStockHolder = true;
  }

  setNoStock(): void {
    this.isStockHolder = false;
  }
}

export const fetchCorporationState = selectorFamily<ICorporation, string>({
  key: 'fetchCorporationState',
  get: recordId => async () => {
    const {data} = await axios.get(`${STARTBIZ_BASE_URL}/${recordId}/RAW2`);
    return data;
  }
})

export const corporationState = selector<ICorporation>({
  key: 'corporationState',
  get: ({ get }) => {
    const id = get(corporationIdState);
    const { name, englishName } = get(corporationNamesState);
    const address = get(corporationAddressState);
    const stockInfo = get(corporationStockState);
    const members = get(corporationMemberState);
    const bizTypes = get(corporationBizTypesState);
    const saveStates = get(corporationSaveState);
    const { leader, manager } = get(corporationCommunicateState);
    const isComplete = get(corporationIsCompleteState);

    return {
      id,
      name, englishName,
      address,
      stockInfo,
      members,
      bizTypes,
      saveStates,
      leader, manager,
      isComplete,
    }
  },
  set: ({ set, reset }, value) => {
    if (value instanceof DefaultValue) {
      reset(corporationIdState);
      reset(corporationNamesState);
      reset(corporationAddressState);
      reset(corporationStockState);
      reset(corporationMemberState);
      reset(corporationBizTypesState);
      reset(corporationSaveState);
      reset(corporationIsCompleteState);
      return;
    }
    set(corporationIdState, value.id ?? '');
    set(corporationNamesState, {
      name: value.name,
      englishName: value.englishName,
    })
    set(corporationAddressState, value.address);
    set(corporationStockState, value.stockInfo);
    set(corporationMemberState, plainToClass(CorpMember, value.members));
    set(corporationBizTypesState, value.bizTypes);
    set(corporationSaveState, value.saveStates);
    set(corporationCommunicateState, {
      leader: value.leader,
      manager: value.manager,
    })
    set(corporationIsCompleteState, value.isComplete);
  }
})

export const corporationCommunicateState = atom<{ leader?: string, manager?: string[] }>({
  key: 'corporationCommunicateState',
  default: {
    leader: '',
    manager: [],
  }
})

export const corporationIdState = atom<string>({
  key: 'corporationIdState',
  default: ''
})

export interface IInput {
  isGet?: boolean,
}

export const corporationNamesState = atom<{ name: string, englishName: string } & IInput>({
  key: 'corporationNamesState',
  default: {
    name: '',
    englishName: '',
  }
})

export const corporationAddressState = atom<IAddress & IInput>({
  key: 'corporationAddressState',
  default: {
    main: '',
    detail01: '',
    detail02: '',
  }
})

export const corporationStockState = atom<Partial<ITotalStock> & IInput>({
  key: 'corporationStockState',
  default: {
    capital: undefined,
    price: undefined,
    total: undefined,
  }
})

export const corporationMemberState = atom<CorpMember[]>({
  key: 'corporationMemberState',
  default: [],
})

export const corporationBizTypesState = atom<string[]>({
  key: 'corporationBizTypesState',
  default: [],
})

export const corporationSaveState = atom<string[]>({
  key: 'corporationSaveState',
  default: ['작성중', '작성중', '작성중', '작성중', '작성중', '작성중', '작성중'],
})

export const corporationIsCompleteState = atom<boolean>({
  key: 'corporationIsCompleteState',
  default: false,
})

export const focusedSectionState = atom<string>({
  key: 'focusedSectionState',
  default: '',
})

export const bizNameState = selector<IBizName>({
  key: 'bizNameState',
  get: ({get}) => {
    const { name, englishName } = get(corporationNamesState);
    return { name: name ?? '', englishName: englishName ?? '' };
  },
  set: ({get, set}, value) => {
    if (value instanceof DefaultValue) {
      return;
    }
    const corp = get(corporationState);
    const data = {
      ...corp,
      name: value.name,
      englishName: value.englishName,
    };
    set(corporationNamesState, data);
  }
})

export interface IAddress {
  main: string;
  detail01: string;
  detail02: string;
}

export const addressState = selector<IAddress>({
  key: 'addressState',
  get: ({get}) => {
    return  get(corporationState).address ?? {
      main: '', detail01: '', detail02: ''
    };
  },
  set: ({set}, value) => {
    if (value instanceof DefaultValue) {
      return;
    }
    set(corporationState, cur => {
      const data = {...cur, address: value };
      set(customerInfoState(''), data);
      return data;
    })
  }
})

interface IStock {
  capital: string;
  price: string;
  isGet?: boolean;
}

export const stockState = selector<IStock>({
  key: 'stockState',
  get: ({get}) => {
    const { isGet, capital = '', price = '' } = get(corporationStockState);
    return {
      capital: NumberPolicy.addComma(capital),
      price: NumberPolicy.addComma(price),
      isGet: isGet,
    }
  },
  set: ({set}, value) => {
    if (value instanceof DefaultValue) {
      return;
    }

    const capital = value.capital ? NumberPolicy.toNumber(value.capital) : undefined;
    const price = value.price ? NumberPolicy.toNumber(value.price) : undefined;
    const total = capital && price ? Math.floor(capital / price) : undefined;
    const stockInfo = {
      capital,
      price,
      total,
    }
    set(corporationStockState, stockInfo)
  }
})

export interface ITotalStock {
  capital: number;
  price: number;
  total: number;
}

export const totalStockState = selector<ITotalStock>({
  key: 'totalStockState',
  get: ({ get }) => {
    const { stockInfo } = get(corporationState);
    return { capital: stockInfo?.capital ?? 0, price: stockInfo?.price ?? 0, total: stockInfo?.total ?? 0 };
  },
})

export interface IRegistrationNumber {
  front: string;
  back: string;
}

export const inputMemberRegistrationNumberState = atom<IRegistrationNumber>({
  key: 'registrationNumberState',
  default: {
    front: '',
    back: '',
  }
})

export const inputMemberNameState = atom<string>({
  key: 'executiveNameState',
  default: '',
})

export const inputMemberPositionState = atom<string>({
  key: 'executivePositionState',
  default: '',
})

export const inputMemberIsStockState = atom<string>({
  key: 'executiveIsStockState',
  default: '',
})

export const inputMemberAddressState = atom<IAddress>({
  key: 'executiveAddressState',
  default: {
    main: '',
    detail01: '',
    detail02: '',
  },
})

export const inputMemberState = selectorFamily<CorpMember, boolean>({
  key: 'executiveState',
  get: isExecutive => ({ get }) => {
    const name = get(inputMemberNameState);
    const position = get(inputMemberPositionState);
    const isStock = get(inputMemberIsStockState);
    const registrationNum = get(inputMemberRegistrationNumberState);
    const address = get(inputMemberAddressState);
    return plainToClass(CorpMember, {
      name,
      position,
      address,
      isStockHolder: !isExecutive ? true : isStock === '' ? undefined : isStock === stockOptions[0],
      registrationNumber: registrationToString(registrationNum),
      isExecutive: isExecutive,
    });
  },
  set: () => ({ get, set, reset }, newValue) => {
    if (newValue instanceof DefaultValue) {
      reset(inputMemberNameState);
      reset(inputMemberIsStockState);
      reset(inputMemberPositionState);
      reset(inputMemberRegistrationNumberState);
      reset(inputMemberAddressState);
      return;
    }
    if (newValue.name != null) set(inputMemberNameState, newValue.name);
    if (newValue.position != null) set(inputMemberPositionState, newValue.position);
    if (newValue.isStockHolder != null) {
      const input = newValue.isStockHolder ? stockOptions[0] : stockOptions[1];
      set(inputMemberIsStockState, input);
    }
    if (newValue.registrationNumber != null) set(inputMemberRegistrationNumberState, stringToRegistration(newValue.registrationNumber));
    if (newValue.address != null) set(inputMemberAddressState, newValue.address);
  },
})

// export const bizTypesState = selector<string[]>({
//   key: 'bizTypesState',
//   get: ({get}) => {
//     return get(corporationBizTypesState);
//   },
//   set: ({set, reset}, value) => {
//     if (value instanceof DefaultValue) {
//       return;
//     }
//
//     set(corporationState, cur => {
//       const data = {...cur, bizTypes: value};
//       set(customerInfoState(''), data);
//       return data;
//     });
//   }
// })

// export const memberListState = selector<CorpMember[]>({
//   key: 'executiveListState',
//   get: ({get}) => {
//     const { members } = get(corporationState);
//     return members;
//   },
//   set: ({set}, value) => {
//     if (value instanceof DefaultValue) {
//       return;
//     }
//     set(corporationState, cur => {
//       const data = {...cur, members: value };
//       set(customerInfoState(''), data);
//       return data;
//     })
//   }
// });

export const sectionSaveState = selectorFamily<string, number>({
  key: 'sectionSaveState',
  get: index => ({get}) => {
    const saveStates = get(corporationSaveState);
    return saveStates[index];
  },
  set: index => ({set}, value) => {
    if (value instanceof DefaultValue) {
      return;
    }

    set(corporationSaveState, cur => {
      const clone = _.clone(cur);
      clone[index] = value;
      return clone;
    })
  },
})

export const BIZTYPE_DUMMIES = [
  '(업종명) 프랜차이즈업 or (업종명)프랜차이즈 운영 및 모집업',
  '위 각 호에 관련된 통신판매업 및 전자상거래업',
  '소프트웨어 개발, 제조, 공급업',
  '소프트웨어 판매, 유통, 임대업',
  '소프트웨어 유지, 보수업',
  '소프트웨어 관련 컨설팅업',
  '컴퓨터 프로그래밍 시스템 개발, 관리, 유지, 보수업',
  '컴퓨터 프로그래밍 시스템 자문업',
  '컴퓨터 및 주변기기 도소매업 및 판매업',
  '컴퓨터 및 주변기기 제조업',
  '보안 시스템 개발, 제조, 공급업',
  '시스템 통합사업',
  '데이터 베이스 개발, 구축, 판매업',
  '데이터 베이스 검색서비스업',
  '인터넷 서비스 및 정보서비스업',
  '온라인 정보 제공업',
  '자료처리 호스팅 포털 및 기타 인터넷 정보 매개 서비스업',
  '모바일 어플리케이션 및 응용 소프트웨어 개발업',
  '[세부분야] + 모바일 서비스업',
  '모바일 방송업',
  '모바일 콘텐츠 개발 및 판매업',
  '홈페이지 개발, 제작, 공급업',
  '홈페이지 구축 및 웹호스팅업',
  '온라인 게임 소프트웨어 개발 및 공급업',
  '모바일 게임 소프트웨어 개발 및 공급업',
  '인터넷 쇼핑몰 운영, 개발, 제작업',
  '통신판매 중개업',
  '전자상거래 소매업',
  '부가통신사업',
  '전자상거래 소매 중개업',
  '주거용, 비주거용 부동산 임대업 및 전대업',
  '부동산 매매업',
  '부동산 경매 및 공매 입찰업',
  '부동산 경매 및 공매 컨설팅업',
  '부동산 컨설팅업',
  '부동산 개발 및 시행업',
  '부동산 개발 및 시행업',
  '부동산 투자자문업',
  '주거용, 비주거용 부동산 관리업',
  '주택건설업',
  '임대주택건설업',
  '주택신축판매업',
  '부동산 투자업',
  '부동산 감정평가업',
  '부동산 중개업',
  '토목건축 공사업',
  '산업환경설비공사업',
  '조경공사업',
  '토목공사업',
  '건축공사업',
  '철근 콘크리트 공사업',
  '금속 구조물 창호 공사업',
  '상하수도 설비공사업',
  '비계 구조물 해체 공사업',
  '전기공사업',
  '미장방수 조적 공사업 (습식 방수공사업)',
  '실내 건축공사업',
  '조경식재 공사업',
  '기계설비 공사업',
  '조경시설물 설치 공사업',
  '석공사업',
  '보링 그라우팅 공사업',
  '강구조물 공사업',
  '철물 공사업',
  '전기통신 공사업',
  '정보통신 공사업',
  '상하수도 공사업',
  '도장 공사업',
  '지붕판금 건축물 조립 공사업',
  '통신공사업',
  '소방시설공사업',
  '포장공사업',
  '산업환경 설비공사업',
  '수중공사업',
  '철도·궤도공사업',
  '토공사업',
  '철강재 설치공사업',
  '삭도설치공사업',
  '시설물유지관리업',
  '준설공사업',
  '승강기설치공사업',
  '광고업 및 광고대행업',
  '온라인 광고업 및 온라인 광고대행업',
  '광고 기획, 디자인, 제작, 판매업',
  '광고 매체 판매업',
  '홍보대행업',
  '시각 디자인업, 그래픽 디자인업',
  '제품 디자인업, 의류 디자인업',
  '광고 디자인업',
  '캐릭터 디자인업',
  '디자인 컨설팅업',
  '영상 디자인업',
  '인테리어 공사업(실내건축공사업)',
  '인테리어 디자인업',
  '[세부분야] + 유통업',
  '[세부분야] + 유통전문판매업',
  '종합물류유통업',
  '일반 창고업',
  '물류창고업, 운송창고업',
  '냉장창고업 및 냉동창고업',
  '[세부분야] + 무역업',
  '[세부분야] + 무역중개업',
  '일반여행업',
  '국내여행업',
  '국외여행업',
  '일반 여행알선업',
  '국내 여행알선업',
  '국외 여행알선업',
  '여행보조 및 예약서비스업',
  '여행정보제공업',
  '관광호텔업',
  '휴양콘도미니엄업',
  '관광객이용시설업',
  '국제회의시설업',
  '국제회의기획업',
  '관광펜션업',
  '관광편의시설업',
  '외국인환자 유치업',
  '일반 숙박업',
  '생활 숙박업',
  '목욕장업',
  '이용업, 이용업 관련 서비스업, 프랜차이즈업',
  '종합 미용업',
  '세탁업 및 세탁소 운영업',
  '건물위생관리업',
  '건물청소대행업',
  '농어촌 민박사업',
  '청소년 수련시설업',
  '자동차 용품 도소매업',
  '자동차 부품 제조 및 판매업',
  '자동차 임대업',
  '중고 자동차 판매업',
  '자동차종합정비업',
  '소형자동차정비업',
  '자동차부분정비업',
  '원동기전문정비업',
  '자동차해체재활용업',
  '자동차 세차업',
  '주유소 운영업',
  '세차용품 도소매업',
  '개별화물자동차운송업',
  '일반화물자동차운송업',
  '이사화물운송주선업',
  '보세 운송업',
  '화물자동차운송주선업',
  '국제물류주선업',
  '물류유통업',
  '물류창고업',
  '종합물류대행업',
  '물류관리업',
  '물류운송업',
  '[세부분야] + 교육서비스업',
  '온라인 교육업',
  '온라인 교육 및 관련 학원운영업',
  '교육 관련 자문 및 평가업',
  '교육 지원 서비스업',
  '교육 컨설팅업',
  '평생교육사업',
  '학원운영업',
  '온라인 교육학원업',
  '기타 기술 및 직업훈련 학원',
  '학원 운영 컨설팅업',
  '일반 교습 학원업',
  '외국어 학원업',
  '학교교과 교습학원업',
  '평생직업 교육학원업',
  '학술연구 용역업',
  '[세부분야] + 연구개발업',
  '전기전자 공학 연구 개발업',
  '자연과학 및 공학연구 개발업',
  '연구 개발 컨설팅 전문업',
  '인문 및 사회과학 연구개발업',
  '태양광 발전 사업',
  '태양광 발전 전기생산 및 판매업',
  '태양광 발전 장치 제조, 설치, 판매업',
  '영화 제작 및 배급업',
  '영화, 비디오물, 방송 프로그램 제작 관련 서비스업',
  '영상.오디오 기록물 제작 및 배급업',
  '인물 사진 및 행사용 영상 촬영업',
  '방송 프로그램 제작 및 수출입업',
  '비디오물제작업 및 비디오물배급업',
  '비디오물시청제공업',
  '영화상영관 운영업',
  '인터넷 방송사업',
  '유선, 위성 및 기타 방송업',
  '인터넷 멀티미디어 방송 제공사업',
  '인터넷 멀티미디어 방송 콘텐츠사업',
  '영화, 방송 기타 공연 관련 사업',
  '공연 기획 및 연출, 제작업',
  '창작, 예술 공연기획 및 행사대행업',
  '공연시설, 공연장 운영업',
  '연예 활동단체 및 기타 공연단체 운영업',
  '공연기획 컨설팅업',
  '창작 및 예술관련 서비스업',
  '엔터테인먼트 사업',
  '연예인 매니지먼트업',
  '연예인 및 기타 공인 매니저업',
  '캐스팅 디렉터, 연예기획사, 공연알선업,',
  '모델 에이전시, 연기학원 운영업, 음반 프로듀싱업 등',
  '음반음악영상물제작업',
  '음반음악영상물배급업',
  '온라인음악서비스제공업',
  '노래연습장업',
  '연예인 굿즈 제작 및 판매업',
  '교과서 및 학습 서적 출판업',
  '만화 출판업',
  '인쇄업',
  '신문 및 정기간행물 출판업',
  '서적, 잡지 및 기타 인쇄물 출판업',
  '전자 출판업',
  '대부업',
  '대부중개업',
  '온라인 대출정보 연계 대부업',
  'P2P연계대부업',
  '온라인 대출정보 중개업',
  '온라인 대출정보 연계사업',
  '대부업 및 대부채권매입추심업',
  '경영 컨설팅업',
  '경영 컨설팅 및 공공관계 서비스업',
  '기업 경영 컨설팅업',
  '창업 및 벤처창업 컨설팅업',
  '중소기업창업투자사업',
  '액셀러레이터업',
  '중소기업상담 및 자문',
  '사업성 평가 사업',
  '영농에 필요한 자재의 생산, 공급업',
  '영농에 필요한 종묘 생산 및 종균 배양사업',
  '농업기계 기타 장비의 임대, 수리, 보관업',
  '농업 컨설팅업',
  '농식품 제조 및 가공',
  '농식품 유통 및 판매',
  '농식품 통신판매',
  '농식품 연구개발',
  '농식품 레스토랑 프랜차이즈사업',
  '농식품 수출입업 및 관련 컨설팅',
  '농업경영업',
  '농산물의 유통, 가공, 판매업',
  '농작업 대행업',
  '농어촌관광휴양사업',
  '영농에 필요한 자재의 생산, 공급업',
  '영농에 필요한 종자생산 및 종균배양사업',
  '농산물의 구매, 비축사업',
  '농업기계 기타 장비의 임대, 수리, 보관업',
  '소규모 관개시설의 수탁, 관리사업',
  '농업회사법인은 위 사항 외의 다른 사업목적을 넣을 수 없습니다.',
  '근로자(인력) 파견업',
  '교육위탁 및 파견 등의 교육사업',
  '무료직업소개사업',
  '유료직업소개사업',
  '인력알선업',
  '고급인력알선업',
  '직업정보제공사업',
  '근로자(인력)공급사업',
  '일반경비업',
  '특수경비업',
  '의류 도소매업',
  '의류 제조업',
  '의류잡화 도소매업',
  '의류잡화 제조업',
  '의류잡화 수출입업',
  '화장품 도소매업',
  '화장품 제조업',
  '화장품 책임판매업',
  '맞춤형 화장품 판매업',
  '화장품 수출입업',
  '화장품 방문 판매업',
  '의료기기 수입업',
  '의료기기 판매업',
  '의료기기 제조업',
  '의료기기 임대업',
  '의약품 도매상',
  '의약품 제조업',
  '의약외품 제조업',
  '안전상비의약품도매상',
  '원료의약품도매상',
  '수입의약품도매상',
  '시약도매상',
  '한약도매상',
  '건강기능식품 제조업',
  '건강기능식품 수출입업',
  '건강기능식품 (일반)판매업',
  '건강기능식품 (유통전문)판매업',
  '[세부분야] + 음식점업',
  '일반음식점업',
  '음식료품 및 담배소매업',
  '휴게음식점업',
  '서양식 음식점업',
  '기타 음식점업',
  '한식 음식점업',
  '일반음식점 및 기타서비스업',
  '종합주류도매업',
  '특정주류도매업',
  '주류제조업',
  '주류판매업',
  '주정소매업',
  '주정도매업',
  '주류수출입업',
  '주류중개업',
  '주류소매업',
  '의제주류판매업',
  '비알콜 음료점업',
  '식음료 도소매업',
  '식음료 판매업',
  '식음료 제조업',
  '각종 식음료 제조 가공 및 판매업',
  '주방용품 도소매업',
  '주방기기 도소매업',
  '주방용품 제조업',
  '주방기기 제조업',
  '전기전자 제품 도소매업',
  '전기전자 제품 제조 및 판매업',
  '전기전자 제품 수출입업',
  '전자자금이체업',
  '직불전자지급수단 발행 및 관리업',
  '선불전자지급수단 발행 및 관리업',
  '전자지급결제대행업(PG)',
  '결제대금예치업(ESCROW)',
  '전자고지결제업(EBPP)',
  '블록체인 기반 유선 온라인 게임 소프트웨어 개발 및 공급업',
  '블록체인 기반 모바일 게임 소프트웨어 개발 및 공급업',
  '블록체인 기반 시스템 소프트웨어 개발 및 공급업',
  '블록체인 기반 응용 소프트웨어 개발 및 공급업',
  '블록체인 기반 컴퓨터 프로그래밍 서비스업',
  '블록체인 기반 컴퓨터 시스템 통합 자문 및 구축 서비스업',
  '블록체인 기술 관련 기타 정보기술 및 컴퓨터 운영 서비스업',
  '블록체인 기술 관련 호스팅 서비스업',
  '블록체인 기반 암호화 자산 매매 및 중개업',
  '블록체인 기술 관련 기타 정보서비스업',
  '증권정보 제공업',
  '온라인정보 제공업',
  '전자화폐 환전 및 중개업',
  '환전업',
  '외국환중개업',
  '환급창구 운영사업',
  '투자매매업',
  '투자중개업',
  '집합투자업',
  '신탁업',
  '투자자문업',
  '투자일임업',
  '유사투자자문업',
  '보험대리점업',
  '보험중개업',
  '전문 소방시설 공사업',
  '일반 소방시설 공사업',
  '섬유류 방염업',
  '합성수지류 방염업',
  '합판.목재류 방염업',
  '전문 소방시설 설계업',
  '일반 소방시설 설계업',
  '전문 소방공사 감리업',
  '일반 소방공사 감리업',
  '동물 생산업',
  '동물 판매업, 수입업, 운송업',
  '동물 전시업, 위탁관리업, 미용업',
  '반려동물 관련 서비스업(훈련, 카페)',
  '반려동물 관련 컨설팅업',
  '반려동물 용품 제조, 유통 및 도소매업',
  '반려동물 관련 서비스 프랜차이즈업',
  '사료 제작 및 공급, 유통, 판매업',
  '동물진료업',
  '동물장묘업',
  '항공기대여업',
  '초경량비행장치사용사업',
  '드론 수리업',
  '드론 유통 및 판매업',
  '드론 제조업',
  '항공레저스포츠사업',
  '수상 레저 사업',
  '수상 레저 교육사업',
  '수중레저기구 및 수중레저장비 임대업',
  '수중레저활동자 운송업',
  '수중레저활동 교육업',
  '위생용품 수입업',
  '위생용품 도소매업',
  '위생용품 유통업',
  '위생용품 제조업',
  '상조서비스업',
  '장례 지도사 서비스업',
  '장례식 대행 및 장의사업',
  '장례 서비스 및 장례 용역 대행업',
  '결혼 준비, 기획 및 대행업',
  '국내 결혼 중개업',
  '국제 결혼 중개업',
  '항만용역업',
  '항만하역업 (3급지 : 1급지와 2급지를 제외한 항)',
  '항만하역업 (2급지 : 여수항, 마산항, 동해/묵호항, 군산항, 평택/당진항)',
  '항만하역업 (1급지 : 부산항, 인천항, 울산항, 포항항, 광양항)',
  '외항 정기(부정기) 화물운송사업 - 수산물운송',
  '외항 정기(부정기) 화물운송사업 - 일반화물운송',
  '내항화물운송사업',
  '해운중개업',
  '해운대리점업',
  '선박대여업',
  '선박연료공급업',
  '선박수리업 및 컨테이너 수리업',
  '선용품 공급업',
  '선박관리업',
  '응급환자이송업',
  '도축업',
  '집유업',
  '축산물가공업',
  '식용란선별포장업',
  '식육포장처리업',
  '축산물보관업',
  '축산물운반업',
  '축산물판매업',
  '식육즉석판매가공업',
  '가축인공수정업',
  '정액 등 처리업',
  '해외이주알선업(해외이주신고 대행, 해외이주 입국사증 발급신청의 대행, 해외이주와 관련된 상담 및 안내, 해외이주자의 이주정착 지원)',
  '종합설계업',
  '전문설계업(1종)',
  '전문설계업(2종)',
  '종합감리업',
  '전문감리업',
];
