import React from 'react';

import Main from './1-main/Main';
import Header from '../shared/Header';
import FreeProgress from './2-free-progress/FreeProgress';
import Realtime from './3-realtime/Realtime';
import Target from './4-target/Target';
import Method from './6-method/Method';
import Complicate from './7-complicate/Complicate';
import Process from './8-process/Process';
import Benefit from './9-benefit/Benefit';
import Support from './10-support/Support';
import SimpleRequest from './12-simple-request/SimpleRequest';
import Request from './16-request/Request';
import Example from './13-example/Example';
import Review from './14-review/Review';
import Advantage from './5-advantage/Advantage';
import { LinkItem } from '../../../shared/presentation/shared/LinkItem';
import { scrollWindow } from '../../../shared/helper/Util';
import { Assets } from '../shared/Assets';
import { requestPage, useMyHistory } from '../../../shared/presentation/HistoryHelper';
import { withHome } from '../../../shared/WithHome';
import FloatingKakaoButton from '../shared/FloatingKakaoButton';
import Footer from '../shared/footer/Footer';
import NewsSection from '../../../shared/component/news/NewsSection';

const SECTION_LIST = ['main', 'free', 'advantage', 'process', 'support', 'review'];

const WARNING = '세이브택스 법인전환지원센터의 모든 법인설립 업무는 제휴 변호사 측에서 진행되며,\n' +
  '법인설립에 소요되는 모든 등기비용도 제휴 변호사측으로 지불되고 있음을 말씀 드립니다.';

const PLACE = '서울특별시 강남구 테헤란로 70길 12, 2층';

function Home() {
  const history = useMyHistory();
  const LINK_LIST: LinkItem[] = [
    {
      title: '무료법인전환',
      callback: () => scrollWindow('free'),
    },
    {
      title: '100만원혜택',
      callback: () => scrollWindow('support'),
    },
    {
      title: '고객리뷰',
      callback: () => scrollWindow('review'),
    },
    {
      title: '무료상담신청',
      callback: () => history.push(requestPage()),
      isLast: true,
    },
  ];

  return (
    <>
      <FloatingKakaoButton/>
      <Header/>
      <div id="main">
        <Main/>
      </div>
      <div id="free">
        <FreeProgress/>
      </div>
      <Realtime/>
      <Target/>
      <div id="advantage">
        <Advantage/>
      </div>
      <Method/>
      <Complicate/>
      <div id="process">
        <Process/>
      </div>
      <Benefit/>
      <div id="support">
        <Support/>
      </div>
      {/*<Blog/>*/}
      {/*<Service/>*/}
      <SimpleRequest/>
      <Example/>
      <div id="review">
        <Review/>
      </div>
      <NewsSection/>
      <Request/>
      <Footer
        LogoImage={Assets.LogoIcon}
        baseColor={'white'}
        textColor={'#051a43'}
        linkList={LINK_LIST}
        company={'Savetax'}
        warning={WARNING}
        place={PLACE}
      />
    </>
  );
}

export default withHome(Home, SECTION_LIST);
