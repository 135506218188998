import React, { useState } from 'react';
import { css } from 'aphrodite';
import Slider from 'react-slick';
import { useRecoilValue } from 'recoil';

import { robotoRegular, spoqa } from '../../../../shared/css/css-utils';
import { Image } from '../../../../shared/css/Image';
import { totalScreenState } from '../../../../shared/presentation/MobileFrame';
import { NotDesktopBr } from '../../../../shared/presentation/NotDesktopBr';
import Review1 from '../../../assets/pngs/review-logo1.png';
import Review2 from '../../../assets/pngs/review-logo2.png';
import Review3 from '../../../assets/pngs/review-logo3.png';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { CreateStyle } from '../../../../shared/presentation/shared/CreateStyle';

const REVIEW_LIST = [
  {
    logo: Review1,
    reviewContents: '“처음이라 너무 막막했는데 일사천리로 진행해 주셔서 매우 만족스럽습니다. 접수부터 완료까지 친절하게 응대해 주셔서 기분 좋았어요. 직원분의 추천으로 업종 전문 세무사를 연결 받았는데 매우 만족합니다..”',
    writer: '(주)한국바이에른식육학교  |  유병관 대표님',
  },
  {
    logo: Review2,
    reviewContents: '“플랫폼으로써 끝나는 것이 아닌, 지속적인 관리와 관심에 신뢰와 감사함을 느낍니다. 막연하게 생각했던 법인 설립 과정을 한눈에 알 수 있도록 자료로 보내주신 게 많은 도움이 되었습니다.”',
    writer: '(주)조우아건축사사무소  |  김원준 대표님'
  },
  {
    logo: Review3,
    reviewContents: '“지인 추천으로 알게 되었는데 복잡한 케이스였음에도 체계적인 서비스로 빠르게 법인설립되었습니다. 세무사를 따로 알아봐야 하는 번거로움 없이 전문성, 친절함까지 갖춘 분을 만나 만족하고 개인 사업자도 같이 맡기고 있습니다.”',
    writer: '(주)다이나미스  |  류원철 대표님'
  },
];

function Review() {
  const {isMobile, isTablet, isDesktop} = useRecoilValue(totalScreenState);
  const [selectedIndex, setSelectedIndex] = useState(0);
  let slider: any;
  const styles = ReviewStyle(isMobile, isTablet);

  const sliderSettingsMobile = {
    autoplay: true,
    dots: true,
    infinite: true,
    arrows: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    speed: 200,
    autoplaySpeed: 5000,
    pauseOnHover: true,
    dotsClass: 'customdot-css',
    centerMode: true,
    beforeChange: (oldIndex: number, newIndex: number) => setSelectedIndex(newIndex),
  };

  return (
    <div
      className={css(styles.background)}
      id={'section_11'}
    >
      <div className={css(styles.title)}>
        최적의 파트너를 만난<NotDesktopBr/> 고객의 경험담을 들어보세요
      </div>
      {
        isDesktop
          ?
          <div className={css(styles.reviewContainer)}>
            {REVIEW_LIST.map((item, index) =>
              <div
                key={'review_' + index}
                className={css(styles.reviewBox)}
              >
                <div className={css(styles.logoBox)}>
                  <Image src={item.logo} alt={'logo'} className={css(styles.logoImg)}/>
                </div>
                <div className={css(styles.contentsBox)}>
                  {item.reviewContents}
                </div>
                <div className={css(styles.authorBox)}>
                  {item.writer}
                </div>
              </div>
            )}
          </div>
          :
          <>
            <Slider
              ref={c => slider = c}
              {...sliderSettingsMobile}
              className={css(styles.sliderContainer)}
            >
              {
                REVIEW_LIST.map((item, index) =>
                  <div
                    key={'review_' + index}
                    className={css(styles.reviewBox)}
                  >
                    <div className={css(styles.logoBox)}>
                      <Image src={item.logo} alt={'logo'} className={css(styles.logoImg)}/>
                    </div>
                    <div className={css(styles.contentsBox)}>
                      {item.reviewContents}
                    </div>
                    <div className={css(styles.authorBox)}>
                      {item.writer}
                    </div>
                  </div>
                )
              }
            </Slider>
          </>
      }
    </div>
  );
}

export default Review;

const ReviewStyle = (isMobile: boolean, isTablet: boolean) => CreateStyle({
  background: {
    width: '100%',
    height: '558px/510px',

    overflow: 'hidden',
    position: 'relative',

    paddingTop: '41px/88px',
    backgroundColor: '#051A43',
    fontFamily: spoqa,
    fontWeight_: 300,
    fontSize: '20px/30px',
    lineHeight: '30px/40px',
  },
  title: {
    color: 'white',
    textAlign: 'center',

    marginBottom: '30px/57px',
  },
  reviewContainer: {
    display_: '/flex',
    justifyContent_: '/space-between',

    width: '/1128px',
    height: '/100%',

    margin_: '/0 auto',
  },
  sliderContainer: {
    width: '100%',
  },
  reviewBox: {
    marginLeft: '15px/@',
    marginRight: '15px/@',

    width: '95%/360px@322px',
    height: '345px/325px',

    display: 'flex',
    flexDirection: 'column',
    alinItems_: '/center',

    position: 'relative',
    left: '45%/@50%',
    transform: 'translateX(-50%)/',

    backgroundColor: '#FFF',

    padding: '26px 22px 22px 26px/29px 26px 20px 26px',
  },
  logoImg: {
    height: '40px/40px',
  },
  logoBox: {
    height: '40px/40px',
    marginBottom: '19px/18px',
  },
  contentsBox: {
    height: '175px/125px',
    fontWeight: 300,
    fontSize: '15px/15px',
    lineHeight: '25px/25px',

    marginBottom: '50px/69px',
    color: '#002933',
  },
  authorBox: {
    height: '27px/27px',
    fontWeight: 300,
    fontSize: '14px/15px',
    lineHeight: '27px/25px',

    color: '#8C9496',
  },
  titleFont: {
    fontFamily: robotoRegular,
    fontSize: '12px/.9vw',
    letterSpacing: '1.5px/1.73px',
    color: 'white',
    marginTop: '28px/',
    marginBottom: '270px/2vw',
  },
  quoteStyle: {
    width: '3vw',
    height: '2.1vw',

    backgroundColor: 'rgba(0,0,0,0)',
  },
  contentFont: {
    width: '346px/',

    fontSize: '14px/1.45vw',
    fontFamily: spoqa,
    fontWeight_: '400/300',
    lineHeight: '1.48/1.48',
    textAlign_: '/center',
    color: 'white',

    marginBottom: '3vw/.83vw',
    marginTop: '/2.08vw',

    whiteSpace: 'pre',
  },
  reviewerFont: {
    fontFamily: spoqa,
    fontWeight_: '400/300',
    fontSize: '13px/1.11vw',
    lineHeight: '1.69/1.69',
    color: '#7b898d/whit',

    marginBottom: '2.08vw',
  },
  arrowRightButtonStyle: {
    width: '1.3vw/1.3vw',
    height: '2.6vw/2.6vw',

    opacity_: '/0.2',
    cursor: 'pointer',

    position: 'absolute',
    right: '4vw/4vw',
    bottom: '30px/5vw',

    zIndex: 1,

    ':hover': {
      opacity: 1,
      transition: '0.3s',
    },
  },
  arrowLeftButtonStyle: {
    width: '1.3vw/1.3vw',
    height: '2.6vw/2.6vw',

    opacity_: '/0.2',
    cursor: 'pointer',

    transform: 'rotate(180deg)',

    position: 'absolute',
    bottom: '30px/5vw',
    left: '4vw/4vw',

    ':hover': {
      opacity: 1,
      transition: '0.3s',
    },
  },
  numberBox: {
    display: 'flex',

    position: 'absolute',
    bottom: '30px/5vw',
    left: '40vw/15vw',

    color: 'white',
  },
  numberFont: {
    fontFamily: robotoRegular,
    fontSize: '12px/18px',
    letterSpacing: '1.33px/2px',
    color: 'white',

    marginRight: '3px/5px',
    marginLeft: '3px/5px',

    cursor: 'pointer',
  },
  hrStyle: {
    width: '6vw/2.5vw',
    height: 0,
    borderTop: 'solid 1px white',

    marginTop: '6px/7px',
    marginLeft: '3px/0.21vw',
    marginRight: '3px/0.21vw',
  },
  imageStyle: {
    position: 'absolute',
    width: '361px/100%',
    height: 'auto',
    top: '70px/0',
    left: 0,
    zIndex_: '1/-1',
  },
}, isMobile, isTablet, {width: 1440});
