import '../shared/helper/IEHelper';
import React from 'react';
import ReactDOM from 'react-dom';
import { Route, Switch } from 'react-router-dom';

import '../index.css';
import './transbiz.css';
import * as serviceWorker from '../serviceWorker';
import { NewsPopUp } from '../shared/presentation/news-popup/NewsPopUp';
import PrivacyPolicy from '../shared/presentation/privacy-policy/PrivacyPolicy';
import TermsOfService from '../shared/presentation/terms-of-service/TermsOfService';
import RequestPopUp from './pages/request-popup/RequestPopUp';
import Home from './pages/home/Home';
import Manual from '../startbiz/pages/manual/Manual';
import TransbizMenu from '../shared/presentation/menu-popup/TransbizMenu';
import KakaoPopUp from '../shared/presentation/kakao-popup/KakaoPopUp';
import { RootFrame } from '../shared/presentation/RootFrame';

ReactDOM.render(
  <RootFrame project={'transbiz'} isDefaultFont>
    <Route component={KakaoPopUp}/>
    <Route component={RequestPopUp}/>
    <Route component={NewsPopUp}/>
    <Route component={TransbizMenu}/>
    <Switch>
      <Route exact path='/privacy_policy' component={PrivacyPolicy}/>
      <Route exact path='/terms_of_service' component={TermsOfService}/>
      <Route exact path='/manual' component={Manual}/>
      <Route exact path='/request' render={() => <Home request/>}/>
      <Route exact path='/consulting' render={() => <Home request/>}/>
      <Route exact path='/kakao' render={() => <Home kakao/>}/>
      <Route component={Home}/>
    </Switch>
  </RootFrame>
  ,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
