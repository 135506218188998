export const PhoneFrame = require('./pngs/shared-phone-frame.png');
export const ReqPopupBackDesk = require('./pngs/req-popup-back-desk.png');
export const ReqPopupBackMobile = require('./pngs/req-popup-back-mobile.png');
export const NewsKoreaThumb = require('./jpgs/news-korea-thumb.jpg');
export const NewsSeoulThumb = require('./jpgs/news-seoul-thumb.jpg');
export const Quotation = require('./jpgs/quotation.jpg');
export const NewsKoreaBannerDesk = require('./jpgs/news-korea-banner-desk.jpg');
export const NewsKoreaBannerMobile = require('./pngs/news-korea-banner-mobile.png');
export const NewsSeoulBannerMobile = require('./pngs/news-seoul-banner-mobile.png');
export const NewsSeoulBannerDesk = require('./jpgs/news-seoul-banner-desk.jpg');
