import React, { useEffect } from 'react';
import { css, StyleSheet } from 'aphrodite';
import { useHistory, withRouter } from 'react-router-dom';
import { useRecoilValue } from 'recoil';

import './KakaoPopUp.css';
import { BackgroundButton } from './BackgroundButton';
import { SharedAssets } from '../../assets/SharedAssets';
import { CloseButton } from '../shared/CloseButton';
import {
  defaultPage,
  IHistory,
  isCheckProcess,
  isInputProcess,
  isShowKakaoPopup,
  isSuccessProcess
} from '../HistoryHelper';
import { KakaoInput } from './KakaoInput';
import { KakaoCheck } from './KakaoCheck';
import { KakaoSuccess } from './KakaoSuccess';
import { useInjection } from '../InversifyProvider';
import { LOCATION_SERVICE, LocationService } from '../../store/LocationStore';
import { totalScreenState } from '../MobileFrame';
import { spoqa } from '../../css/css-utils';

const styles = StyleSheet.create({
  kakao: {
    width: 40,
    height: 40,
    fill: '#3a1e0b',
  }
})

export const KakaoPopUp = () => {
  const history = useHistory<IHistory>();

  return (
    <>{isShowKakaoPopup(history) && <KakaoBody />}</>
  );
}

function KakaoBody() {
  const history = useHistory<IHistory>();
  const { isMobile, isTablet, isDesktop } = useRecoilValue(totalScreenState);
  const locationService = useInjection<LocationService>(LOCATION_SERVICE);

  useEffect(() => {
    if (isMobile || isTablet) {
      locationService.openKakaoMobilePage();
      history.goBack();
    }
  }, [isMobile, isTablet, locationService, history]);
  const closePopup = () => history.push(defaultPage);

  return (
    <>
      {isDesktop && (
        <div className='popup background' id={'kakao_popup_frame'}>
          <div className='kakao window'>
            <CloseButton onClickClose={closePopup}/>
            <div
              className='kakao title space'
              style={{fontFamily: spoqa, fontWeight: 300}}
              onClick={closePopup}
            >
              <SharedAssets.Kakao className={css(styles.kakao)}/>
              <p style={{marginLeft: 12}}>카카오톡 상담</p>
            </div>
            {isInputProcess(history) && <KakaoInput />}
            {isCheckProcess(history) && <KakaoCheck />}
            {isSuccessProcess(history) && <KakaoSuccess />}
          </div>
          <BackgroundButton/>
        </div>
      )}
    </>
  );
}

export default withRouter(KakaoPopUp);
