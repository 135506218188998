import { createCalc, MOBILE_SIZE, TABLET_SIZE } from '../helper/Util';

const SharedStyle = (isMobile: boolean) => ({
  onlyDesktop: {
    display: 'inherit',
    [`@media ( max-width: ${MOBILE_SIZE}px )`]: {
      display: 'none',
    },
    [`@media ( max-width: ${TABLET_SIZE}px )`]: {
      display: 'none',
    }
  },
  onlyMobile: {
    display: 'none',
    [`@media ( max-width: ${MOBILE_SIZE}px )`]: {
      display: 'inherit',
    },
    [`@media ( max-width: ${TABLET_SIZE}px )`]: {
      display: 'none',
    }
  },
  notDesktop: {
    display: 'none',
    [`@media ( max-width: ${MOBILE_SIZE}px )`]: {
      display: 'inherit',
    },
    [`@media ( max-width: ${TABLET_SIZE}px )`]: {
      display: 'inherit',
    }
  },
  backgroundStyle: {
    position: 'absolute' as 'absolute',
    width: '100%',
    height: '101%',
    top: -1,
    left: 0,
    zIndex: -2,
  },
  closeButton: {
    width: isMobile ? createCalc(15, 414) : '1.1vw',
    height: isMobile ? createCalc(15, 414) : '1.1vw',
    backgroundColor: 'rgba(0, 0, 0, 0)',

    cursor: 'pointer',

    position: 'absolute' as 'absolute',
    right: isMobile ? '2vw' : '1vw',
    top: isMobile ? '2vw' : '1vw',
    zIndex: 5,
    fill: isMobile ? 'white' : 'black',
  },
});

export default SharedStyle;
