import React, { useEffect } from 'react';
import { css } from 'aphrodite';
import { useRecoilValue } from 'recoil';
import { Link } from 'react-router-dom';

import { SuccessProcessStyle } from './SuccessProcessStyle';
import SharedStyleSheet from '../../css/SharedStyleSheet';
import { isWorkTimeState } from '../../helper/Util';
import { totalScreenState } from '../MobileFrame';
import { defaultPage } from '../HistoryHelper';
import { useResetAtom } from 'jotai/utils';
import { requestAtom } from '../../store/RequestAtom';

interface Props {
  highlight?: string;
  baseColor?: string;
  buttonColor?: string;
}

export function SuccessProcess({ highlight, baseColor, buttonColor }: Props) {
  const isWorkTime = useRecoilValue(isWorkTimeState);
  const { isMobile, isTablet } = useRecoilValue(totalScreenState);
  const resetRequest = useResetAtom(requestAtom);

  const styles = SuccessProcessStyle(
    isMobile,
    isTablet,
    baseColor,
    highlight,
    buttonColor,
  );
  const shared = SharedStyleSheet(isMobile);

  useEffect(() => {
    return () => {
      resetRequest();
    }
  }, [])

  return (
    <div
      className={css(styles.successOutline)}
      id={'success_frame'}
    >
      <div>
        <div className={css(styles.successTitle)}>
          접수가 완료되었습니다
        </div>
        <div className={css(styles.hrStyle)}/>
        {isWorkTime ? (
          <>
            <div className={css(styles.successSubTitle)}>
              연락 가능시간: 평균 10분 이내
            </div>
            <div
              className={css(styles.successDesc)}
              data-testid='success-comment'
            >
              작성해주신 정보는 정상적으로 접수되었습니다. <br className={css(shared.onlyMobile)}/>
              고객님의 소중한 문의 감사합니다. <br className={css(shared.onlyMobile)}/>
              신속히 연락 드리도록 하겠습니다.
            </div>
          </>
        ) : (
          <>
            <div className={css(styles.successSubTitle)}>
              업무시간: 평일 09:00-19:00
            </div>
            <div
              className={css(styles.successDesc)}
              data-testid='success-comment'
            >
              작성해주신 정보는 정상적으로 접수되었습니다. <br className={css(shared.onlyMobile)}/>
              업무시간에 신속히 연락드리도록 하겠습니다. 감사합니다.
            </div>
          </>
        )}
      </div>
      <Link
        className={css(styles.requestButton)}
        to={defaultPage}
      >
        <p
          className='white'
          id={'confirm_close_button'}
        >확인</p>
      </Link>
    </div>
  );
}
