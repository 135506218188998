import React from 'react';
import { css } from 'aphrodite';
import { useRecoilValue } from 'recoil';

import { spoqa } from '../../../../shared/css/css-utils';
import { totalScreenState } from '../../../../shared/presentation/MobileFrame';
import { DesktopBr } from '../../../../shared/presentation/DesktopBr';
import { CreateStyle } from '../../../../shared/presentation/shared/CreateStyle';

const ADVANTAGE_LIST = ([
  {
    list: '세금',
    personal: '최대 소득세 : 42%, \n지역보험 가입',
    corporate: '법인세율 : 25%, \n직장보험 가입(저렴)',
  },
  {
    list: '절세',
    personal: '불가능',
    corporate: '특허권, 영업권 평가를 \n통한 절세',
  },
  {
    list: '사업리스크',
    personal: '대표가 사업 관련 채무 \n책임져야함',
    corporate: '대표나 주주에게 사업관련 \n채무 책임이 없음',
  },
  {
    list: '자금조달',
    personal: '대외 신용도 낮음',
    corporate: '높은 대외 신용도를 바탕으로 \n자금조달 용이함',
  },
  {
    list: '정책자금',
    personal: '받을 수 있는 \n정책자금이 제한됨',
    corporate: '중소기업 창업자금, \n고용지원금 등 다양함',
  },
]);

function Advantage() {
  const {isMobile, isTablet} = useRecoilValue(totalScreenState);
  const styles = AdvantageStyle(isMobile, isTablet);
  return (
    <div className={css(styles.background)} id={'section_5'}>

      <div className={css(styles.titleFont)}>
        개인사업자의 <DesktopBr/>
        법인전환의 장점
      </div>

      <div className={css(styles.advantageTotal)}>
        <div className={css(styles.topAdvantageBox, styles.topColor)}>
          <div className={css(styles.listBox)}>
            항목
          </div>
          <div className={css(styles.personalBox, styles.topColor)}>
            개인사업자
          </div>
          <div className={css(styles.corporateBox)}>
            법인사업자
          </div>
        </div>

        {ADVANTAGE_LIST.map((item, index) => (
          <div key={'advantage-' + index}>
            <div className={index === 0
              ? css(styles.advantageBox, styles.secondAdvantageLine)
              : css(styles.advantageBox)}
            >
              <div className={css(styles.listBox)}>
                {item.list}
              </div>
              <div className={css(styles.personalBox, styles.largerFont)}>
                {item.personal}
              </div>
              <div className={css(styles.corporateBox, styles.largerFont)}>
                {item.corporate}
              </div>
            </div>
          </div>
        ))}
      </div>

    </div>
  );
}

export default Advantage;

const AdvantageStyle = (isMobile: boolean, isTablet: boolean) => CreateStyle({
  background: {
    height: '500px/700px',
    backgroundColor: '#051a43',

    display: 'flex',
    flexDirection_: 'column/',
    justifyContent: 'center',
    alignItems_: '/@center',

    fontFamily: spoqa,

    paddingTop: '20px/100px',
    paddingLeft: '21px/',
  },
  titleFont: {
    fontSize: '24px/38px',
    fontWeight: 300,
    lineHeight: '1.42/1.37',
    color: 'white',

    marginRight: '/255px',
  },
  advantageTotal: {
    width: '360px/900px',

    fontWeight: 300,
    fontSize: '13px/18px',

    marginTop: '28px/10px',
  },
  advantageBox: {
    width: '100%',
    height: '60px/85px',

    display: 'flex',
    alignItems: 'center',

    color: 'white',

    borderTop: 'solid 1px rgba(131, 139, 163, .5)',

    whiteSpace_: 'pre/',
  },
  topAdvantageBox: {
    height: '30px/40px',

    display: 'flex',
  },
  topColor: {
    color: '#06b3cb',
  },
  secondAdvantageLine: {
    borderTop: 'none',
  },
  listBox: {
    width: '80px/200px',

    lineHeight: 1.72,
  },
  personalBox: {
    width: '130px/320px',

    lineHeight: 1.72,
    color: '#838ba3',
  },
  corporateBox: {
    width: '150px/',
    lineHeight: 1.72,
  },
  largerFont: {
    fontSize: '13px/19px',
  }
}, isMobile, isTablet);
