import React from 'react';
import { css } from 'aphrodite';
import * as _ from 'lodash';

import { moveToHome } from '../../helper/Util';
import { IResizeWithDirection, withResizeFrameAndDirection } from '../../WithResizeFrame';
import { CreateStyle } from '../shared/CreateStyle';

interface Props {
  baseColor?: string;
  LogoImage: any;
  styles?: { logo?: any, logoIcon?: any, logoIconTop?: any }
}

const SharedLogo: React.FC<IResizeWithDirection & Props> = (
  {
    isTop, isMobile, isTablet,
    history,
    baseColor = 'black',
    LogoImage,
    styles: inputStyle = {},
  }) => {

  const defaultStyle = {
    logo: {
      height: '23px/36.5px',
      cursor: 'pointer',
      marginLeft: '20px/92px',
    },
    logoIcon: {
      width: '150px/300px',
      height: '100%',
      fill: baseColor,
    },
    logoIconTop: {
      width: '150px/300px',
      height: '100%',
      fill: 'white',
    },
  };

  const styles = CreateStyle(_.merge(defaultStyle, inputStyle), isMobile, isTablet, {isNoRatio: true});

  return (
    <div
      className={css(styles.logo)}
      onClick={() => moveToHome(history)}
    >
      <LogoImage className={css(isTop ? styles.logoIconTop : styles.logoIcon)}/>
    </div>
  );
};

export default withResizeFrameAndDirection<Props>(SharedLogo);
