import React from 'react';
import { css } from 'aphrodite';
import { useRecoilValue } from 'recoil';

import NewsStyle from './NewsStyle';
import SharedStyleSheet from '../../css/SharedStyleSheet';
import { Image } from '../../css/Image';
import { totalScreenState } from '../../presentation/MobileFrame';
import { NewsList } from './NewsConstant';
import News from '../../assets/pngs/press.png';


function NewsSection() {
  const screen = useRecoilValue(totalScreenState);
  const {isMobile, isTablet} = screen;

  const styles = NewsStyle(isMobile, isTablet);
  const shared = SharedStyleSheet(isMobile, isTablet);

  return (
    <div className={css(styles.background)}>
      <div className={css(styles.pressFont)}>
        PRESS
      </div>
      <div className={css(styles.newsTotalBox)}>
        {NewsList(screen).map((item, index) => (
          <React.Fragment key={'news-' + index}>
            <div
              className={css(styles.newsBox)}
              onClick={() => window.open(item.linkUrl)}
            >
              <div className={css(styles.titleFont)}>
                {item.title}
              </div>
              <div className={css(styles.contentFont)}>
                {item.content}
              </div>
            </div>
          </React.Fragment>
        ))}
      </div>
      <Image src={News} className={css(styles.imageStyle, shared.onlyDesktop)} alt="이미지"/>
    </div>
  );
}

export default NewsSection;
