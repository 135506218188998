import React, { useEffect } from 'react';
import { css } from 'aphrodite';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { useHistory } from 'react-router-dom';
import Cookies from 'universal-cookie';
import { useAtomValue } from 'jotai';
import { useResetAtom } from 'jotai/utils';

import { CheckProcessStyle } from './CheckProcessStyle';
import { sharedColor } from '../../css/SharedStyleSheet';
import { IHistory, requestPage } from '../HistoryHelper';
import { totalScreenState } from '../MobileFrame';
import { useInjection } from '../InversifyProvider';
import { isLoadingState } from '../LoadingFrame';
import { requestAtom } from '../../store/RequestAtom';
import { CustomRequestService } from '../../service/RequestService';
import { totalKeywordAtom } from '../KeywordAtom';


interface Props {
  baseColor?: string;
  buttonColor?: string;
  cookie?: Cookies;
}

export function CheckProcess({ baseColor, buttonColor }: Props) {
  const request = useAtomValue(requestAtom);
  const resetRequest = useResetAtom(requestAtom);
  const history = useHistory<IHistory>();
  const { isMobile, isTablet } = useRecoilValue(totalScreenState);
  const setIsLoading = useSetRecoilState(isLoadingState);
  const keyword = useAtomValue(totalKeywordAtom);
  const requestService = useInjection(CustomRequestService);

  useEffect(() => {
    if (request.phone === '') {
      history.goBack();
    }
  }, [request.phone, history]);

  const sendRequest = async () => {
    setIsLoading(true);
    try {
      await requestService?.sendFinalRequest(request, keyword);
      setTimeout(() => resetRequest(), 500);
      history.push(requestPage(2));
    } catch (e) {
      console.log(e);
      alert('간편신청에 실패했습니다.');
    } finally {
      setIsLoading(false);
    }
  };

  const styles = CheckProcessStyle(
    isMobile,
    isTablet,
    baseColor,
    buttonColor,
  );
  return (
    <div
      className={css(styles.checkOutline)}
      id={'request_check_frame'}
    >
      <div style={{ width: '100%' }}>
        <div className={css(styles.checkTitle, sharedColor.baseColor)}>
          휴대폰 번호가 맞으시면 아래의 확인버튼을 눌러주세요
        </div>
        <div
          className={css(styles.checkPhone, sharedColor.baseColor)}
          data-testid="checkPhone"
        >
          {request.phone}
        </div>
      </div>
      <div className={css(styles.ButtonDiv)}>
        <div
          className={css(styles.reInputNumberButton)}
          onClick={() => history.goBack()}
          id={'modify_number'}
        >
          <p className="dark-blue">휴대폰 번호 재입력</p>
        </div>
        <div
          className={css(styles.okayButton)}
          onClick={sendRequest}
          id={'check_confirm_button'}
        >
          <p className="white">확인</p>
        </div>
      </div>
    </div>
  );
}
