import React from 'react';
import { css } from 'aphrodite';
import { useRecoilValue } from 'recoil';

import { robotoRegular, spoqa } from '../../../../shared/css/css-utils';
import { Image } from '../../../../shared/css/Image';
import { Assets } from '../../shared/Assets';
import { totalScreenState } from '../../../../shared/presentation/MobileFrame';
import { CreateStyle } from '../../../../shared/presentation/shared/CreateStyle';
import { NotDesktopBr } from '../../../../shared/presentation/NotDesktopBr';

const PROCESS_LIST = ['무료법인전환 신청', '접수 및 담당자 1:1 배정', '법인전환 대행', '세이브택스 세무사 상담', '절세 솔루션'];

function Process() {
  const {isMobile, isTablet, isDesktop} = useRecoilValue(totalScreenState);
  const styles = ProcessStyle(isMobile, isTablet);
  return (
    <div className={css(styles.background)} id={'section_8'}>
      <div className={css(styles.firstFont)}>
        간편하고 신속한 법인전환 프로세스
      </div>
      <div className={css(styles.processTotalBox)}>
        {PROCESS_LIST.map((item, index) => (
          <React.Fragment key={'process-' + index}>
            {index === 2 && !isDesktop && <div className={css(styles.shortHr)}/>}
            {index === 0 && !isDesktop && <div className={css(styles.longHr)}/>}
            <div className={css(styles.imageStyle)}>
              <Image
                src={Assets.Processes[index]}
                alt="프로세스"
                width="100%"
                height="100%"
              />
              <div className={css(styles.titleFont)}>
                {item}
              </div>
              <div className={css(styles.stepFont)}>
                {'STEP ' + (index + 1)}
              </div>
            </div>
            {
              isDesktop ?
                <div className={index === 4 ? undefined : css(styles.hrStyle)}/>
                :
                (
                  <>
                    <div className={(index === 1 || index === 4) ? undefined : css(styles.hrStyle)}/>
                    {index === 1 && <div className={css(styles.longHr)}/>}
                    {index === 4 && <div className={css(styles.shortHr)}/>}
                  </>
                )

            }
          </React.Fragment>
        ))}
      </div>
      <div className={css(styles.lastFont)}>
        * 상담신청을 위하여 남겨주신 개인정보는 개인정보취급방침에 <NotDesktopBr/>
        의거하여 100% 안전하게 보호됩니다.
      </div>
    </div>
  );
}

export default Process;

const ProcessStyle = (isMobile: boolean, isTablet: boolean) => CreateStyle({
  background: {
    height: '500px/583px',

    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent_: '/center',

    fontFamily: spoqa,

    paddingTop: '41px/',
  },
  firstFont: {
    fontSize: '24px/38px',
    fontWeight: 300,
    lineHeight: 1.37,
    color: '#051a43',

    marginBottom: '25px/78px',
  },
  processTotalBox: {
    width: '100%/1272px@414px',

    display: 'flex',

    position: 'relative',

    flexWrap_: 'wrap/',
  },
  imageStyle: {
    width: '75px/151px',
    height: '75px/151px',

    marginBottom: '95px/125px',
  },
  titleFont: {
    width: '86px/210px',
    fontWeight: 300,
    fontSize: '14px/18px',
    lineHeight: 1.72,
    color: '#051a43',
    textAlign: 'center',
    wordBreak: 'keep-all',
    whiteSpace_: '/pre',

    marginTop: '5px/10px',
    marginLeft: '35px/77px',
    transform: 'translateX(-50%)',
  },
  stepFont: {
    width: '50px/65px',
    fontFamily: robotoRegular,
    fontSize: '12px/15px',
    letterSpacing: '1.5px/2px',
    color: '#06b3cb',
    whiteSpace: 'pre',

    marginTop: '5px/10px',
    marginLeft: '38px/80px',
    transform: 'translate(-50%)',
  },
  hrStyle: {
    height: 0,

    flexGrow_: '1/',
    flexShrink_: '0/',
    flexBasis: '40px/150px',

    borderBottom: 'solid 1px rgba(131, 139, 163, .3)',

    marginTop: '40px/78px',
  },
  shortHr: {
    height: 0,

    flexGrow_: '1/',
    flexShrink_: '0/',
    flexBasis: '46px/',

    borderBottom: 'solid 1px rgba(131, 139, 163, .3)',

    marginTop: '37px/',
  },
  longHr: {
    height: 0,

    flexGrow_: '1/',
    flexShrink_: '0/',
    flexBasis: '90px/',

    borderBottom: 'solid 1px rgba(131, 139, 163, .3)',

    marginTop: '37px/',
  },
  lastFont: {
    fontWeight: 300,
    fontSize: '11px/15px',
    color: '#051a43',
    textAlign_: 'center/',
    lineHeight: 1.3,
  },
}, isMobile, isTablet);
