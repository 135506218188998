import Airtable from 'airtable';
import { atom } from 'recoil';

import { FranchiseRequestRepository } from '../domain/FranchiseRequestRepository';
import { FranchisePartnerForm } from './FranchisePartnerForm';
import { AirtableRequestForm, mapRequestToEntity } from '../airtable/RequestForm';
import { AirtableRequestUpdateForm } from './AddRequestDto';
import { ITotalKeyword } from '../presentation/KeywordAtom';

const API_KEY = 'patc9ZiqoPk0ImxuG.322c5f745f033f484b94c00c6793230123367970e20e767b1a1dafb5f32e6ba5';
const BASE_ID = 'appOdiL4PY8HHKlGv';
const TABLE_NAME = '고객 관리';

export const franchisePartnerFormState = atom<FranchisePartnerForm>({
  key: 'franchisePartnerFormState',
  default: new FranchisePartnerForm(),
});

export class AirtableFranchiseRequestRepository implements FranchiseRequestRepository {
  private readonly base: any;
  private readonly table: any;

  constructor() {
    this.base = new Airtable({apiKey: API_KEY}).base(BASE_ID);
    this.table = this.base(TABLE_NAME);
  }

  async addRequest(request: AirtableRequestForm): Promise<string> {
    if (this.table == null) {
      return Promise.reject('airtable setting not exist');
    }

    const result = await this.table.create(request.fields);
    return result.id;
  }

  async addRequestForPartner(request: FranchisePartnerForm, keyword: ITotalKeyword): Promise<string> {
    const table = this.base(TABLE_NAME);
    if (table == null) {
      return Promise.reject('airtable setting not exist');
    }

    const result = await table.create({
      ...request.fields,
      KEYWORD: keyword.first,
      KEYWORD_2: keyword.final,
    });
    return result.id;
  }

  async findExistByRecordId(recordId: string): Promise<any> {
    if (this.table === undefined) {
      return Promise.reject('airtable setting not exist');
    }
    const findExists = await this.table.find(recordId);
    if (findExists == null) {
      throw new Error('Record is not exist');
    }
    return findExists.id;
  }

  async updateRequest(request: AirtableRequestUpdateForm): Promise<string> {
    if (this.table === undefined) {
      return Promise.reject('airtable setting not exist');
    }

    const data = mapRequestToEntity(request);
    return await this.table.update([data]);
  }

}
