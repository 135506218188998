import Airtable from 'airtable';

import { PaymentBankRepository } from '../domain/PaymentBankRepository';
import { AirtableHelper } from '../../../../shared/airtable/AirtableScrapHelper';
import { AirtablePaymentBank } from './AirtablePaymentBank';
import { plainToClass } from 'class-transformer';
import { injectable } from 'inversify';

const BASE_ID = 'app9mEf2v0kxPbAb1';
const TABLE_NAME = '공과금 발송';
const API_KEY = 'patc9ZiqoPk0ImxuG.322c5f745f033f484b94c00c6793230123367970e20e767b1a1dafb5f32e6ba5';

@injectable()
export class AirtablePaymentBankRepository implements PaymentBankRepository {
  async findDefault(): Promise<AirtablePaymentBank> {
    const base = new Airtable({ apiKey: API_KEY }).base(BASE_ID);
    const table = base(TABLE_NAME);

    const result = await table.select({
      filterByFormula: `{Name} = 'default'`
    }).firstPage();

    if (!result || result.length === 0) {
      throw new Error('관련 예금주 명이 존재하지 않습니다.')
    }
    return plainToClass(AirtablePaymentBank, AirtableHelper.mapToJson(result[0])) ;
  }
}


