import { spoqa } from '../../../shared/css/css-utils';
import { CreateStyle } from '../shared/CreateStyle';

export const MenuStyle = ({ isMobile, isTablet, color }: {
  isMobile: boolean,
  isTablet: boolean,
  color: string,
}) => CreateStyle({
  wrapper: {
    boxSizing: 'border-box',
    width: '68%/@513px',
    height: '100%',
    background: 'white',

    padding: '20vw 4vw 25vw 0/@128px 42px 94px 0',
    fontFamily: spoqa,
    fontWeight: 500,
    fontSize: '20px/@30px',
    lineHeight: '1.9/@2.1',
    textAlign: 'right',

    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',

    color: color,
    overflow_: 'scroll/',
  },
  button: {
    cursor: 'pointer',
    whiteSpace: 'pre',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  icon: {
    height: '4.5vw/@34px',
    marginRight: '2vw/@12px',
    fill: color,
  },
  time: {
    fontSize: '12px/@20px',

    marginBottom: '5vh/@',
  },
}, isMobile, isTablet);
