import React from 'react';
import { css } from 'aphrodite';
import { useRecoilValue } from 'recoil';

import { ReactComponent as CloseIcon } from '../icons/close.svg';
import { sharedColor } from '../../css/SharedStyleSheet';
import { totalScreenState } from '../MobileFrame';
import { CreateStyle } from './CreateStyle';

interface Props {
  onClickClose: () => void
  isAlwaysBlack?: boolean,
}

export function CloseButton({isAlwaysBlack, onClickClose}: Props) {
  const {isMobile, isTablet} = useRecoilValue(totalScreenState);
  const style = CreateStyle({
    iconBox: {
      width: '77%/',
      height: '40px/',
      background: 'white',

      position: 'fixed',
      top: '0/',

      zIndex: 4,
    },
    icon: {
      width: '15px/20px@26px',
      height: '15px/20px@26px',

      cursor: 'pointer',

      position: 'fixed',
      right: '3vw/1vw@36px',
      top: '3vw/1vw@25px',
      zIndex: 50,
      fill_: 'white/black',
    }
  }, isMobile, isTablet);

  return (
    <div className={css(style.iconBox)}>
      <CloseIcon
        className={css(
          style.icon,
          isAlwaysBlack && sharedColor.black
        )}
        onClick={onClickClose}
        data-testid="close"
        id={'request_close_button'}
      />
    </div>
  );
}
