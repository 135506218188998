import React from 'react';
import { css } from 'aphrodite';

import { ReactComponent as CallLogo } from '../../assets/svgs/call.svg';
import SharedStyleSheet from '../../../shared/css/SharedStyleSheet';
import { useRecoilValue } from 'recoil';
import { totalScreenState } from '../../../shared/presentation/MobileFrame';
import { RenewalButtonStyle } from '../../../startbiz/pages/shared/header/RenewalButtonStyle';
import { IHistory, isPartnershipPage, requestPage } from '../../../shared/presentation/HistoryHelper';
import { scrollWindow } from '../../../shared/helper/Util';
import { useHistory } from 'react-router-dom';
import { useInjection } from '../../../shared/presentation/InversifyProvider';
import { LOCATION_SERVICE, LocationService } from '../../../shared/store/LocationStore';

export const RequestButton = () => {
  const {isMobile, isTablet, isDesktop, isTop} = useRecoilValue(totalScreenState);
  const history = useHistory<IHistory>();
  const locationService = useInjection<LocationService>(LOCATION_SERVICE);
  const styles = RenewalButtonStyle({
    isMobile,
    isTablet,
  });
  const shared = SharedStyleSheet(isMobile);
  const showRequestOrCall = () => {
    if (!isDesktop) {
      locationService.callToService();
      return;
    }
    if (isPartnershipPage(history)) {
      scrollWindow('request');
      return;
    }
    history.push(requestPage());
  };

  return (
    <>
      {
        (isTop || isDesktop) && (
          <div
            className={css(styles.button, isTop && styles.button_top)}
            onClick={showRequestOrCall}
            id={'call_request_button'}
          >
            <CallLogo className={css(styles.buttonIcon)}/>
            {isDesktop && (
              <p className={css(shared.iconSpace)}>
                고객센터 {process.env.REACT_APP_SERVICE_CENTER_NUMBER}
              </p>
            )}
          </div>
        )
      }
    </>

  );
};
