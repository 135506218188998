import { css } from 'aphrodite';
import React from 'react';
import { useAtom } from 'jotai';

import { nameAtom } from '../../../shared/store/RequestAtom';
import { requestErrorAtom } from '../../../shared/store/RequestErrorAtom';

interface INameInputProps {
  styles: { inputBox: Object, input: Object, error: Object }
}

export function NameInput({ styles }: INameInputProps) {
  const [name, setName] = useAtom(nameAtom);
  const [errors, setErrors] = useAtom(requestErrorAtom);

  const onChangeName = (name: string) => {
    setName(name);
    setErrors({ ...errors, isNameError: false })
  }

  return (
    <div className={css(styles.inputBox)}>
      <input
        className={css(styles.input)}
        placeholder={'이름(상호)'}
        value={name}
        onChange={e => onChangeName(e.target.value)}
        data-testid='name'
        id={'name_input'}
      />
      {errors.isNameError && (
        <div className={css(styles.error)}>
          {'이름(상호)를 입력해주세요'}
        </div>
      )}
    </div>
  )
}
