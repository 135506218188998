import React from 'react';
import { css } from 'aphrodite';
import { useRecoilValue } from 'recoil';
import { Link } from 'react-router-dom';

import { spoqa } from '../../../../shared/css/css-utils';
import { Image } from '../../../../shared/css/Image';
import { Assets } from '../../shared/Assets';
import { totalScreenState } from '../../../../shared/presentation/MobileFrame';
import { requestPage } from '../../../../shared/presentation/HistoryHelper';
import { DesktopBr } from '../../../../shared/presentation/DesktopBr';
import { NotDesktopBr } from '../../../../shared/presentation/NotDesktopBr';
import { CreateStyle } from '../../../../shared/presentation/shared/CreateStyle';

function SimpleRequest() {
  const {isMobile, isTablet, isDesktop} = useRecoilValue(totalScreenState);
  const styles = SimpleRequestStyle(isMobile, isTablet);

  return (
    <div className={css(styles.background)} id={'section_12'}>
      <Image src={Assets.Map} className={css(styles.imageStyle)} alt="지도"/>
      <div className={css(styles.firstFont)}>
        전국 어디에서나 <NotDesktopBr/>
        간편하게 신청해주세요
      </div>
      <div className={css(styles.secondFont)}>
        유선 및 온라인으로도 100% 동일하게<NotDesktopBr/> 진행이 가능합니다. <DesktopBr/>
        {isDesktop && (<>전국 어디에서나 <span style={{color: '#06b3cb'}}>홈페이지 또는 전화</span>를 통해 간편하게 연락주세요</>)}
      </div>
      <Link className={css(styles.requestButton)} to={requestPage()} id={'request_button'}>
        무료 법인전환 신청하기
      </Link>
    </div>
  );
}

export default SimpleRequest;

const SimpleRequestStyle = (isMobile: boolean, isTablet: boolean) => CreateStyle({
  background: {
    height: '300px/474px',

    paddingTop: '/129px',
    paddingLeft: '22px/272px@',
    backgroundColor: '#fafafa',

    position: 'relative',

    fontFamily: spoqa,

    display_: 'flex/',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems_: '/@center',

    overflow_: 'hidden/',
  },
  firstFont: {
    fontWeight: 300,
    fontSize: '24px/38px',
    lineHeight: '1.42/1.37',
    color: '#051a43',

    zIndex: 1,

    marginBottom: '7px/13px',
  },
  secondFont: {
    fontWeight: 300,
    fontSize: '14px/18px',
    lineHeight: 1.72,
    color: '#051a43',

    zIndex: 1,

    marginBottom: '15px/30px',
  },
  requestButton: {
    width: '276px/18.54vw',
    height: '46px/3.47vw',

    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

    cursor: 'pointer',

    border: 'solid 1px #051a43',

    zIndex: 1,

    fontWeight: 300,
    fontSize: '14px/0.97vw',
    color: '#051a43',

    ':hover': {
      backgroundColor: '#051a43',
      color: 'white',
      transition: '0.3s',
    },
  },
  imageStyle: {
    width: '370px/590px',
    height: '250px/430px',

    position: 'absolute',
    top: '10px/20px',
    right: '-80px/250px',

    zIndex: 0,
  },
}, isMobile, isTablet);

