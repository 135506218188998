import React, { useRef } from 'react';
import { useHistory, withRouter } from 'react-router-dom';
import { css } from 'aphrodite';
import { useRecoilValue } from 'recoil';
import Cookies from 'universal-cookie';

import RequestPopUpStyle from '../../../shared/presentation/request-popup/RequestPopUpStyle';
import { DefaultInputForm } from '../../../shared/presentation/request-popup/DefaultInputForm';
import { CheckProcess } from '../../../shared/presentation/request-popup/CheckProcess';
import { SuccessProcess } from '../../../shared/presentation/request-popup/SuccessProcess';
import { RequestBanner } from '../../../shared/presentation/request-popup/RequestBanner';
import { CloseButton } from '../../../shared/presentation/shared/CloseButton';
import { Assets } from '../shared/Assets';
import { isMobileState } from '../../../shared/presentation/MobileFrame';
import {
  closedPage,
  IHistory,
  isCheckProcess,
  isInputProcess,
  isShowRequestPopup,
  isSuccessProcess
} from '../../../shared/presentation/HistoryHelper';


function RequestPopUp() {
  const isMobile = useRecoilValue(isMobileState);
  const myRef = useRef<HTMLDivElement>(null);
  const history = useHistory<IHistory>();
  const cookie = new Cookies();

  const styles = RequestPopUpStyle(isMobile);
  const banner = {
    imagePath: Assets.RequestPopupBack(isMobile),
    title: '법인전환 간편신청하기',
    mobileTitle: '법인전환 간편신청하기',
    subTitle: '문의 남겨주시면 빠른 연락드리겠습니다.',
    privacyPolicyComment: '상담신청을 위하여 남겨주신 개인정보는 개인정보취급방침에\n의거하여 100% 안전하게 보호됩니다.',
  };
  return (
    <React.Fragment>
      {isShowRequestPopup(history) &&
      <div className='popup background'>
        <div className={css(styles.requestBox)} ref={myRef}>
          <CloseButton onClickClose={() => history.push(closedPage)}/>
          <RequestBanner {...banner}/>
          {isInputProcess(history) &&
          <DefaultInputForm
            reference={myRef}
            cookie={cookie}
          />}
          {isCheckProcess(history) && <CheckProcess cookie={cookie}/>}
          {isSuccessProcess(history) && <SuccessProcess/>}
        </div>
        <div
          className='background touchable'
          onClick={() => history.push(closedPage)}
          data-testid='background'
        />
      </div>}
    </React.Fragment>
  );
}

export default withRouter(RequestPopUp);
