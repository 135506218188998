import React from 'react';
import SharedLogo from './SharedLogo';

interface Props {
  LogoImage: any,
  baseColor?: string,
}

export const RenewalLogo = (
  {
    baseColor,
    LogoImage,
  }: Props) => {
  return (
    <SharedLogo
      styles={{
        logo: {
          position: 'relative',
          top: '-2px',

          marginLeft: '20px/40px',
          marginRight: '/32px',
        },
        logoIcon: {
          width: '/145px',
          height: '/21px',
        },
        logoIconTop: {
          width: '/145px',
          height: '/21px',
        },
      }}
      LogoImage={LogoImage}
      baseColor={baseColor}
    />
  );
}
