import React from 'react';
import { css } from 'aphrodite';
import { Link } from 'react-router-dom';
import { useRecoilValue } from 'recoil';

import { ReactComponent as Kakao } from '../../../../shared/presentation/icons/kakao.svg';
import { totalScreenState } from '../../../../shared/presentation/MobileFrame';
import { kakaoPage } from '../../../../shared/presentation/HistoryHelper';
import { RenewalButtonStyle } from './RenewalButtonStyle';

export function KakaoButton() {
  const { isMobile, isTablet, isTop, isDesktop } = useRecoilValue(totalScreenState);
  const styles = RenewalButtonStyle({
    isMobile,
    isTablet,
  });

  return (
    <>
      {(isTop || isDesktop) && (
        <Link
          className={css(styles.button, isTop && styles.button_top)}
          to={kakaoPage()}
          id={'kakao_request_button'}
          rel={'kakao_request'}
        >
          <Kakao className={css(styles.buttonIcon)}/>
          {isDesktop && <p className={css(styles.text, styles.iconSpace)}>카카오톡 상담</p>}
        </Link>
      )}
    </>
  );
}
