import React from 'react';
import { withRouter } from 'react-router-dom';

import { scrollWindow } from '../../helper/Util';
import MenuFrame from './MenuFrame';
import { closedPage, isPartnershipPage, requestPage, useMyHistory } from '../HistoryHelper';

function TransbizMenu() {
  const history = useMyHistory();

  const onClickRequest = () => {
    if (isPartnershipPage(history)) {
      history.push(closedPage);
      scrollWindow('request');
      return;
    }
    history.push(requestPage());
  };

  const openManual = () => {
    window.open('https://manual.k-startbiz.org');
  };

  return (
    <MenuFrame
      menuList={[
        {
          title: '무료법인전환 간편신청하기',
          callback: onClickRequest,
        },
        {
          title: '서비스 이용 매뉴얼',
          callback: openManual,
        },
      ]}
      textColor={process.env.REACT_APP_BASE_COLOR!}
    />
  );
}

export default withRouter(TransbizMenu);
