import React from 'react';
import { useRecoilValue } from 'recoil';

import PrivacyPolicyStyle from './PrivacyPolicyStyle';
import { isMobileState } from '../MobileFrame';

function PrivacyPolicy() {
  const isMobile = useRecoilValue(isMobileState);
  const companyName = process.env.REACT_APP_COMPANY_NAME;
  const companySite = process.env.REACT_APP_COMPANY_SITE;
  const phoneNumber = process.env.REACT_APP_SERVICE_CENTER_NUMBER;
  const styles = PrivacyPolicyStyle(isMobile);

  const startLocation = window.scrollTo(0,0);
  return (
    <div style={styles.wrapper}>
      <>
        {startLocation}
      </>
      <div style={styles.background}>
        <p style={styles.title}>
          {companyName} 이용약관은 공정거래위원회의 「표준이용약관」을 기반으로
          작성되었음을 알려드립니다.
        </p>
        <p style={styles.text}>
          <span style={styles.subTitle}>제 1 조 (목적)</span><br/>
          본 약관은 {companyName}(이하 '회사'라 한다)와 {companyName} 서비스(이하 '서비스'라 한다) 이용회원(이하 '회원'이라 한다) 간에 서비스의 이용조건 및 절차, 정산,
          회원과 회사의 권리, 의무, 책임사고와 기타 필요한 사항에 관한 사항을 규정함을 목적으로 한다.
          <br/><br/>
          <span style={styles.subTitle}>제 2 조 (약관의 효력과 변경)</span><br/>
          ① 서비스 이용은 회원이 본 약관 내용에 동의하는 경우, {companyName} 서비스 제공 행위 및 회원의 서비스 사용 행위에 본 약관이 우선적으로 적용 된다. ② 회사는 본 약관을 사전 고지
          없이 변경할 수 있고, 변경된 약관은 사이트 내에 공지하거나 전자우편을 통하여 회원에게 공지하며, 공지와 동시에 그 효력이 발생한다. 이용자가 변경된 약관에 동의하지 않는 경우, 이용자는 본인의
          회원등록을 취소(회원탈퇴)할 수 있으며, 계속된 사용의 경우는 약관 변경에 대한 동의로 간주한다.
          <br/><br/>
          <span style={styles.subTitle}>제 3 조 (약관 외 준칙)</span><br/>
          ① 본 약관은 회사가 제공하는 별도의 이용에 관한 규정과 함께 적용될 수 있다. ② 본 약관에 명시되지 않은 사항에 관하여는 정보통신 윤리강령, 여신전문금융업법, 통신비밀보호법, 정보통신망 이용촉진
          및 정보보호 등에 관한 법률 등 관계 법령 및 회사와 별도로 정한 개별 이용계약서(동의서 등)의 규정을 적용한다.
          <br/><br/>
          <span style={styles.subTitle}>제 4 조 (용어의 정의)</span><br/>
          ① 본 약관에서 사용하는 용어의 정의는 다음 각 호와 같다. 1) 서비스 : 회사가 회원에게 {companyName}를 활용한 매출/매입관리, 장부작성, 회계 관리, 경영컨설팅 등을 제공하는
          '서비스'를 말한다. 2) 회원 : 회사가 제공하는 서비스를 이용하기 위해 회원 ID와 사용자 암호를 정하는 등 소정의 가입 절차를 거쳐 본 약관을 승인하고, 회사로부터 가입 신청에 대한 승낙을
          받아 서비스를 제공 받는 사람을 의미한다. 3) 서비스 이용 계약 : 서비스 이용과 관련하여 회사와 회원 간에 체결하는 계약을 말한다. 4) 사이트 : 회사가 서비스를 제공하기 위하여 컴퓨터 등
          정보통신 설비를 이용하여 운영하는 사이트를 말하며, 계정을 이용하여 서비스를 제공 받을 수 있는 아래의 사이트 및 어플리케이션을 말한다. - {companyName} 홈페이지
          : {companySite} ② 본 약관에서 정의하지 않은 용어는 관계법령 및 개별서비스에 대한 별도 약관 및 서비스별 안내 등에서 정하는 바에 의한다.
          <br/><br/>
          <span style={styles.subTitle}>제 5 조 (계약성립)</span><br/>
          ① 이용계약은 회원가입 신청자가 온라인 등으로 {companyName}에서 제공하는 가입 양식에 따라 회원정보 사항을 기입한 후 이 약관에 동의한다는 의사표시를 함으로서 성립된다. 1)
          회원가입계약의 성립 시기는 {companyName}의 승낙이 회원에게 도달한 시점으로 한다. 2) 회원은 등록사항에 변경이 있는 경우, 즉시 전자우편 및 기타 방법으로 {companyName}에
          대하여 그 변경사항을 알려야 한다. ② {companyName}는 다음 각 호에 해당하는 이용계약에 대해서 가입을 취소할 수 있다. 1) 가입신청자가 이전에 회원자격을 상실한 적이 있는 경우 2)
          가입 시 등록한 내용을 허위로 기재하거나 누락이 있는 경우 3) 타인의 명의를 도용하거나, 타인의 서비스 이용을 방해할 목적으로 이용할 경우 ③ {companyName}는 다음 각 호에 해당하는
          경우 그 사유가 해결될 때까지 이용계약을 유보할 수 있다. 1) 서비스 관련 제반 용량이 부족할 경우 2) 기술상 장애 사유가 있어서 불가피하게 이용을 제한해야 할 경우
          <br/><br/>
          <span style={styles.subTitle}>제 6 조 (서비스상품 약관)</span><br/>
          서비스 이용을 위하여 서비스상품 약관 등 별도의 약관이 존재할 수 있다. 이 경우, 추가되는 서비스 상품에 따라 별도의 약관을 제정할 수 있으면, 이용약관과 서비스상품 약관의 내용이 상충될 경우
          서비스상품 약관이 우선 적용된다
          <br/><br/>
          <span style={styles.subTitle}>제 7 조 (회원 정보 사용에 대한 동의)</span><br/>
          ① 회원의 개인정보는 「개인정보보호법」에 의해 보호된다. ② 사이트 이용 회원 정보는 다음과 같이 사용, 관리, 보호된다. 1) 개인정보의 수집 : {companyName}는 회원 가입 시 제공하는
          정보를 통하여 회원에 관한 정보를 수집한다. 2) 개인정보의 사용 : 서비스 제공과 관련해서 수집된 회원의 신상정보를 본인의 승낙 없이 제3자에게 누설, 배포하지 않는다. 단, 전기통신기본법 등
          법률의 규정에 의해 국가기관의 요구가 있는 경우, 범죄에 대한 수사상의 목적이 있거나 정보통신윤리위원회의 요청이 있는 경우 또는 기타 관계법령에서 정한 절차에 따른 요청이 있는 경우, 회원이 회사에
          제공한 개인정보를 스스로 공개한 경우에는 그러하지 않는다. 3) 개인정보의 관리 : 회사는 개인정보의 보호 및 관리를 위하여 서비스의 개인정보관리에서 수시로 회원의 개인정보를 수정/삭제할 수 있다.
          4) 개인정보의 보호 : 회원의 개인정보는 오직 회원만이 열람/수정/삭제할 수 있으며, 이는 전적으로 회원의 ID와 비밀번호에 의해 관리된다. 따라서 타인에게 본인의 ID와 비밀번호를 알려주어서는
          안 되며, 제3자가 ID와 비밀번호를 도용하여 무단으로 이용하고 있음을 인지하는 경우 즉시 회사에 통보하여 그 안내를 따라야 한다. ③ 회원이 본 약관에 따라 이용신청을 하는 것은, 신청서에 기재된
          회원정보를 수집, 이용하는 것에 동의하는 것으로 간주된다.
          <br/><br/>
          <span style={styles.subTitle}>제 8 조 (회원 정보 보안)</span><br/>
          ① 가입 신청자가 사이트 서비스 가입 절차를 완료하는 순간부터 회원은 입력한 정보의 비밀을 유지할 책임이 있으며, 회원의 ID와 비밀번호를 사용하여 발생하는 모든 결과에 대한 책임은 회원 본인에게
          있다. ② ID와 비밀번호에 관한 모든 관리의 책임은 회원에게 있으며, 회원의 ID나 비밀번호가 부정하게 사용되었다는 사실을 발견한 경우에는 즉시 회사에 신고하여야 한다. 신고를 하지 않음으로 인한
          모든 책임은 회원 본인에게 있다. ③ 이용자는 사이트 서비스의 사용 종료 시마다 정확히 접속을 종료해야 하며, 정확히 종료하지 아니함으로써 제3자가 회원에 관한 정보를 이용하게 되는 등의 결과로
          인해 발생하는 손해 및 손실에 대하여 회사는 책임을 부담하지 아니한다. ④ {companyName} 사이트의 회원정보는 사이트 및 어플리케이션 유지·보수를 위해서 혹은 e-mail, SMS, 우편물
          등의 안내자료 발송을 위한 정보로 이용될 수 있다.
          <br/><br/>
          <span style={styles.subTitle}>제 9 조 (서비스 이용시간)</span><br/>
          ① 서비스 이용시간은 업무상 또는 기술상 특별한 지정이 없는 한 연중무휴로 1일 24시간 이용을 원칙으로 한다. 다만, 서비스의 종류 및 내용에 따라 일부의 서비스는 별도의 이용시간을 정할 수
          있으며, 이 경우 해당 내용을 서비스 화면에 공지하도록 한다. ② 제1항의 이용시간에도 불구하고 통신장애, 서비스개발, 정기점검, 긴급조치 등 불가피한 사유에 한해 서비스 제공이 일정기간 동안
          제한되거나 중단될 수 있다.
          <br/><br/>
          <span style={styles.subTitle}>제 10 조 (서비스의 중지 및 정보의 저장과 사용)</span><br/>
          ① 회원은 서비스 이용 중에 보관되거나 전송된 메시지 및 기타 통신 메시지 등의 내용이 국가의 비상사태, 정전, 사이트의 관리 범위 외의 서비스 설비 장애 및 기타 불가항력에 의하여 보관되지
          못하였거나 삭제된 경우, 전송되지 못한 경우 및 기타 통신 데이터의 손실이 있을 경우에 회사는 관련 책임을 부담하지 아니한다. ② 회사가 정상적인 서비스 제공의 어려움으로 인하여 일시적으로 서비스를
          중지하여야 할 경우에는 서비스 중지 고지 후 서비스를 중지할 수 있으며, 이 기간 동안 회원이 고지 내용을 인지하지 못한 데 대하여 회사는 책임을 부담하지 아니한다. 부득이한 사정이 있을 경우 사전
          고지 기간은 감축되거나 생략될 수 있다. 또한 위 서비스 중지에 의하여 서비스에 보관되거나 전송된 메시지 및 기타 통신 메시지 등의 내용이 보관되지 못하였거나 삭제된 경우, 전송되지 못한 경우 및
          기타 통신 데이터의 손실이 있을 경우에 대하여도 회사는 책임을 부담하지 아니한다. ③ 사이트는 사전 고지 후 서비스를 일시적으로 수정, 변경 및 중단할 수 있으며, 이에 대하여 회원 또는 제3자에게
          어떠한 책임도 부담하지 아니한다. ④ 사이트는 이용자가 본 약관의 내용에 위배되는 행동을 한 경우, 임의로 서비스 사용을 제한 및 중지할 수 있다.
          <br/><br/>
          <span style={styles.subTitle}>제 11 조 (서비스의 변경 및 해지)</span><br/>
          ① 회사는 이 서비스를 이용하여 기대하는 손익이나 서비스를 통하여 얻은 자료로 인한 손해에 관하여 책임을 지지 않으며, 회원이 본 서비스에 게재한 정보, 자료, 사실의 신뢰도, 정확성 등 내용에
          관하여는 책임을 지지 않는다. ② 회사는 서비스 이용과 관련하여 가입자에게 발생한 손해 중 가입자의 고의, 과실에 의한 손해에 대하여 책임을 부담하지 아니한다.
          <br/><br/>
          <span style={styles.subTitle}>제 12 조 (정보 제공 및 홍보물 게재)</span><br/>
          ① 사이트는 서비스를 운영함에 있어서 각종 정보를 서비스에 게재하는 방법 등으로 회원에게 제공할 수 있다. ② 사이트는 서비스에 적절하다고 판단되거나 활용 가능성이 있는 홍보물을 게재할 수 있다.
          <br/><br/>
          <span style={styles.subTitle}>제 13 조 (게시물의 저작권)</span><br/>
          ① 이용자가 게시한 게시물의 내용에 대한 권리는 이용자 본인에게 있다. ② 사이트는 게시된 내용을 사전 통지 없이 편집, 이동할 수 있는 권리를 보유하며, 다음의 경우 사전 통지 없이 삭제할 수
          있다. 1) 본 서비스 약관에 위배되거나 상용 또는 불법, 음란, 저속하다고 판단되는 게시물을 게시한 경우 2) 다른 회원 또는 제3자를 비방하거나 중상모략으로 명예를 손상시키는 내용인 경우 3)
          공공질서 및 미풍양속에 위반되는 내용인 경우 4) 범죄적 행위에 결부된다고 인정되는 내용일 경우 5) 제3자의 저작권 등 기타 권리를 침해하는 내용인 경우 6) 기타 관계 법령에 위배되는 경우 ③
          이용자의 게시물이 타인의 저작권을 침해함으로써 발생하는 민, 형사상의 책임은 전적으로 이용자가 부담하여야 한다.
          <br/><br/>
          <span style={styles.subTitle}>제 14 조 (사용자의 행동규범 및 서비스 이용제한)</span><br/>
          ① 이용자가 제공하는 정보의 내용이 허위인 것으로 판명되거나, 그러하다고 의심할 만한 합리적인 사유가 발생할 경우 사이트는 이용자의 서비스 사용을 일부 또는 전부 중지할 수 있으며, 이로 인해
          발생하는 불이익에 대해 책임을 부담하지 아니한다. ② 이용자가 서비스를 통하여 게시, 전송, 입수하였거나 전자메일, 기타 다른 수단에 의하여 게시, 전송 또는 입수한 모든 형태의 정보에 대하여는
          회원이 모든 책임을 부담하며 사이트는 어떠한 책임도 부담하지 아니한다. ③ 사이트는 제공한 서비스가 아닌 가입자 또는 기타 유사업체가 제공하는 서비스의 내용상의 정확성, 완전성 및 질에 대하여
          보장하지 않는다. 따라서 회사는 회원이 위 내용을 이용함으로 인하여 입게 된 모든 종류의 손실이나 손해에 대하여 책임을 부담하지 아니한다. ④ 회원은 본 서비스를 통하여 다음과 같은 행동을 하지
          않는 데 동의 한다. 1) 타인의 ID와 비밀번호를 도용하는 행위 2) 저속, 음란, 모욕적, 위협적이거나 타인의 프라이버시를 침해할 수 있는 내용을 전송, 게시, 게재, 전자메일 또는 기타의
          방법으로 전송하는 행위 3) 서비스를 통하여 전송된 내용의 출처를 위장하는 행위 4) 법률, 계약에 의하여 이용할 수 없는 내용을 게시, 게재, 전자메일 또는 기타의 방법으로 전송하는 행위 5)
          타인의 특허, 상표, 영업비밀, 저작권, 기타 지적재산권을 침해하는 내용을 게시, 게재, 전자메일 또는 기타의 방법으로 전송하는 행위 6) 사이트 승인을 받지 아니한 광고, 판촉물, 정크메일,
          스팸, 행운의 편지, 피라미드 조직, 기타 다른 형태의 권유를 게시, 게재, 전자메일 또는 기타의 방법으로 전송하는 행위 7) 다른 사용자의 개인정보를 수집 또는 저장하는 행위 ⑤ 회사는 회원이 본
          약관을 위배했다고 판단되면 서비스와 관련된 모든 정보를 이용자의 동의 없이 삭제할 수 있다.
          <br/><br/>
          <span style={styles.subTitle}>제 15 조 (회사의 의무 및 준수사항)</span><br/>
          ① 회사는 특별한 사정이 없는 한 회원이 신청한 서비스 제공은 결재 이후 시점부터 이용할 수 있도록 한다. ② 회사는 시스템 및 서버점검 및 업그레이드, 통신두절, 기타 불가항력적인 사고 등 특별한
          사정이 없는 한 본 약관이 정한 바에 따라 지속적, 안정적인 서비스를 제공할 의무가 있다. ③ 회사는 서비스 이용과 관련한 내용을 회원에게 설명해야 하며, 서비스 중 관련 민원을 신속히 해결하도록
          최선의 노력을 다하여야 하고, 서비스를 이용 중인 회원이 불이익을 당하지 않도록 최선의 노력을 다하여야 한다. ④ 회사는 서비스 이용계약의 성립 및 서비스 이행을 위한 필요한 최소한의
          개인신용정보만을 수집하며, 회원이 서비스 이용신청서를 통하여 동의한 개인신용정보의 이용 범위를 초과하여 이용하거나 제3자에게 제공, 위탁하는 경우에는 회원에게 별도의 동의를 받아야 한다. ⑤ 회사는
          서비스와 연관된 각종 정보가 유출되거나 부당하게 이용되지 않도록 정보 보호 및 보안에 선량한 의무를 다하여야 한다. ⑥ 회원은 언제든지 자신의 개인정보를 열람할 수 있고, 회사 또는 정보관리
          책임자에게 잘못된 정보에 대한 정정을 요청할 수 있다. 회사는 회원으로부터 정보 정정의 요청이 있는 경우 그 오류가 시정될 때까지 당해 개인정보를 이용하지 않는다.
          <br/><br/>
          <span style={styles.subTitle}>제 16조 (회원의 의무 및 준수사항)</span><br/>
          ① 회원 가입 시에 요구되는 정보는 정확하게 기입하여야 한다. 또한 이미 제공된 이용자에 대한 정보가 정확한 정보가 되도록 유지, 갱신하여야 하며, 회원은 자신의 ID 및 비밀번호를 제3자에게
          이용하게 해서는 안 된다. ② 회원은 사이트의 사전 승낙 없이 서비스를 이용하여 어떠한 영리 행위도 할 수 없다. ③ 회원은 서비스를 이용하여 얻은 정보를 사이트의 사전 승낙 없이 복사, 복제,
          변경, 번역, 출판, 방송 기타의 방법으로 사용하거나 이를 타인에게 제공할 수 없다. ④ 회원은 서비스 이용과 관련하여 다음 각 호의 행위를 하여서는 안 된다. 1) 다른 회원의 ID를 부정
          사용하는 행위 2) 범죄행위를 목적으로 하거나 기타 범죄행위와 관련된 행위 3) 선량한 풍속, 기타 사회질서를 해하는 행위 4) 타인의 명예를 훼손하거나 모욕하는 행위 5) 타인의 지적재산권 등의
          권리를 침해하는 행위 6) 해킹행위 또는 컴퓨터바이러스의 유포행위 7) 타인의 의사에 반하여 광고성 정보 등 일정한 내용을 지속적으로 전송하는 행위 8) 서비스의 안전적인 운영에 지장을 주거나 줄
          우려가 있는 일체의 행위 9) 회사의 게시된 정보의 변경
          <br/><br/>
          <span style={styles.subTitle}>제 17 조 (이용계약 해지)</span><br/>
          ① 회원이 서비스 이용계약을 해지하고자 할 경우에는 본인이 {companyName} 또는 회사가 정한 별도의 방법을 이용하여 회사에 해지 신청을 하여야 한다. ② 다음의 사항에 해당하는 경우는
          회사는 사전 동의 없이 서비스 이용계약해지나 서비스 중지, 내용 삭제 조치를 취할 수 있다. 1) 회원의 의무를 성실하게 이행하지 않을 때 2) 규정한 유료서비스 이용 요금을 납부하지 않을 때 3)
          본 서비스 목적에 맞지 않는 분야에 정보를 활용 하였을 때 4) 회원이 등록한 정보가 사실과 다르거나 조작했을 때 5) 허위 정보를 등록했을 때 6) 서비스를 목적에 맞지 않게 이용하였다고 운영자가
          판단하였을 때 7) 게시판 도배, 욕설, 폭언을 하거나 서버에 무리를 주었다고 운영자가 판단하였을 때 8) 회원과 회원 간에 분쟁이 있을 때 9) 상업적인 목적으로 서비스를 이용 하였을 때 10)
          기타 서비스의 명예를 훼손 했을 때 11) 개인회원을 위장해 업체광고를 할 경우 12) 타인(가족, 친지, 친구포함)의 주민등록번호로 가입한 경우 13) 연락처 변경 후 회원정보에서 수정 하지 않은
          경우 ③ 제2항에 해당하는 행위를 한 회원은 이로 인해 다른 회원에게 발생한 손해를 배상할 책임이 있다. ④ 제2항의 회사 조치에 대하여 회원은 회사에 이의신청을 할 수 있다. ⑤ 제4항의
          이의제기가 정당하다고 인정되는 경우 회사는 즉시 서비스의 이용을 재개하여야 한다. ⑥ 탈퇴 처리 된 회원은 그 즉시 삭제 처리 된다.
          <br/><br/>
          <span style={styles.subTitle}>제 18 조 (회사의 소유권)</span><br/>
          ① 사이트가 제공하는 서비스, 그에 필요한 소프트웨어, 이미지, 마크, 로고, 디자인, 서비스명칭, 정보 및 상표 등과 관련된 지적재산권 및 기타 권리는 {companyName}에 소유권이 있다.
          ② 이용자는 사이트가 명시적으로 승인한 경우를 제외하고는 전항의 소정의 각 재산에 대한 전부 또는 일부의 수정, 대여, 대출, 판매, 배포, 제작, 양도, 재 라이선스, 담보권 설정 행위, 상업적
          이용 행위를 할 수 없으며, 제3자로 하여금 이와 같은 행위를 하도록 허락할 수 없다.
          <br/><br/>
          <span style={styles.subTitle}>제 19 조 (양도금지)</span><br/>
          회원이 서비스의 이용권한, 기타 이용계약 상 지위를 타인에게 양도, 증여할 수 없다.
          <br/><br/>
          <span style={styles.subTitle}>제 20 조 (손해배상)</span><br/>
          사이트는 무료로 제공되는 서비스와 관련하여 회원에게 어떠한 손해가 발생하더라도 회사가 고의로 행한 범죄행위를 제외하고 이에 대하여 책임을 부담하지 아니한다.
          <br/><br/>
          <span style={styles.subTitle}>제 21 조 (면책조항)</span><br/>
          ① 사이트는 회원이나 제3자에 의해 표출된 의견을 승인하거나 반대하거나 수정하지 않는다. 사이트는 어떠한 경우라도 회원이 서비스에 담긴 정보에 의존해 얻은 이득이나 입은 손해에 대해 책임이 없다.
          ② 사이트는 회원 간 또는 회원과 제3자간에 서비스를 매개로 하여 물품거래 혹은 금전적 거래 등과 관련하여 어떠한 책임도 부담하지 아니하고, 회원이 서비스의 이용과 관련하여 기대하는 이익에 관하여
          책임을 부담하지 않는다.
          <br/><br/>
          <span style={styles.subTitle}>제 22 조 (재판관할)</span><br/>
          사이트는 이용자 간에 발생한 서비스 이용에 관한 분쟁에 대하여는 대한민국 법을 적용하며, 본 분쟁으로 인한 소는 대한민국의 법원에 제기한다.
          <br/><br/>
          <span style={styles.subTitle}>제 23 조 (개인정보관리책임자)</span><br/>
          ① {companyName}는 개인정보의 누출 및 오·남용 등으로 인한 피해를 방지하기 위하여 개인정보취급자를 최소한의 인원으로 제한하고 있으며, 개인정보 관리에 관한 실질적인 책임을 가진 대표자를
          개인정보관리책임자로 지정하고 있다.
          <br/>
          ② {companyName}의 개인정보관리책임자는 다음과 같다.
          <br/>
          성명 : {process.env.REACT_APP_COMPANY_LEADER ?? '이정민'}
          <br/>
          소속 : {companyName}
          <br/>
          전화 : {phoneNumber}
          <br/>
        </p>
      </div>
    </div>
  );
}

export default PrivacyPolicy;

