import React from 'react';
import { css } from 'aphrodite';
import { useRecoilValue } from 'recoil';
import { Link } from 'react-router-dom';

import { spoqa } from '../../../../shared/css/css-utils';
import { Image } from '../../../../shared/css/Image';
import { Assets } from '../../shared/Assets';
import { totalScreenState } from '../../../../shared/presentation/MobileFrame';
import { requestPage } from '../../../../shared/presentation/HistoryHelper';
import { CreateStyle } from '../../../../shared/presentation/shared/CreateStyle';
import { NotDesktopBr } from '../../../../shared/presentation/NotDesktopBr';

function Complicate() {
  const {isMobile, isTablet} = useRecoilValue(totalScreenState);
  const styles = ComplicateStyle(isMobile, isTablet);
  return (
    <div className={css(styles.background)} id={'section_7'}>
      <Image
        src={Assets.ComplicateBack(isMobile)}
        className="background"
        alt="배경"
      />
      <div className={css(styles.firstFont)}>
        복잡한 법인전환, <NotDesktopBr/>
        잘 모르셔도 괜찮습니다.
      </div>
      <div className={css(styles.secondFont)}>
        부담없이 연락주시면 법인전환 전문가의 수 많은 경험을 <NotDesktopBr/>
        통해 쉽고 친절하게 안내드립니다.
      </div>
      <Link className={css(styles.requestButton)} to={requestPage()} id={'request_button'}>
        무료 법인전환 신청하기
      </Link>
    </div>
  );
}

export default Complicate;

const ComplicateStyle = (isMobile: boolean, isTablet: boolean) => CreateStyle({
  background: {
    height: '340px/620px',

    position: 'relative',

    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems_: 'center/',

    paddingLeft: '/273px',

    color: 'white',
    fontFamily: spoqa,
  },
  firstFont: {
    fontWeight: 300,
    fontSize: '31px/50px',
    lineHeight: '1.33/1.2',
    textAlign_: 'center/',

    marginBottom: '6px/13px',
  },
  secondFont: {
    fontWeight: 300,
    fontSize: '15px/18px',
    lineHeight: 1.72,
    textAlign_: 'center/',

    marginBottom: '25px/30px',
  },
  requestButton: {
    width: '309px/18.54vw',
    height: '46px/3.47vw',
    border: 'solid 1px white',

    fontWeight: 300,
    fontSize: '14px/0.97vw',

    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

    cursor: 'pointer',

    ':hover': {
      backgroundColor: 'white',
      color: '#051a43',
      transition: '0.3s',
    },
  },
}, isMobile, isTablet);
