import React from 'react';
import { css } from 'aphrodite';
import { Link } from 'react-router-dom';
import { useRecoilValue } from 'recoil';

import { totalScreenState } from '../../../shared/presentation/MobileFrame';
import { kakaoPage } from '../../../shared/presentation/HistoryHelper';
import { CreateStyle } from '../../../shared/presentation/shared/CreateStyle';
import { SharedAssets } from '../../../shared/assets/SharedAssets';


function FloatingKakaoButton() {
  const { isMobile, isTablet } = useRecoilValue(totalScreenState);
  const styles = FloatingKakaoButtonStyle(isMobile, isTablet);

  return (
    <Link className={css(styles.kakaoButton)} to={kakaoPage()}>
      <SharedAssets.Kakao className={css(styles.kakaostyle)}/>
    </Link>
  );
}

export default FloatingKakaoButton;

const FloatingKakaoButtonStyle = (isMobile: boolean, isTablet?: boolean) => CreateStyle({
  kakaoButton: {
    width: '14vw/14vw@78px',
    height: '14vw/14vw@78px',
    borderRadius: '50%',
    backgroundColor: '#ffe900',

    display_: 'flex/none',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'fixed',
    right: '3.86vw',
    bottom: '11vw',

    zIndex: 18,
    cursor: 'pointer',
    textDecoration: 'none',
    color: 'inherit',
  },
  kakaoButtonNew: {
    width: '80px/60px@78px',
    height: '80px/60px@78px',
    borderRadius: '50%',
    backgroundColor: '#ffe900',

    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'fixed',
    right: '16px/34px@34px',

    zIndex: 18,
    cursor: 'pointer',

    '-webkit-tap-highlight-color': 'transparent',
  },
  kakaoBottomRocket: {
    bottom: '96px/2vw@98px',
    '-webkit-tap-highlight-color': 'transparent',
    transition: 'bottom .3s/',
  },
  kakaoBottomFreeget: {
    bottom: '20vw/5vw',
  },
  kakaostyle: {
    width: '35px/34px@44px',
    height: '35px/32px@41px',
    objectFit: 'contain',

    fill: '#34373A',
  },
  kakaoBottomFixedBottom: {
    bottom: '45px/110px@90px',
    transition: 'bottom .3s',
  },
  kakaoBottomFloating: {
    bottom: '118px/2vw@118px',
    transition: 'bottom .3s/',
  }
}, isMobile, isTablet, {mobileWidth: 414, width: 1920});
