import React from 'react';
import { useHistory } from 'react-router-dom';
import { closedPage, IHistory } from '../HistoryHelper';
import { spoqa } from '../../css/css-utils';

export function KakaoSuccess() {
  const history = useHistory<IHistory>();
  return (
    <>
      <div
        className='kakao comment success bottom-space25'
        style={{fontFamily: spoqa, fontWeight: 400}}
        data-testid='success'
        id={'kakao_success'}
      >
        휴대폰 카카오톡으로 메시지가 전달되었습니다. <br/>
        확인부탁드립니다.
      </div>
      <button
        className='kakao button bottom-space15'
        style={{fontFamily: spoqa, fontWeight: 500}}
        type='button'
        onClick={() => history.push(closedPage)}
        id={'kakao_send_button'}
      >
        확인
      </button>
    </>
  );
}
