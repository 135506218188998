import { plainToClass } from 'class-transformer';
import axios from 'axios';
import moment from 'moment';

export class CustomerDTO {
  constructor(
    public readonly date_creation: string,
    public readonly user_name: string,
    public readonly dosi_name: string,
    public readonly industry: string,
    public readonly isFirst: boolean,
    public readonly req_type: string,
    public readonly req_price_type: string,
    public readonly elapseTime: string,
  ) {
  }
}

export class CustomerRepository {
  constructor(
    private readonly customerUrl: string = '',
  ) {
  }

  public async fetchCustomers(): Promise<CustomerDTO[]> {
    if (this.customerUrl === '' || this.customerUrl === undefined) {
      return Promise.resolve([]);
    }

    const {data: customers} = await axios.get(
      this.customerUrl
    );

    const responeMapping = customers.map((item: any, index: number) => {
      const date = moment(item.date_creation).toDate();
      const year = date.getFullYear();
      const month = date.getMonth() + 1;
      const day = date.getDate();
      const object = {
        ...item,
        date_creation: `${year}.${month <
        10 ? `0${month}` : month}.${
          day < 10 ? `0${day}` : day
        }`,
        isFirst: index === 0,
        elapseTime: this.getElapseTimeForRequest(date),
      };
      return object;
    });

    return plainToClass(CustomerDTO, responeMapping);
  }

  private getElapseTimeForRequest(reqDate: Date): number {
    const currentDate = new Date();
    const elapseMSec = currentDate.getTime() - reqDate.getTime();

    return Math.abs(Math.ceil((elapseMSec / 1000) / 60) % 120);
  }
}
