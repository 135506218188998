import { spoqa } from '../../css/css-utils';
import { CreateStyle } from '../shared/CreateStyle';

export const RequestBannerStyle = (isMobile: boolean, isTablet?: boolean) => CreateStyle({
  outline: {
    width: '100%/28vw',
    height: '/100%',

    position: 'relative',
    zIndex: 1,

    fontFamily: spoqa,
  },
  leftDiv: {
    width: '100%',
    height: '100%',
    padding: '15vw 0 6vw 0/2.8vw 3.3vw@98px 0 72px',

    display: 'flex',
    flexDirection: 'column',
    alignItems_: 'center/',
    textAlign_: 'center/',
    justifyContent_: '/space-between',

    fontWeight: 300,
    fontSize: '13px/14px',
    color: 'white',

    position: 'relative',
  },
  title: {
    display_: 'none/',

    fontSize: '/40px',
    lineHeight: 1.3,

    whiteSpace: 'pre-wrap',

    marginBottom: '.83vw',
  },
  mobileTitle: {
    display_: '/none',

    fontSize: '2.5em/@43px',
    lineHeight: '1.37/@1.03',
    whiteSpace: 'pre-wrap',
    marginBottom: '/@11px',
  },
  subTitle: {
    fontSize: '1.16em/18px@22px',
    lineHeight: '1.72/1.72@1.44',

    marginBottom: '31px/@35px',
  },
  comment: {
    fontSize: '.9em/14px@16px',
    lineHeight: '1.86/@1.71',
  },
  description: {
    fontSize: '1em/14px@16px',
    fontWeight: 400,
    lineHeight: '1.62/1.86@2',
    whiteSpace: 'pre-wrap',
  },
}, isMobile, isTablet);
