import React from 'react';
import { Link, useHistory, withRouter } from 'react-router-dom';
import { css } from 'aphrodite';
import { useRecoilValue } from 'recoil';

import { Assets } from './Assets';
import { totalScreenState } from '../../../shared/presentation/MobileFrame';
import { IHistory, isShowMenu, requestPage } from '../../../shared/presentation/HistoryHelper';
import { RequestButton } from './RequestButton';
import { CreateStyle } from '../../../shared/presentation/shared/CreateStyle';
import { KakaoButton } from './KakaoButton';
import { RenewalLogo } from '../../../shared/presentation/header/RenewalLogo';
import { HeaderStyles } from '../../../startbiz/pages/shared/header/Header';
import { RenewalButtonStyle } from '../../../startbiz/pages/shared/header/RenewalButtonStyle';
import { RenewalMenuButton } from '../../../shared/presentation/header/RenewalMenuButton';
import { spoqa } from '../../../shared/css/css-utils';
import { moveToHome } from '../../../shared/helper/Util';
import { ReactComponent as HeaderLogo } from '../../../startbiz/assets/svgs/logo.svg';
import { MenuButton } from '../../../startbiz/pages/shared/header/MenuButton';

function Header() {
  const {isMobile, isDesktop, isTablet, isTop} = useRecoilValue(totalScreenState);
  const history = useHistory<IHistory>();
  const isMenu = isShowMenu(history) && !isDesktop;

  const headerStyles = HeaderStyles({
    isTop: isTop && !isMenu, isMobile, isTablet
  });
  const buttonStyles = RenewalButtonStyle({isMobile, isTablet});
  const customStyles = CustomStyles({isMobile, isTablet, isTop});
  const openManual = () => {
    window.open('https://manual.k-startbiz.org');
  };

  if (!isDesktop && isShowMenu(history)) {
    return (
      <></>
    );
  }

  return (
    <>
      {
        isDesktop ?
          (
            <div className={css(headerStyles.header, headerStyles.transitionStyle)} id="header">
              <div className={css(headerStyles.headerFirstBox)}>
                <div className={css(headerStyles.logoIcon)}>
                  <RenewalLogo
                    LogoImage={Assets.LogoIcon}
                    baseColor={'#051A43'}
                  />
                </div>
                <div className={css(headerStyles.buttonBox)}>
                  {isDesktop &&
                  <>
                    {/*<div*/}
                    {/*  className={css(buttonStyles.button, isTop && buttonStyles.button_top)}*/}
                    {/*  onClick={() => window.open('https://blog.k-startbiz.org')}*/}
                    {/*>*/}
                    {/*  블로그*/}
                    {/*</div>*/}
                    <div
                      className={css(buttonStyles.button, isTop && buttonStyles.button_top)}
                      onClick={openManual}
                    >
                      이용 매뉴얼
                    </div>
                  </>
                  }
                </div>
              </div>
              <div className={css(headerStyles.buttonBox)}>
                <KakaoButton/>
                <RequestButton/>
                {!isTop && (
                  <>
                    <Link
                      className={css(customStyles.button)}
                      to={requestPage()}
                      id={'request_button'}
                    >
                      <p className={css(headerStyles.text)}>
                        {'무료법인설립 신청'}
                      </p>
                    </Link>
                  </>
                )}
                <RenewalMenuButton/>
              </div>
            </div>
          ) : (
            <div
              className={css(headerStyles.header, headerStyles.transitionStyle)}
              id="header"
            >
              <div
                className={css(headerStyles.logo)}
                onClick={() => moveToHome(history)}
              >
                <HeaderLogo className={css(headerStyles.logoIcon)}/>
              </div>
              <div className={css(headerStyles.buttonBox)}>
                <KakaoButton/>
                <RequestButton/>
                <MenuButton/>
              </div>
            </div>
          )
      }
    </>
  );
}

export default withRouter(Header);


const CustomStyles = (
  {
    isMobile,
    isTablet,
    isTop,
  }: {
    isMobile: boolean,
    isTablet: boolean,
    isTop: boolean,
  }) => CreateStyle({
  button: {
    width: '50px/',
    height: '100%',
    border: 'none',
    borderLeft: `solid 1px ${isTop ? 'rgba(255, 255, 255, 0.25)' : '#d8d8d8'}`,
    padding: '0/0 42px',

    display: 'flex',
    alignItems: 'center',
    justifyContent_: 'center/flex-start',

    backgroundColor: '#051A43',
    fontFamily: spoqa,
    fontWeight: 300,
    fontSize: '3.14vw/17px',
    lineHeight: '/20px',
    color: 'white',

    cursor: 'pointer',
  },
}, isMobile, isTablet, {isNoRatio: true});
